import http from '../http';
import { getDayFormatHypenV2 } from '../../utility/admin/timeFormat';

export const userList = (params) => {
  return http.get('/user', {
    params: {
      ...params,
      startDate: getDayFormatHypenV2(params.startDate),
      endDate: getDayFormatHypenV2(params.endDate),
    },
  });
};

export const userIdCheck = (params) => {
  return http.get(`/user/${params}/exists`);
};

export const signUpUser = (data) => {
  return http.post('/signup', data);
};

export const getUserDetail = (id) => {
  return http.get(`/user-info/${id}`);
};

export const upDateUserDetail = (id, data) => {
  // return http.put(`/user/${id}`, data);
  return http.post(`/user/${id}/put`, data);
};
export const upDateUserDetailPw = (id, data) => {
  // return http.put(`/password/${id}`, data);
  return http.post(`/password/${id}/put`, data);
};

export const deleteUser = (id) => {
  // return http.delete(`/user/${id}`);
  return http.get(`/user/${id}/delete`);
};
