import styled from 'styled-components';

// 체크박스
function Checkbox({ children, disabled, checked, onChange, customStyle }) {
  return (
    <CheckBoxWrapper $customStyle={customStyle}>
      <input
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={({ target: { checked } }) => onChange(checked)}
      />
      {children}
    </CheckBoxWrapper>
  );
}
// 체크박스 컴포넌트 style
const CheckBoxWrapper = styled.label`
  display: flex;
  align-items: center;
  color: var(--color-dark-1);
  ${(props) => props.$customStyle?.label};
  input[type='checkbox'] {
    margin-right: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    height: 20px;
    outline: 0;
    width: 20px;
    position: relative;
    border: 1px solid var(--color-admin-border);
    ${(props) => props.customStyle?.checkbox};
  }
  input[type='checkbox']::after {
    border: solid #fff;
    border-width: 0 3px 3px 0;
    content: '';
    display: none;
    position: absolute;
    height: 50%;
    top: 3px;
    width: 32%;
    left: 6px;

    transform: rotate(45deg);
  }
  input[type='checkbox']:checked {
    background: var(--color-admin-main);
  }
  input[type='checkbox']:checked::after {
    display: block;
  }
`;

export default Checkbox;
