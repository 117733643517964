// 네비게이션 메뉴 상수
export const navMenu = [
  {
    id: 'apply',
    name: '서비스 신청 관리',
    accessRole: 'ROLE_ADMIN',
  },
  {
    id: 'reference',
    name: '자료실 관리',
    accessRole: 'ROLE_ADMIN',
  },
  {
    id: 'faq',
    name: 'FAQ 관리',
    accessRole: 'ROLE_ADMIN',
  },
  {
    id: 'notice',
    name: '공지사항 관리',
    accessRole: 'ROLE_ADMIN',
  },
  {
    id: 'question',
    name: '문의 관리',
    accessRole: 'ROLE_ADMIN',
  },
  {
    id: 'user',
    name: '관리자 관리',
    accessRole: 'ROLE_SUPER',
  },
];
// 헤더 메뉴 타이틀
export const getMenuTitle = (id) => {
  if (!id) return '';
  if (id === 'auth' || id === 'pwchange') return '계정 정보';
  for (let i = 0; i < navMenu.length; i++) {
    const menu = navMenu[i];
    if (id.includes(menu.id)) {
      return menu.name;
    }
  }
  return '';
};

// 계정 권한 이름
export const converterUserRoleName = (userRole) => {
  switch (userRole) {
    case 'ROLE_SUPER':
      return '통합관리자';
    case 'ROLE_ADMIN':
      return '관리자';
    default:
      return '';
  }
};

export const getNavMenu = (userRole) => {
  if (userRole === 'ROLE_SUPER') return navMenu;
  const menus = navMenu.filter((menu) => menu.accessRole === userRole);
  return menus;
};
