import React, { useMemo, useRef, useState } from 'react';
import {
	CustomOverlayMap,
	Map,
	MapMarker,
	MapTypeControl,
	ZoomControl,
} from 'react-kakao-maps-sdk';
import useKakaoLoader from './useKakaoLoader';
import styled from '@emotion/styled';

const KakaoMap = () => {
	useKakaoLoader();
	const [position, setPosition] = useState({
		lat: 37.39960697476817,
		lng: 126.6296919849085,
	});
	const [restPosition, setRestPosition] = useState([
		{
			lat: 37.400078630645126,
			lng: 126.62710336858113,
		},
		{
			lat: 37.39783694949332,
			lng: 126.62769044544743,
		},
		{
			lat: 37.39899879814023,
			lng: 126.63330896328013,
		},
		{
			lat: 37.4004948099816,
			lng: 126.63196334317489,
		},
	]);
	const mapRef = useRef(null);
	const [mapType, setMapType] = useState('roadmap');
	const handleOnClickResetPos = useMemo(() => {
		// eslint-disable-next-line no-undef
		const bounds = new kakao.maps.LatLngBounds();

		restPosition.forEach((point) => {
			// eslint-disable-next-line no-undef
			bounds.extend(new kakao.maps.LatLng(point.lat, point.lng));
		});
		return bounds;
	}, [restPosition]);

	const handleOnClickZoomIn = () => {
		const map = mapRef.current;
		if (!map) return;
		map.setLevel(map.getLevel() - 1);
	};

	const handleOnClickZoomOut = () => {
		const map = mapRef.current;
		if (!map) return;
		map.setLevel(map.getLevel() + 1);
	};

	return (
		<CustomKaKaoMapBox>
			<Map
				id={'map'}
				ref={mapRef}
				center={{ lat: 37.39960697476817, lng: 126.6296919849085 }} // 지도의 중심 좌표
				style={{ width: '100%', height: '100%', position: 'relative' }} // 지도 크기
				level={2} // 지도 확대 레벨
				// onClick={(_t, mouseEvent) => }
			>
				<MapMarker
					position={{ lat: 37.39960697476817, lng: 126.6296919849085 }}
				/>

				<CustomOverlayMap // 커스텀 오버레이를 표시할 Container
					yAnchor={2.8}
					// 커스텀 오버레이가 표시될 위치
					position={{
						lat: 37.39960697476817,
						lng: 126.6296919849085,
					}}>
					{/* 커스텀 오버레이에 표시할 내용입니다 */}
					<div
						className={'bg-white px-[10px] py-[5px] text-[14px] font-bold'}
						style={{ color: '#000' }}>
						<span className='center'>바움피앤에스</span>
					</div>
				</CustomOverlayMap>
				<MapTypeControl position={'TOPRIGHT'} />
				<ZoomControl position={'RIGHT'} />
			</Map>
		</CustomKaKaoMapBox>
	);
};

export default KakaoMap;

const CustomKaKaoMapBox = styled.div`
	position: relative;
	width: 100%;
	height: 100%;

	.zoomBox {
		position: absolute;
		top: 50px;
		left: 0;
		width: 36px;
		height: 120px;
		overflow: hidden;
		z-index: 1;
		background-color: #f5f5f5;
	}
	.zoomBox span {
		display: block;
		width: 36px;
		height: 40px;
		text-align: center;
		cursor: pointer;
	}
	.zoomBox span img {
		width: 15px;
		height: 15px;
		padding: 12px 0;
		border: none;
	}
`;
const CustomKaKaoMapControl = styled.div``;
