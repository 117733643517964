import React, { useCallback, useEffect, useState } from 'react';
import Section from '../../../../layout/web/Section';
import ServiceSectionTypography from '../../../../components/web/texts/ServiceSectionTypography';
import SearchInput from '../../../../components/inputs/SearchInput';
import QuestionTable from '../../../../components/web/tables/QuestionTable';
import MainColorBtn from '../../../../components/web/button/MainColorBtn';
import { useNavigate } from 'react-router-dom';
import MainPagination from '../../../../components/web/pagination/MainPagination';
import { useQuery } from '@tanstack/react-query';
import { getQuestionList, postQuestionInfoPassword } from '../../../../api/web/customerService/question';
import PassWord from '../../../../components/modal/PassWordModal';
import NotData from '../../../../components/web/lists/NotData';
import { useSetRecoilState } from 'recoil';
import { loadingState } from '../../../../recoil/auth';

const ServiceQuestion = () => {
  const [searchWord, setSearchWord] = useState('');
  const [pageOffset, setPageOffset] = useState({
    searchWord: '',
    page: 1,
    size: 10,
    searchOption: '1',
  });
  const [infoStatus, setInfoStatus] = useState({
    inquiryId: '',
    password: '',
  });
  const [modalStatus, setModalStatus] = useState(true);
  const nav = useNavigate();
  const setLoading = useSetRecoilState(loadingState);
  const [isShowModal, setIsShowModal] = useState(false);
  const { data, refetch, isFetching } = useQuery({
    queryKey: ['questionList', pageOffset.searchWord],
    queryFn: () => getQuestionList(pageOffset),
  });

  useEffect(() => {
    refetch();
  }, [pageOffset.page]);

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);
  const handleSearchOnChange = ({ target: { value } }) => setSearchWord(value);

  const handleInputOnKeyDown = useCallback(
    ({ key }) => {
      if (key === 'Enter')
        return setPageOffset({
          ...pageOffset,
          page: 1,
          searchWord,
        });
    },
    [searchWord]
  );

  const handleOnChangeCurrentPage = useCallback(
    ({ name, value }) => {
      setPageOffset((pageOffset) => ({ ...pageOffset, [name]: value }));
    },
    [setPageOffset]
  );
  const handleShowModal = (id) => {
    setInfoStatus({ ...infoStatus, inquiryId: id });
    setIsShowModal(true);
  };

  const handleOnClickDetailPage = useCallback(() => {
    if (!infoStatus.password) return setModalStatus(false);
    setLoading(true);
    postQuestionInfoPassword(infoStatus)
      .then(({ data }) => {
        setLoading(false);
        if (!data || data.length === 0) return setModalStatus(false);
        nav(`detail`, { state: { ...infoStatus } });
      })
      .catch();
  }, [infoStatus.password, infoStatus.id, isShowModal]);

  useEffect(() => {
    if (!isShowModal) {
      setModalStatus(true);
      setInfoStatus({
        inquiryId: '',
        password: '',
      });
    }
  }, [isShowModal]);

  useEffect(() => {
    if (data?.data.totalPages < pageOffset.page) return setPageOffset((pageOffset) => ({ ...pageOffset, page: 1 }));
  }, [data?.data]);

  return (
    <Section>
      {isShowModal && (
        <PassWord
          setShowModal={setIsShowModal}
          status={modalStatus}
          state={infoStatus.password}
          setState={setInfoStatus}
          onClick={handleOnClickDetailPage}
        />
      )}
      <Section
        maxSize
        className={'h-full pt-[180px] md:pt-[140px] sm:pt-[103px] xs:pt-[103px] fold:pt-[103px] transition-all'}>
        <ServiceSectionTypography
          title={'문의하기'}
          className={'text-dark1A text-center mb-[60px] md:mb-[50px] sm:mb-[40px] xs:mb-[30px] fold:mb-[30px]'}
        />
        <SearchInput onChange={handleSearchOnChange} value={searchWord} onKeyDown={handleInputOnKeyDown} />
        <div className={'border-solid border-t-dark1A border-t-2 border-b-darkDB border-b-[1px]'}>
          <QuestionTable items={data?.data?.content || []} onClick={handleShowModal} />
          {(!data?.data?.content || data?.data?.content?.length === 0) && (
            <NotData
              text={pageOffset.searchWord.replace(/ /g, '') ? '검색 결과가 없습니다.' : '등록된 문의가 없습니다.'}
            />
          )}
        </div>
        <div className={'w-full flex items-center justify-end mt-[20px] mb-[60px]'}>
          <MainColorBtn
            text={'문의하기'}
            className={
              'px-[25px] py-[5px] text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall xs:px-[20px] fold:px-[20px] leading-[30px] font-[500] rounded-[10px]'
            }
            onClick={() => nav('create', { state: { type: 'create' } })}
          />
        </div>
        {data?.data?.content && data?.data?.content?.length !== 0 && (
          <div className={'mb-[120px] md:mb-[90px] sm:mb-[70px] xs:mb-[50px] fold:mb-[50px]'}>
            <MainPagination
              total={data?.data?.totalElements || 1}
              limit={10}
              currentPage={pageOffset.page}
              onChange={handleOnChangeCurrentPage}
            />
          </div>
        )}
      </Section>
    </Section>
  );
};

export default ServiceQuestion;
