import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Section from '../../../layout/web/Section';
import ServiceSectionTypography from '../../../components/web/texts/ServiceSectionTypography';
import PaymentCircleList from '../../../components/web/lists/PaymentCircleList';
import ServerSection from '../../../layout/web/services/ServerSection';
import ServiceCardList from '../../../components/web/lists/ServiceCardList';
import PgSubImage1 from '../../../assets/images/services/platform/pgAppSubImage1.png';
import PgSubImage2 from '../../../assets/images/services/platform/pgAppSubImage2.png';
import PgSubImage3 from '../../../assets/images/services/platform/pgAppSubImage3.png';
import PgSubImage4 from '../../../assets/images/services/platform/pgAppSubImage4.png';
import PlatformPGHome from '../../../assets/images/web/platform/home.png';
import PlatformPGPaying from '../../../assets/images/web/platform/paying.png';
import PlatformPGDetail from '../../../assets/images/web/platform/paymentDetails.png';
import PlatformPGSettlement from '../../../assets/images/web/platform/settlementDetails.png';
import PlatformPGPhone from '../../../assets/images/web/platform/phone.png';

import { useNavigate } from 'react-router-dom';
import {
	platformCardItems,
	platformCircleItems,
} from '../../../utility/web/items/serviceExplanation';
import { useMediaQuery } from '@mui/material';

const Platform = () => {
	const [currentImage, setCurrentImage] = useState({
		current: 0,
		next: 1,
	});
	const firstSectionRef = useRef(null);
	const sm = useMediaQuery('(max-width:800px)');
	const nav = useNavigate();
	useEffect(() => {
		const timer = setInterval(() => {
			setCurrentImage((prev) => ({
				current: prev.next,
				next: (prev.next + 1) % 4,
			}));
		}, 4000);

		return () => clearInterval(timer);
	}, [currentImage]);

	const callback = useCallback(
		(entries, io) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add('active');
					io.unobserve(entry.target);
				}
			});
		},
		[firstSectionRef],
	);
	const sections = new IntersectionObserver(callback, {
		threshold: [sm ? 0.1 : 0.4],
	});
	useEffect(() => {
		if (firstSectionRef.current) sections.observe(firstSectionRef.current);
		return () => {
			if (firstSectionRef.current) sections.unobserve(firstSectionRef.current);
		};
	}, []);
	return (
		<Page>
			<Section className={'bg-[#F9F9F9]'}>
				<Section
					maxSize
					className={
						'pt-[180px] md:pt-[160px] sm:pt-[133px] xs:pt-[103px] fold:pt-[103px] pb-[120px] md:pb-[100px] sm:pb-[70px] xs:pb-[50px] fold:pb-[50px]'
					}>
					<div
						ref={firstSectionRef}
						className={'flex flex-col items-center justify-center'}>
						<div className={'max-w-[550px]'}>
							<ServiceSectionTypography
								title={'플랫폼 서비스'}
								className={
									'text-dark1A mb-[30px] md:mb-[25px] sm:mb-[20px] xs:mb-[15px] fold:mb-[15px] fade-in-up'
								}
							/>
							<div
								className={
									'text-bodyLarge text-dark49 font-[500] leading-[40px] xs:text-bodySmall xs:leading-[30px] fold:text-bodySmall fold:leading-[30px] mb-[60px] md:mb-[50px] sm:mb-[40px] xs:mb-[30px] fold:mb-[30px] fade-in-up-sub'
								}>
								다양한 결제수단 및 가맹점{' '}
								<br className={'hidden xs:block fold:block'} /> 통합 매출 관리
								시스템을 통하여 <br className={'hidden xs:block fold:block'} />
								가맹점에 지원하는 맞춤형 서비스입니다.
							</div>
						</div>

						<PaymentCircleList items={platformCircleItems} />
					</div>
				</Section>
			</Section>

			<Section>
				<Section
					maxSize
					className={
						'pt-[100px] md:pt-[80px] sm:pt-[60px] xs:pt-[50px] fold:pt-[50px] flex items-center justify-center'
					}>
					<div
						className={
							'flex w-full header:flex-col gap-40 transition-all justify-center md:gap-[10px] header:items-center header:justify-center header:gap-[40px]'
						}>
						<div
							className={
								'flex flex-col mt-[100px] header:mt-0 header:mb-[40px] items-center '
							}>
							<ServiceSectionTypography
								title={'PG결제 시스템 APP'}
								className={'mb-[30px] header:mb-[15px]'}
							/>
							<div
								className={
									'flex flex-col text-dark49 text-bodyLarge md:leading-[40px] md:text-bodyMedium header:hidden  whitespace-nowrap'
								}>
								<div>모든 사용자가 간편하게 이용할 수 있는</div>
								<div>결제 솔루션 어플입니다. 간단한 연동만으로 별도의</div>
								<div>플러그인 설치 과정 없이 모든 기기에서</div>
								<div>앱 설치와 결제가 가능합니다.</div>
							</div>
							<div
								className={
									'hidden header:flex flex-col text-dark49 text-bodySmall leading-[30px]'
								}>
								<div>간단한 연동만으로 별도의 플러그인</div>
								<div>설치 과정 없이 모든 기기에서 앱 설치와</div>
								<div>결제가 가능합니다.</div>
							</div>
						</div>
						<div>
							<div
								className={
									'relative max-w-[440px] min-w-[440px] header:max-w-[308px] header:min-w-[308px] header:translate-x-[45px]'
								}>
								<img
									src={PlatformPGPhone}
									alt=''
									className={'w-[440px] header:w-[302px]'}
								/>
								<SliderImageBox
									className={
										'h-[470px] w-[215px] header:h-[327px] header:w-[150px]'
									}>
									<div className={'contentImage'}>
										<SliderImage
											className={`top-0  ${
												currentImage.current === 0
													? 'left-0'
													: currentImage.next === 0
													? 'left-full mz'
													: 'z-[1] -left-full'
											}`}
											src={PlatformPGHome}
											alt=''
										/>
										<SliderImage
											className={`top-0 ${
												currentImage.current === 1
													? ' left-0'
													: currentImage.next === 1
													? 'left-full mz'
													: 'z-[1] -left-full'
											}`}
											src={PlatformPGPaying}
											alt=''
										/>
										<SliderImage
											className={`top-0  ${
												currentImage.current === 2
													? ' left-0'
													: currentImage.next === 2
													? 'left-full mz'
													: 'z-[1] -left-full '
											}`}
											src={PlatformPGDetail}
											alt=''
										/>
										<SliderImage
											className={`top-0 ${
												currentImage.current === 3
													? ' left-0'
													: currentImage.next === 3
													? 'left-full mz'
													: 'z-[1] -left-full'
											}`}
											src={PlatformPGSettlement}
											alt=''
										/>
									</div>
								</SliderImageBox>
								<div
									className={
										'absolute w-[330px] h-[485px] header:h-[373px] header:w-[245px] top-0 overflow-hidden -translate-y-[29px] z-[-3] -left-[12px] xs:-left-[18px] fold:-left-[18px]'
									}>
									<div className={'relative w-full h-full '}>
										<SliderBackgroundImage
											src={PgSubImage1}
											alt=''
											className={`absolute w-full ${
												currentImage.current === 0
													? 'left-0'
													: currentImage.next === 0
													? 'left-full mz'
													: '-left-full'
											}`}
										/>
										<SliderBackgroundImage
											src={PgSubImage2}
											alt=''
											className={`absolute w-full ${
												currentImage.current === 1
													? 'left-0'
													: currentImage.next === 1
													? 'left-full mz'
													: '-left-full'
											}`}
										/>
										<SliderBackgroundImage
											src={PgSubImage3}
											alt=''
											className={`absolute w-full ${
												currentImage.current === 2
													? 'left-0'
													: currentImage.next === 2
													? 'left-full mz'
													: '-left-full'
											}`}
										/>
										<SliderBackgroundImage
											src={PgSubImage4}
											alt=''
											className={`absolute w-full ${
												currentImage.current === 3
													? 'left-0'
													: currentImage.next === 3
													? 'left-full mz'
													: '-left-full z-[0]'
											}`}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Section>
			</Section>
			<ServerSection
				btnTitle={'서비스 문의하기'}
				onClick={() => nav('/customerService/question/create')}>
				<div>온/오프라인 비즈니스에</div>
				<div>필요한 모든 플랫폼 서비스</div>
			</ServerSection>
			<ServiceCardList items={platformCardItems} title={'플랫폼 서비스 항목'} />
		</Page>
	);
};

export default Platform;

const Page = styled(Section)`
	text-wrap: normal;
	white-space: normal;
	word-break: keep-all;
	text-align: center;
	.fade-in-up {
		opacity: 0;
		transform: translateY(100px);
		transition:
			opacity 1s,
			transform 0.7s;
	}
	.fade-in-up-circle {
		opacity: 0;
		transform: translateY(100px);
		transition:
			opacity 1s,
			transform 0.7s;
		transition-delay: 0.3s;
	}
	.fade-in-up-sub {
		opacity: 0;
		transform: translateY(100px);
		transition:
			opacity 1s,
			transform 0.7s;
		transition-delay: 0.1s;
	}
	& .active .fade-in-up,
	& .active .fade-in-up-sub,
	& .active .fade-in-up-circle {
		opacity: 1;
		transform: translateY(0);
	}
`;

const SliderImageBox = styled.div`
	position: absolute;
	top: 2.14%;
	left: 10.7%;

	z-index: -1;
	& > .contentImage {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
		& > img {
			transition: all 0.7s;
			position: absolute;
			&.mz {
				transition: 0s;
				z-index: -9999 !important;
			}
		}
	}
`;

const SliderImage = styled.img`
	width: 100%;
	height: 100%;
`;
const SliderBackgroundImage = styled.img`
	width: 100%;
	height: 100%;
	z-index: -3;
	&.mz {
		transition: 0s;
	}
	& {
		transition: all 0.7s;
	}
`;
