const { default: styled } = require('styled-components');

function TextField({ ...rest }) {
  return <TextFieldInput {...rest} autoComplete={'off'}></TextFieldInput>;
}

export const TextFieldInput = styled.input`
  height: ${(props) => (props.height ? props.height : '35px')};
  width: ${(props) => (props.width ? props.width : '100%')};
  padding: 8px 16px;
  font-size: 14px;
  color: var(--color-dark-1);
  border-radius: 6px;

  border: 1px solid var(--color-admin-border);
  border-radius: 6px;
  &:focus-visible {
    outline: var(--color-admin-main) auto 1px;
  }
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default TextField;
