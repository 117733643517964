import React, { memo, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as LeftArrow } from '../../../assets/icons/web/pagination/pageLeft.svg';
import { ReactComponent as RightArrow } from '../../../assets/icons/web/pagination/pageRight.svg';

/*
  total: 전체 데이터 개수
  limit: 한 페이지에 보여줄 데이터 개수
  currentPage: 현재 페이지
  onChange: 페이지 변경 함수
 */
const MainPagination = ({ total, limit, currentPage, onChange }) => {
  const totalPages = Number.isInteger(+total / +limit)
    ? Number.parseInt(+total / +limit)
    : Number.parseInt(+total / +limit) + 1;
  const [pages, setPages] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);

  useEffect(() => {
    setPages(Array.from({ length: totalPages }, (_, i) => i + 1));
  }, [total, currentPage, limit]);
  useEffect(() => {
    if (totalPages < currentPage) return onChange({ name: 'page', value: totalPages });
  }, [totalPages]);

  const goNext = useCallback(() => {
    if (currentPage < endIndex) return onChange({ name: 'page', value: currentPage + 1 });
    if (endIndex < totalPages) {
      onChange({ name: 'page', value: currentPage + 1 });
      setStartIndex((prev) => prev + 10);
      setEndIndex((prev) => prev + 10);
    }
  }, [endIndex, totalPages, currentPage, limit]);

  const goPrev = useCallback(() => {
    if (currentPage > startIndex + 1) return onChange({ name: 'page', value: currentPage - 1 });
    if (startIndex > 0) {
      onChange({ name: 'page', value: currentPage - 1 });
      setStartIndex((prev) => prev - 10);
      setEndIndex((prev) => prev - 10);
    }
  }, [endIndex, totalPages, currentPage, limit]);

  return (
    <Pagination>
      <PageButton className={`mr-[20px]`} disabled={currentPage === 1} onClick={() => !(currentPage === 1) && goPrev()}>
        <LeftArrow />
      </PageButton>
      {pages?.slice(startIndex, endIndex)?.map((page) => (
        <button
          key={`${page}`}
          className={`${
            currentPage === page ? 'bg-main text-white cursor-default' : 'text-dark1A'
          } text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall w-[32px] h-[32px] mx-[5px] leading-[32px] rounded-full`}
          onClick={() => currentPage !== page && onChange({ name: 'page', value: page })}>
          {page}
        </button>
      ))}

      <PageButton
        className={'ml-[20px]'}
        disabled={currentPage === totalPages}
        onClick={() => !(currentPage === totalPages) && goNext()}>
        <RightArrow />
      </PageButton>
    </Pagination>
  );
};

export default memo(MainPagination);

const Pagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageButton = styled.button`
  &:disabled {
    & svg {
      & path {
        stroke: #e0e0e0;
      }
    }
  }
`;
