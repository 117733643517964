import React from 'react';
import styled from '@emotion/styled';
import { ThreeDots } from 'react-loader-spinner';
import { useRecoilValue } from 'recoil';
import { loadingState } from '../../recoil/auth';

const Loading = () => {
  const IsLoading = useRecoilValue(loadingState);

  return (
    <>
      {IsLoading && (
        <LoadingContainer>
          <ThreeDots
            height="100"
            width="100"
            radius="10"
            color="#0081FF"
            ariaLabel="three-dots-loading"
            visible={true}
          />
        </LoadingContainer>
      )}
    </>
  );
};

export default Loading;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  text-align: center;
  svg {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
  }
`;
