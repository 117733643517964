import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { getDayFormatHypenV2 } from '../../../utility/admin/timeFormat';
import NotData from './NotData';

const NotIceList = ({ items, fixItems, isSearch, pageMeta }) => {
	const nav = useNavigate();
	const { page, pageSize, total } = pageMeta;
	const [formatItems, setFormatItems] = useState([]);
	const handleOnClick = (link) => {
		nav(`${link}`);
	};
	useEffect(() => {
		if (items) {
			setFormatItems(
				items.map((item, index) => {
					return {
						...item,
						idx: total + (1 - page) * pageSize - index,
					};
				}),
			);
		}
	}, [items]);

	return (
		<NotIceListContainer>
			{fixItems?.map((item) => (
				<FixNotIceListItem
					onClick={() => handleOnClick(item.id)}
					key={item.id}
					className={
						'text-bodySmall leading-[30px] h-[70px] sm:text-bodyXSmall xs:text-bodyXSmall fold:text-bodyXSmall py-[10px] px-[50px] md:px-[40px] sm:px-[30px] xs:px-[20px] fold:px-[20px] rounded-[10px] whitespace-nowrap'
					}>
					<div
						className={
							'md:pr-[40px] sm:pr-[20px] xs:pr-[10px] fold:pr-[0] h-full flex items-center justify-start w-[100px]'
						}>
						<span
							className={
								'px-[15px] py-[2px] bg-main rounded-[100px] text-white text-bodyXSmall xs:text-bodyXXSmall fold:text-bodyXXSmall w-[58px] xs:w-[50px] fold:w-[50px] h-[28px] text-center flex items-center justify-center '
							}>
							공지
						</span>
					</div>
					<div className={'w-full text-start  pr-[20px] font-[600] fold:pr-0'}>
						<span
							className={
								'overflow-hidden text-ellipsis whitespace-nowrap text-dark1A'
							}>
							{item.title}
						</span>
					</div>
					<div className={'w-auto text-center text-dark49'}>
						{getDayFormatHypenV2(item.createdDate)}
					</div>
				</FixNotIceListItem>
			))}
			{formatItems?.map((item) => (
				<NotIceListItem
					onClick={() => handleOnClick(item.id)}
					key={item.id}
					className={
						'text-bodySmall leading-[30px] h-[70px] sm:text-bodyXSmall xs:text-bodyXSmall fold:text-bodyXSmall py-[20px] px-[50px] md:px-[40px] sm:px-[30px] xs:px-[20px] fold:px-[20px] rounded-[10px] whitespace-nowrap'
					}>
					<div
						className={
							'md:pr-[40px] sm:pr-[20px] xs:pr-[10px] fold:pr-0 h-full flex items-center justify-start w-[100px] px-[15px] text-dark1A'
						}>
						{item.idx}
					</div>
					<div className={'w-full text-start pr-[20px]'}>
						<span
							className={
								'overflow-hidden text-ellipsis whitespace-nowrap text-dark1A'
							}>
							{item.title}
						</span>
					</div>
					<div className={'w-auto text-center text-dark49'}>
						{getDayFormatHypenV2(item.createdDate)}
					</div>
				</NotIceListItem>
			))}
			{(!items || items?.length === 0) &&
				(!fixItems || fixItems?.length === 0) && (
					<NotData
						text={
							isSearch ? '검색 결과가 없습니다.' : '등록된 공지사항이 없습니다.'
						}
					/>
				)}
		</NotIceListContainer>
	);
};

export default NotIceList;

const NotIceListContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	width: 100%;
	user-select: text;
	& * {
		transition: all 0.2s;
	}
`;

const NotIceListItem = styled.div`
	display: flex;
	cursor: pointer;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	user-select: text;
	& > div {
		height: 70px;
		display: flex;
		flex-direction: row;
		align-items: center;
		&:first-child {
			flex: 1;
			min-width: 10px;
			max-width: 80px;
			margin-right: 20px;
			@media only screen and (max-width: 399.9px) {
				max-width: 50px;
				min-width: 50px;
			}
		}
		&:nth-child(2) {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			flex: 1;
			@media only screen and (max-width: 399.9px) {
				margin-right: 0;
				min-width: 110px;
				max-width: 110px;
			}
		}
		&:last-child {
			flex: 1;
			max-width: 110px;
			width: 100%;
			@media only screen and (max-width: 399.9px) {
				max-width: 105px;
			}
		}
	}
	&:hover {
		background-color: #f9f9f9;
		& > div:nth-child(2) {
			color: #0081ff;
		}
	}
`;
const FixNotIceListItem = styled(NotIceListItem)`
	user-select: text;
`;
