import React, { memo } from 'react';
import styled from 'styled-components';

const MainColorBtn = ({ onClick, className, text, disabled = false }) => {
  return (
    <MainBtn
      onClick={() => !disabled && onClick()}
      className={`${disabled ? 'bg-darkF9 text-darkDB cursor-default' : 'bg-main text-white'}  ${className}`}>
      {text}
    </MainBtn>
  );
};

export default memo(MainColorBtn);

const MainBtn = styled.button`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
