import { getUserInfo, upDateUserDetail } from 'api/admin/auth';
import Button from 'components/admin/Button';
import TextField from 'components/admin/TextField';
import { ModalContext } from 'components/modal/modalContext';
import { Toast } from 'components/toast';
import { converterUserRoleName } from 'const/admin';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BottomBtnWrapper,
  ContentContainer,
  FormBox,
  FormBoxItem,
  FormBoxItemTitle,
  FormBoxItemValue,
  PageTitle,
} from 'styles/sc/admin';
import { getFullDayFormatHypen } from 'utility/admin/timeFormat';
import { emailRegex } from 'utility/web/regex';

const InfoForm = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    id: 0,
    userRole: '',
    username: '', // 아이디
    name: '', // 이름
    phone: '',
    email: '',
    affiliation: '',
    createdDate: '',
    updatedDate: '',
  });
  const { openModal, closeModal } = useContext(ModalContext);

  const { id, userRole, username, name, phone, email, affiliation, createdDate, updatedDate } = form;
  // 폼 데이터 변경
  const onChangeData = (e, setData) => {
    const { name, value } = e.currentTarget;
    setData((preVal) => ({ ...preVal, [name]: value }));
  };

  // 유효성 검사
  const validationCheck = useCallback(() => {
    if (!name || name.length === 0) {
      Toast.info('이름은 필수 입력값입니다.');
      return document.querySelector('input[name=name]').focus();
    }
    if (!phone || phone.length === 0) {
      Toast.info('휴대폰 번호는 필수 입력값입니다.');
      return document.querySelector('input[name=phone]').focus();
    }
    if (!email || email.length === 0) {
      Toast.info('이메일은 필수 입력값입니다.');
      return document.querySelector('input[name=email]').focus();
    }
    if (!emailRegex(email)) {
      Toast.info('이메일을 확인해주세요.');
      return document.querySelector('input[name=email]').focus();
    }
    if (!affiliation || affiliation.length === 0) {
      Toast.info('소속은 필수 입력값입니다.');
      return document.querySelector('input[name=affiliation]').focus();
    }
    handleOnSave();
  });

  const handleOnSave = useCallback(async () => {
    openModal('수정하시겠습니까?', {
      confirm: async () => {
        upDateUserDetail({
          name: form.name,
          phone: form.phone,
          email: form.email,
          affiliation: form.affiliation,
        })
          .then(({ status }) => {
            if (status === 200) {
              Toast.success('수정되었습니다.');
              return navigate('/ad/apply');
            }
            Toast.error('작업 처리 도중 오류가 발생하였습니다.');
          })
          .catch((err) => {});
        closeModal();
      },
    });
  }, [form]);

  useEffect(() => {
    const fetchData = async () => {
      const { status, data } = await getUserInfo();
      if (status === 200) {
        const { id, userRole, username, name, phone, email, affiliation, createdDate, updatedDate } = data;
        setForm({
          id,
          userRole,
          username,
          name,
          phone,
          email,
          affiliation,
          createdDate,
          updatedDate,
        });
      } else {
        Toast.error('데이터를 불러오는데 실패하였습니다.');
      }
    };
    fetchData();
  }, []);

  return (
    <ContentContainer>
      <PageTitle>계정 정보 변경</PageTitle>
      <FormBox>
        <FormBoxItem>
          <FormBoxItemTitle>계정 구분</FormBoxItemTitle>
          <FormBoxItemValue>{converterUserRoleName(userRole)}</FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>아이디</FormBoxItemTitle>
          <FormBoxItemValue>{username}</FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>비밀번호</FormBoxItemTitle>
          <FormBoxItemValue>
            <Button
              width={'130px'}
              style={{
                color: 'white',
              }}
              color={'admin_main'}
              onClick={() => {
                navigate('/ad/pwchange', { state: { ...form } });
              }}>
              비밀번호 재설정
            </Button>
          </FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>이름</FormBoxItemTitle>
          <FormBoxItemValue>
            <TextField
              placeholder="이름"
              height="40px"
              width="250px"
              name="name"
              value={name}
              onChange={(e) => {
                onChangeData(e, setForm);
              }}></TextField>
          </FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>휴대폰번호</FormBoxItemTitle>
          <FormBoxItemValue>
            <TextField
              placeholder="이름"
              height="40px"
              width="250px"
              name="phone"
              value={phone}
              onChange={(e) => {
                onChangeData(e, setForm);
              }}></TextField>
          </FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>이메일</FormBoxItemTitle>
          <FormBoxItemValue>
            <TextField
              placeholder="이메일"
              height="40px"
              width="250px"
              name="email"
              value={email}
              onChange={(e) => {
                onChangeData(e, setForm);
              }}></TextField>
          </FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>소속</FormBoxItemTitle>
          <FormBoxItemValue>
            <TextField
              placeholder="소속"
              height="40px"
              width="250px"
              name="affiliation"
              value={affiliation}
              onChange={(e) => {
                onChangeData(e, setForm);
              }}></TextField>
          </FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>등록일시</FormBoxItemTitle>
          <FormBoxItemValue style={{ color: 'var(--color-dark-3)' }}>
            {getFullDayFormatHypen(createdDate)}
          </FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>수정일시</FormBoxItemTitle>
          <FormBoxItemValue style={{ color: 'var(--color-dark-3)' }}>
            {getFullDayFormatHypen(updatedDate)}
          </FormBoxItemValue>
        </FormBoxItem>
      </FormBox>
      <BottomBtnWrapper>
        <Button
          style={{
            color: 'white',
            marginRight: '12px',
          }}
          width={'120px'}
          height={'42px'}
          color={'admin_navy'}
          onClick={() => {
            validationCheck();
          }}>
          <span>저장</span>
        </Button>
      </BottomBtnWrapper>
    </ContentContainer>
  );
};
export default InfoForm;
