// export const emailRegex = (email) => {
//   const regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
//   return regex.test(email);
// };

// 이메일 정규식
export const emailRegex = (email) => {
  const reg = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  return reg.test(email);
};
// 비밀번호
export const passwordRegex = (password) => {
  const regex = new RegExp('^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$');
  return regex.test(password);
};

// 한글 입력 방지
export const koreanRegex = (text) => {
  const regex = new RegExp('[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]');
  return regex.test(text);
};
