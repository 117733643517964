import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ContentContainer, PageTitle } from 'styles/sc/admin';
import { toParams } from 'utility/admin/search';
import { useQuery } from '@tanstack/react-query';

import { noticeList } from '../../../api/admin/notice';
import Table from '../../../components/admin/Table';
import { noticeColumn } from '../../../utility/admin/tableColumn';
import MainPagination from '../../../components/web/pagination/MainPagination';
import SearchInputForm from '../../../components/admin/SearchInputForm';
import { noticeSearchItems } from '../../../utility/admin/searchItems';

const NoticeList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorCount, setErrorCount] = useState(0);
  const [searchForm, setSearchForm] = useState({
    startDate: '',
    endDate: '',
    searchOption: '',
    searchWord: '',
  });
  const [pageOffSet, setPageOffSet] = useState({
    page: 1,
    size: 10,
    startDate: '',
    endDate: '',
    searchOption: '',
    searchWord: '',
  });

  const { data, refetch, failureCount } = useQuery({
    queryKey: ['noticeList'],
    queryFn: () => noticeList(pageOffSet),
    enabled: errorCount === 1,
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    setErrorCount(failureCount);
  }, [failureCount]);

  useEffect(() => {
    refetch();
  }, [pageOffSet]);
  const { startDate, endDate, searchWord } = pageOffSet;

  const handleOnChange = ({ value, name }) => {
    if (name === 'page' || name === 'size') return setPageOffSet((prev) => ({ ...prev, [name]: value }));
    else
      return setSearchForm((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
  };
  const handelOnSearch = useCallback(() => {
    setErrorCount(0);
    return setPageOffSet((prev) => ({ ...prev, ...searchForm, page: 1 }));
  }, [searchForm, pageOffSet]);

  return (
    <ContentContainer>
      <PageTitle>공지사항 리스트</PageTitle>
      <SearchInputForm
        onClickSearch={() => handelOnSearch()}
        state={{ ...searchForm, size: pageOffSet.size }}
        onChange={handleOnChange}
        searchItems={noticeSearchItems}
        onClickCreated={() => navigate('/ad/notice_reg')}
      />
      <Table
        items={data?.data?.content}
        rows={noticeColumn}
        pageMeta={{
          page: data?.data?.number + 1 || 0,
          pageSize: data?.data?.size || 0,
          total: data?.data?.totalElements || 0,
        }}
        onClick={({ id }) => navigate(`/ad/notice_edit/${id}`)}
      />
      {data?.data?.totalElements > 0 && (
        <MainPagination
          total={data?.data?.totalElements || 1}
          limit={pageOffSet.size}
          currentPage={pageOffSet.page}
          onChange={handleOnChange}
        />
      )}
    </ContentContainer>
  );
};

export default NoticeList;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;
`;
