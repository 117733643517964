// 서비스 신청 리스트 페이지 검색조건
export const applyServiceSearchItems = [
  {
    type: 'date',
    name: 'datePicker',
    label: '등록일시',
  },
  {
    type: 'radio',
    name: 'status',
    label: '상태',
    options: [
      {
        name: '전체',
        value: '',
      },
      {
        name: '미확인',
        value: '1',
      },
      {
        name: '확인',
        value: '2',
      },
    ],
  },
  {
    type: 'select',
    name: 'searchOption',
    label: '검색조건',
    options: [
      {
        name: '회사명',
        value: '1',
      },
      {
        name: '담당자명',
        value: '2',
      },
      {
        name: '연락처',
        value: '3',
      },
      {
        name: '이메일',
        value: '4',
      },
      {
        name: '사업자번호',
        value: '5',
      },
    ],
  },

  {
    type: 'text',
    name: 'searchWord',
    label: '검색어',
  },
];

// faq 리스트 페이지 검색조건
export const faqSearchItems = [
  {
    type: 'date',
    name: 'datePicker',
    label: '등록일시',
  },
  {
    type: 'select',
    name: 'category',
    label: '카테고리',
    options: [
      {
        name: '전체',
        value: '',
      },
      {
        name: '결제관리/정산관리',
        value: '1',
      },
      {
        name: '결제문의/정산문의',
        value: '2',
      },
      {
        name: '이용문의',
        value: '3',
      },
    ],
  },

  {
    type: 'text',
    name: 'searchWord',
    label: '검색어',
    placeholder: '제목을 입력해주세요.',
  },
];

// 공지사항 리스트 페이지 검색조건
export const noticeSearchItems = [
  {
    type: 'date',
    name: 'datePicker',
    label: '등록일시',
  },
  {
    type: 'radio',
    name: 'searchOption',
    label: '상태',
    options: [
      {
        name: '전체',
        value: '',
      },
      {
        name: '일반',
        value: '0',
      },
      {
        name: '중요',
        value: '1',
      },
    ],
  },

  {
    type: 'text',
    name: 'searchWord',
    label: '검색어',
    placeholder: '제목을 입력해주세요.',
  },
];

// 서비스 문의 리스트 페이지 검색조건
export const questionSearchItems = [
  {
    type: 'date',
    name: 'datePicker',
    label: '등록일시',
  },
  {
    type: 'radio',
    name: 'status',
    label: '상태',
    options: [
      {
        name: '전체',
        value: '',
      },
      {
        name: '답변대기',
        value: '0',
      },
      {
        name: '답변완료',
        value: '1',
      },
    ],
  },
  {
    type: 'select',
    name: 'searchOption',
    label: '검색조건',
    options: [
      {
        name: '문의유형',
        value: '0',
      },
      {
        name: '제목',
        value: '1',
      },
      {
        name: '성명',
        value: '2',
      },
      {
        name: '이메일',
        value: '3',
      },
    ],
  },

  {
    type: 'text',
    name: 'searchWord',
    label: '검색어',
  },
];

// 자료실 리스트 페이지 검색조건
export const referenceSearchItems = [
  {
    type: 'date',
    name: 'datePicker',
    label: '등록일시',
  },
  // {
  //   type: 'select',
  //   name: 'searchOption',
  //   label: '카테고리',
  //   options: [
  //     {
  //       name: '전체',
  //       value: '',
  //     },
  //     {
  //       name: 'PG서비스',
  //       value: '1',
  //     },
  //     {
  //       name: '통신개통',
  //       value: '2',
  //     },
  //     {
  //       name: 'BigOne ERP',
  //       value: '3',
  //     },
  //   ],
  // },
  // {
  //   type: 'select',
  //   name: 'category',
  //   label: '검색조건',
  //   options: [
  //     {
  //       name: '제목',
  //     },
  //   ],
  // },
  {
    type: 'text',
    name: 'searchWord',
    label: '검색어',
    placeholder: '제목을 입력해주세요.',
  },
];

// 관리자 관리 페이지 검색조건
export const superAdminSearchItems = [
  {
    type: 'date',
    name: 'datePicker',
    label: '등록일시',
  },
  {
    type: 'select',
    name: 'searchOption',
    label: '검색조건',
    options: [
      // {
      //   name: '전체',
      //   value: '',
      // },
      {
        name: '아이디',
        value: '1',
      },
      {
        name: '이름',
        value: '2',
      },
      {
        name: '연락처',
        value: '3',
      },
      {
        name: '이메일',
        value: '4',
      },
      {
        name: '소속',
        value: '5',
      },
    ],
  },
  {
    type: 'text',
    name: 'searchWord',
    label: '검색어',
  },
];
