export const emailSelectedOptions = [
  {
    label: '직접입력',
    value: '',
  },
  {
    label: 'naver.com',
    value: 'naver.com',
  },
  {
    label: 'daum.net',
    value: 'daum.net',
  },
  {
    label: 'gmail.com',
    value: 'gmail.com',
  },
  {
    label: 'nate.com',
    value: 'nate.com',
  },
];

export const questionTypeOptions = [
  { value: '전자결제 서비스', label: '전자결제 서비스' },
  { value: '플랫폼 서비스', label: '플랫폼 서비스' },
  { value: '부가 서비스', label: '부가 서비스' },
];
