import React, { useEffect, useState } from 'react';
import Section from '../../../layout/web/Section';
import ServiceSectionTypography from '../../../components/web/texts/ServiceSectionTypography';
import Tab from '../../../components/web/tabs/Tab';
import AccordionList from '../../../components/web/accordions/AccordionList';
import { useNavigate, useParams } from 'react-router-dom';
import { FAQTabItems, mFAQTabItems } from '../../../utility/web/items/customerService';
import { useQuery } from '@tanstack/react-query';
import { getFaqList } from '../../../api/web/customerService/faq';
import { useMediaQuery } from '@mui/material';

const FrequentlyAskedQuestion = () => {
  const xs = useMediaQuery('(max-width: 600px)');
  //"1: 결제관리/정산관리, 2: 결제문의/정산문의, 3: 이용문의"
  const [pageOffSet, setPageOffSet] = useState({
    page: 1,
    size: 10,
    startDate: '',
    endDate: '',
    category: 1,
  });
  const { category } = useParams();
  const nav = useNavigate();
  const { data } = useQuery({
    queryKey: ['faqList', pageOffSet.category],
    queryFn: () => getFaqList(pageOffSet),
  });
  useEffect(() => {
    setPageOffSet((prev) => {
      return {
        ...prev,
        category: category === 'paymentManagement' ? 1 : category === 'paymentInquiry' ? 2 : 3,
      };
    });
    category !== 'inquiryAboutUse' && category !== 'paymentInquiry' && category !== 'paymentManagement' && nav('/404');
  }, [category]);

  return (
    <Section className={'min-h-[71vh]'}>
      <Section
        maxSize
        className={'h-full pt-[180px] md:pt-[140px] sm:pt-[103px] xs:pt-[103px] fold:pt-[103px] transition-all'}>
        <ServiceSectionTypography
          title={'자주묻는 질문'}
          className={'text-dark1A text-center mb-[70px] md:mb-[60px] sm:mb-[50px] xs:mb-[40px] fold:mb-[40px]'}
        />
        <Tab items={xs ? mFAQTabItems : FAQTabItems} />
        <div className={'h-auto mb-[120px] border-t-dark1A border-b-darkDB border-b-[1px] border-t-2 border-solid'}>
          <AccordionList items={data?.data?.content} />
        </div>
      </Section>
    </Section>
  );
};

export default FrequentlyAskedQuestion;
