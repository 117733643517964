import { Outlet } from 'react-router-dom';
import Header from './Header';
import Nav from './Nav';
import styled from 'styled-components';

const AdminLayout = () => {
  return (
    <AdminWrapper>
      {/* 네비게이션 바 */}
      <Nav></Nav>
      <AdminMain>
        <Header></Header>
        <Contents>
          <Outlet />
        </Contents>
      </AdminMain>
    </AdminWrapper>
  );
};

export default AdminLayout;
const AdminWrapper = styled.div`
  user-select: none;
  min-width: 1920px;
  height: 100vh;
  position: relative;
`;
const AdminMain = styled.div`
  padding-left: 258px;
  width: 100%;
  height: 100vh;
`;
const Contents = styled.div`
  height: calc(100vh - 65px);
  position: relative;
  overflow-y: scroll;
  padding: 70px 0px;
`;
