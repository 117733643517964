import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ReactComponent as NicePay } from '../../../assets/icons/companys/nicePay.svg';
import { ReactComponent as KakaoPay } from '../../../assets/icons/companys/kakaoPay.svg';
import { ReactComponent as KCP } from '../../../assets/icons/companys/KCP.svg';
import { ReactComponent as SamsungPay } from '../../../assets/icons/companys/samsungPay.svg';
import { ReactComponent as SmilePay } from '../../../assets/icons/companys/smilePay.svg';
import { ReactComponent as KGMobilians } from '../../../assets/icons/companys/KGMobilians.svg';
import { ReactComponent as TossPay } from '../../../assets/icons/companys/tossPay.svg';
import { ReactComponent as HectoData } from '../../../assets/icons/companys/hectoData.svg';
import { ReactComponent as PayLetter } from '../../../assets/icons/companys/payLetter.svg';
import { ReactComponent as KSNet } from '../../../assets/icons/companys/KSNET.svg';
import { ReactComponent as Smartro } from '../../../assets/icons/companys/smartro.svg';
import { ReactComponent as Payco } from '../../../assets/icons/companys/payco.svg';
import { ReactComponent as Danal } from '../../../assets/icons/companys/danal.svg';
import { ReactComponent as DaouData } from '../../../assets/icons/companys/daouData.svg';
import { ReactComponent as Paytus } from '../../../assets/icons/companys/paytus.svg';
import { useMediaQuery } from '@mui/material';

const InfiniteScrollImage = () => {
  const defaultSizeIcons = (bol) => {
    return bol ? (
      <Fragment>
        <NicePay />
        <KakaoPay />
        <KCP />
        <SamsungPay />
        <SmilePay />
        <KGMobilians />
        <TossPay />
        <HectoData />
      </Fragment>
    ) : (
      <Fragment>
        <PayLetter />
        <KSNet />
        <Smartro />
        <Payco /> <Danal />
        <DaouData />
        <Paytus />
      </Fragment>
    );
  };
  const xsSizeItems = (pos) => {
    switch (pos) {
      case 0:
        return (
          <Fragment>
            <NicePay />
            <KakaoPay />
            <KCP />
            <SamsungPay />
            <SmilePay />
          </Fragment>
        );
      case 1:
        return (
          <Fragment>
            <KGMobilians />
            <TossPay />
            <HectoData />
            <PayLetter />
            <KSNet />
          </Fragment>
        );
      case 2:
        return (
          <Fragment>
            <Smartro />
            <Payco />
            <Danal />
            <DaouData />
            <Paytus />
          </Fragment>
        );
      default:
        break;
    }
  };
  const fold = useMediaQuery('(max-width: 799px)');
  return (
    <div className={'relative w-full h-auto'}>
      {!fold ? (
        <InfiniteScrollContainer className={'pb-[120px] md:pb-[95px] sm:pb-[70px]  sm:hidden xs:hidden fold:hidden'}>
          <div
            className={
              'flex flex-row relative h-[145px] md:h-[130px] sm:h-[120px] mb-[70px] md:mb-[50px] sm:mb-[30px] '
            }>
            <div className={'top absolute left-0 animation-container'}>{defaultSizeIcons(true)}</div>
            <div className={'top absolute left-[1960px] md:left-[1760px] sm:left-[1440px] animation-container'}>
              {defaultSizeIcons(true)}
            </div>
          </div>
          <div className={'right relative h-[145px] md:h-[130px] sm:h-[120px] flex flex-row'}>
            <div className={'bot absolute left-0 animation-container'}>{defaultSizeIcons()}</div>
            <div className={'bot absolute left-[1715px] md:left-[1540px] sm:left-[1260px] animation-container'}>
              {defaultSizeIcons()}
            </div>
          </div>
        </InfiniteScrollContainer>
      ) : (
        <XsSizeInfiniteScrollContainer
          className={'hidden sm:block xs:block fold:block sm:pb-[60px] xs:pb-[60px] fold:pb-[60px]'}>
          <div className={' '}>
            <div className={'top'}>{xsSizeItems(0)}</div>
            <div className={'top'}>{xsSizeItems(0)}</div>
          </div>
          <div className={' '}>
            <div className={'center'}>{xsSizeItems(1)}</div>
            <div className={'center'}>{xsSizeItems(1)}</div>
          </div>
          <div className={'  '}>
            <div className={'top'}>{xsSizeItems(2)}</div>
            <div className={'top'}>{xsSizeItems(2)}</div>
          </div>
        </XsSizeInfiniteScrollContainer>
      )}
    </div>
  );
};

export default InfiniteScrollImage;

const InfiniteScrollContainer = styled.div`
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    position: relative;
    & svg {
      width: 185px;
      min-width: 185px;
      height: 185px;
      min-height: 185px;
      margin: 0 30px;
    }

    @keyframes topLg {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-1960px);
      }
    }

    @keyframes topMd {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-1760px);
      }
    }
    @keyframes topSm {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-1440px);
      }
    }

    @keyframes botLg {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-1715px);
      }
    }
    @keyframes botMd {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-1540px);
      }
    }
    @keyframes botSm {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-1265px);
      }
    }

    .animation-container {
      display: flex;
      &.top {
        animation: topLg 10s linear infinite;
      }
      &.bot {
        animation: botLg 12s linear infinite reverse;
      }
    }
    @media screen and (max-width: 1419.9px) {
      & svg {
        width: 160px;
        min-width: 160px;
        height: 160px;
        min-height: 160px;
        margin: 0 30px;
      }
      .top.animation-container {
        animation: topMd 10s linear infinite;
      }
      .bot.animation-container {
        animation: botMd 12s linear infinite reverse;
      }
    }

    @media screen and (max-width: 799px) {
      & svg {
        width: 140px;
        min-width: 140px;
        height: 140px;
        min-height: 140px;
        margin: 0 30px;
      }
      .top.animation-container {
        animation: topSm 10s linear infinite;
      }
      .bot.animation-container {
        animation: botSm 12s linear infinite reverse;
      }
    }
  }
`;

const XsSizeInfiniteScrollContainer = styled.div`
  & svg {
    width: 138px;
    min-width: 138px;
    height: 138px;
    min-height: 138px;
    margin: 0 25px;
  }
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    position: relative;
    height: 108px;
    min-height: 108px;
    margin-bottom: 20px;
    & > div {
      position: absolute;
      display: flex;
      flex-direction: row;
      &:first-child {
        left: 0;
      }
      &:last-child {
        left: 939px;
      }
    }
  }

  .top {
    animation: topXs 8s linear infinite;
  }
  .center {
    animation: centerXs 10s linear infinite reverse;
  }

  @keyframes topXs {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-939px);
    }
  }
  @keyframes centerXs {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-939px);
    }
  }
`;
