import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { ReactComponent as DropDown } from 'assets/icons/admin/drop_down.svg';
import { css, styled } from 'styled-components';

import logo from 'assets/logos/mainLogoDark.svg';
import { userState, useNavMenuState } from 'recoil/auth';
import { useRecoilValue } from 'recoil';

const Nav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const HEIGHT = 51; // 45 + margin 6 포함
  const userMenu = useRecoilValue(useNavMenuState);

  // snbItem active 여부
  const isSnbItemActive = (id) => {
    const index = userMenu.findIndex((menu) => menu.id === id);
    if (index > -1) {
      const clickMenu = userMenu[index];
      if (!clickMenu.children) return isActive(id);
    }
    return false;
  };

  // active 효과를 위한 함수
  const isActive = (id) => {
    const activePath = location.pathname.split('/')[2];
    if (!activePath) return false;
    return activePath === id || activePath.includes(id);
  };

  // snb
  const snbItems = [];
  userMenu.forEach((menuItem) => {
    const submenu = [];

    if (menuItem.children) {
      menuItem.children.forEach((childItem) => {
        submenu.push(
          <SubMenuItem
            key={childItem.id}
            $active={isActive(childItem.id)}
            onClick={() => {
              navigate(`/ad/${childItem.id}`);
            }}>
            {childItem.name}
          </SubMenuItem>
        );
      });
    }
    snbItems.push(
      <SnbItem key={menuItem.id} id={menuItem.id}>
        <Item
          $active={isSnbItemActive(menuItem.id)}
          onClick={(e) => {
            snbItemClickEvent(e, menuItem.id);
          }}>
          <span>{menuItem.name}</span>
          {menuItem.children && <DropDown></DropDown>}
        </Item>
        {menuItem.children && <SubMenu>{submenu}</SubMenu>}
      </SnbItem>
    );
  });

  // 메뉴 클릭 이벤트
  const snbItemClickEvent = (e, id) => {
    const index = userMenu.findIndex((menu) => menu.id === id);
    if (index > -1) {
      const clickMenu = userMenu[index];
      if (!clickMenu.children) return navigate(`/ad/${clickMenu.id}`);
      else return openMenu(e);
    }
  };

  // 메뉴 드롭다운 효과 함수
  const openMenu = (e) => {
    const el = e.target.closest('li');
    const status = el.classList.contains('opened');

    const snb = e.target.closest('ul');
    const opendEl = snb.querySelectorAll('.opened');
    opendEl.forEach((element) => {
      element.classList.remove('opened');
      const elSubMenu = element.querySelector('ul');
      elSubMenu.style.height = '0rem';
    });

    const elSubMenu = el.querySelector('ul');
    if (status) {
      el.classList.remove('opened');
      elSubMenu.style.height = '0rem';
    } else {
      el.classList.add('opened');
      elSubMenu.style.height = `${HEIGHT * elSubMenu.childElementCount}px`;
    }
  };

  // useEffect(() => {
  //   const activePath = location.pathname.split('/')[2];
  //   let openMenu = '';
  //   switch (activePath) {
  //     case 'data_reg':
  //     case 'data_list':
  //       openMenu = 'data';
  //       break;
  //     case 'notice_list':
  //     case 'question_list':
  //       openMenu = 'cs';
  //       break;
  //     default:
  //       break;
  //   }
  //   if (openMenu) {
  //     const snbItemEl = document.querySelector(`#${openMenu}`);
  //     const status = snbItemEl.classList.contains('opened');
  //     if (!status) {
  //       const snb = snbItemEl.closest('ul');
  //       const opendEl = snb.querySelectorAll('.opened');

  //       opendEl.forEach((element) => {
  //         element.classList.remove('opened');
  //         const elSubMenu = element.querySelector('ul');
  //         elSubMenu.style.height = '0rem';
  //       });
  //       snbItemEl.classList.add('opened');
  //       const elSubMenu = snbItemEl.querySelector('ul');
  //       elSubMenu.style.height = `${HEIGHT * elSubMenu.childElementCount}px`;
  //     }
  //   }
  // }, [location.pathname]);

  return (
    <NavBar>
      {/* 로고 */}
      <LogoWrapper
        onClick={() => {
          navigate('/ad/apply');
        }}>
        <img src={logo} alt="로고" />
      </LogoWrapper>
      {/* SNB */}
      <Snb>{snbItems}</Snb>
    </NavBar>
  );
};

export default Nav;

const itemCss = css`
  height: 45px;
  padding: 12px 16px;
  border-radius: 12px;
  margin-bottom: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ $active }) => {
    if ($active) {
      return css`
        background-color: var(--color-admin-navy);
        color: white;
      `;
    }
  }}
  &:hover {
    background-color: ${({ $active }) => ($active ? 'var(--color-admin-navy)' : '#ebebf0')};
  }
`;

const NavBar = styled.div`
  background-color: #f9f9f9;
  width: 258px;
  position: absolute;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`;
// 로고
const LogoWrapper = styled.div`
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > img {
    padding-top: 8px;
  }
`;
// 사이드 네비게이션 바
const Snb = styled.ul`
  padding: 12px 16px;
  font-size: 18px;
`;
const Item = styled.div`
  ${itemCss};
  /* color: var(--color-dark-1); */
`;
const SnbItem = styled.li`
  transition: height ease 0.4s;
  > ${Item} {
    > svg {
      fill: var(--color-dark-1);
      transition: transform 0.3s linear;
      transform: rotate(0deg);
    }
  }
  &.opened {
    > ${Item} {
      > svg {
        transform: rotate(180deg);
      }
    }
  }
`;

const SubMenu = styled.ul`
  height: 0rem;
  overflow: hidden;
  transition: height ease 0.4s;
`;
const SubMenuItem = styled.li`
  ${itemCss}
  padding-left: 32px;
`;
