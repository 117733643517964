import React, { useCallback, useEffect, useState } from 'react';
import Section from '../../../layout/web/Section';
import ServiceSectionTypography from '../../../components/web/texts/ServiceSectionTypography';
import ReferenceRoomTable from '../../../components/web/tables/ReferenceRoomTable';
import MainPagination from '../../../components/web/pagination/MainPagination';
import { useQuery } from '@tanstack/react-query';
import { getReferenceRoom } from '../../../api/web/referenceRoom';
import { useParams } from 'react-router-dom';

const ReferenceRoom = () => {
	const { category } = useParams();

	const [pageOffSet, setPageOffSet] = useState({
		page: 1,
		size: 10,
	});
	const { data, isFetching, refetch } = useQuery({
		queryKey: ['referenceRoom', category],
		queryFn: () =>
			getReferenceRoom({
				...pageOffSet,
				searchOption:
					category === 'pgService'
						? 1
						: category === 'commencementCommunication'
						? 2
						: category === 'bizOneErp'
						? 3
						: '',
			}),
	});

	useEffect(() => {
		refetch();
	}, [pageOffSet.page]);

	const handleOnChangeCurrentPage = useCallback(
		({ name, value }) => {
			setPageOffSet((pageOffSet) => ({ ...pageOffSet, [name]: value }));
		},
		[setPageOffSet],
	);

	return (
		<Section>
			<Section
				maxSize
				className={
					'h-full pt-[180px] md:pt-[140px] sm:pt-[103px] xs:pt-[103px] fold:pt-[103px] mb-[120px] md:mb-[90px] sm:mb-[60px] xs:mb-[50px] fold:mb-[50px] transition-all'
				}>
				<ServiceSectionTypography
					title={'자료실'}
					className={
						'text-dark1A text-center mb-[100px] md:mb-[80px] sm:mb-[60px] xs:mb-[50px] fold:mb-[50px]'
					}
				/>

				<ReferenceRoomTable
					items={data?.data?.content || []}
					pageMeta={{
						page: data?.data?.number + 1 || 0,
						pageSize: data?.data?.size || 0,
						total: data?.data?.totalElements || 0,
					}}
				/>
				{data?.data?.content && data?.data?.content?.length > 0 && (
					<MainPagination
						total={data?.data?.totalElements || 1}
						limit={10}
						currentPage={pageOffSet.page}
						onChange={handleOnChangeCurrentPage}
					/>
				)}
			</Section>
		</Section>
	);
};

export default ReferenceRoom;
