import React, { useRef, useState, useEffect } from 'react';
import styled from '@emotion/styled';

const CustomScroll = ({ scrollHeight, clientHeight, scrollTop }) => {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const handler = () => {
      const documentElement = document.documentElement;
      const scrolled = documentElement.scrollTop;
      const maxHeight =
        documentElement.scrollHeight - documentElement.clientHeight;
      const scrollPercent = (scrolled / maxHeight) * 100;
      setScroll(scrollPercent);
    };
    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, []);

  return (
    <CustomScrollContainer>
      <CustomScrollBar style={{ height: scroll + '%' }} />
    </CustomScrollContainer>
  );
};

const CustomScrollContainer = styled.div`
  position: fixed;
  top: 80px;
  right: 0;
  height: calc(100% - 80px);
  width: 10px;

  display: block;

  @media (max-width: 799px) {
    top: 70px;
    height: calc(100% - 70px);
  }
  @media (max-width: 499px) {
    top: 65px;
    height: calc(100% - 65px);
  }
`;

const CustomScrollBar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  background: #1e90ff;
  border-radius: 5px;
`;

export default CustomScroll;
