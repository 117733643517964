import React, { useEffect, useRef } from 'react';
import Section from '../Section';
import styled from 'styled-components';

const ServerSection = ({ children, btnTitle, onClick }) => {
  const firstSectionTitleRef = useRef(null);

  const callback = (entries, io) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('active');
        io.unobserve(entry.target);
      }
    });
  };
  const sections = new IntersectionObserver(callback, {
    threshold: [0.2],
  });
  useEffect(() => {
    if (firstSectionTitleRef.current) sections.observe(firstSectionTitleRef.current);

    return () => {
      if (firstSectionTitleRef.current) sections.unobserve(firstSectionTitleRef.current);
    };
  }, []);
  return (
    <FadeInYSection className={'relative'}>
      <div ref={firstSectionTitleRef}>
        <img
          className={'absolute -z-[1] w-full h-full '}
          src={require('../../../assets/images/services/paymentBludBg.png')}
          alt=""
        />
        <Section maxSize className={'py-[60px] md:py-[55px]  sm:py-[45px] xs:py-[38px] fold:py-[38px] z-[1]'}>
          <div
            className={
              'text-white  text-heading2 font-[600] md:text-heading3 md:font-[600] sm:font-[600] xs:font-[600] fold:font-[600] sm:text-[20px] xs:text-[20px] fold:text-[20px] sm:leading-[30px] xs:leading-[30px] fold:leading-[30px] mb-[30px] sm:mb-[25px] xs:mb-[20px] fold:mb-[20px] fade-in-up'
            }>
            {children}
          </div>
          <button
            className={
              'bg-white text-main py-[10px] px-[30px] rounded-[10px] text-[20px] font-[600] sm:text-[16px] xs:text-[16px] fold:text-[16px] leading-normal fade-in-up-btn'
            }
            onClick={onClick}>
            {btnTitle}
          </button>
        </Section>
      </div>
    </FadeInYSection>
  );
};

export default ServerSection;

const FadeInYSection = styled(Section)`
  .fade-in-up {
    opacity: 0;
    transform: translateY(100px);
    transition:
      opacity 1s,
      transform 0.7s;
  }
  .fade-in-up-btn {
    opacity: 0;
    transform: translateY(100px);

    transition:
      opacity 1s,
      transform 0.7s;
    transition-delay: 0.2s;
  }
  & .active .fade-in-up,
  & .active .fade-in-up-btn {
    opacity: 1;
    transform: translateY(0);
  }
`;
