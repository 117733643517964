import { ReactComponent as Checked } from '../../assets/icons/web/checkBox/checkBoxVector.svg';
import styled from 'styled-components';
import { memo } from 'react';

const CheckBox = ({ value, setValue }) => {
  const handleOnChangeChecked = () => {
    setValue((value) => !value);
  };

  return (
    <CustomCheckBox className="custom-checkbox">
      <label className="custom-checkbox">
        <input type="checkbox" className="hidden-input" checked={value} onChange={() => handleOnChangeChecked()} />
        <span className="checkmark" />
        <Checked className="checked-icon" />
      </label>
    </CustomCheckBox>
  );
};

export default memo(CheckBox);

const CustomCheckBox = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;

  .hidden-input {
    display: none;
  }

  .custom-checkbox {
    position: relative;
    display: inline-block;
    width: 26px;
    height: 26px;
    overflow: hidden;
    cursor: pointer;
    @media all and (max-width: 499.9px) {
      width: 20px;
      height: 20px;
    }
  }

  & label {
    & span {
      position: absolute;
      top: 0;
      left: 0;
      width: 26px;
      height: 26px;
      border-radius: 7px;
      background-color: #fff;
      border: 2px solid #dbdbdb;
      @media all and (max-width: 499.9px) {
        width: 20px;
        height: 20px;
      }
    }
  }

  .hidden-input:checked + .checkmark {
    background-color: #0081ff;
  }

  .checked-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    visibility: hidden;
  }

  .hidden-input:checked ~ .checked-icon {
    visibility: visible;
  }
`;
