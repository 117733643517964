import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Lock } from '../../../assets/icons/web/question/lock.svg';
import { getDayFormatHypenV2 } from '../../../utility/admin/timeFormat';

const QuestionTable = ({ items, onClick }) => {
  return (
    <QuestionTableContainer className={'w-full '}>
      <table
        className={
          'w-full text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall leading-[30px] font-[500] text-dark1A  whitespace-nowrap'
        }>
        {items.length !== 0 ? (
          <thead className={'h-[60px]'}>
            <tr className={'w-full'}>
              <th className={'w-[11.7%]  align-middle font-[500] pl-[20px]'}>답변상태</th>
              <th className={'w-[64%] fold:w-auto align-middle font-[500]'}>제목</th>
              <th className={'w-[10.7%] align-middle font-[500] xs:hidden fold:hidden'}>등록일</th>
              <th className={'w-[10.7%]  align-middle font-[500] pr-[20px]'}>작성자</th>
            </tr>
          </thead>
        ) : (
          <thead className={'h-[60px]'}></thead>
        )}
        <tbody className={'border-t-[1px] border-solid border-t-darkDB '}>
          {items?.map((item, index) => (
            <tr
              key={index}
              onClick={() => onClick(item.id)}
              className={'h-[70px] tableRow xs:text-bodyXSmall fold:text-bodyXSmall cursor-pointer'}>
              <td className={`text-center pl-[20px] ${item?.useAnswer === 0 ? '' : 'text-main'}`}>
                {!item?.useAnswer || item?.useAnswer === 0 ? '답변대기' : '답변완료'}
              </td>
              <td className={'text-start pl-[30px]'}>
                <div className={'flex h-full items-center'}>
                  <Lock className={'mr-[10px]'} /> <span>{item.title}</span>
                </div>
              </td>
              <td className={'text-center text-dark49 xs:hidden fold:hidden '}>
                {getDayFormatHypenV2(item.createdDate)}
              </td>
              <td className={'text-center text-dark49 pr-[20px]'}>{item.name.slice(0, 1)}**</td>
            </tr>
          ))}
        </tbody>
      </table>
    </QuestionTableContainer>
  );
};

export default QuestionTable;

const QuestionTableContainer = styled.div`
  table {
    width: 100%;
    //border-collapse: separate;
    //border-spacing: 0 20px;
    thead {
      tr {
        td:first-child {
          padding-left: 120px;
        }
      }
    }
  }
  & * {
    transition: all 0.1s;
  }
  & tbody .tableRow {
    & svg path {
      fill: rgba(188, 188, 188, 1);
      stroke: rgba(188, 188, 188, 1);
    }

    & > td {
      vertical-align: middle;
      & svg {
        width: 19.6px;
        height: 20px;
        fill: #1a1a1a;
        stroke: #1a1a1a;
        & path {
          width: 17px;
          height: 13px;
          fill: #1a1a1a;
          stroke: #1a1a1a;
        }
      }
    }

    &:hover td {
      background-color: #f9f9f9;
      &:nth-child(2) {
        & > div {
          color: #0081ff;
          & svg path {
            fill: #0081ff;
            stroke: #0081ff;
          }
        }
      }
    }
  }
`;
