import React, { useState } from 'react';
import { ReactComponent as Search } from '../../assets/icons/web/search.svg';

import styled from 'styled-components';

const SearchInput = ({ value, onChange, onKeyDown }) => {
  return (
    <InputBox className={'mb-[40px] xs:mb-[30px] fold:mb-[30px]'}>
      <Search className={'w-[24px] h-[24px] absolute top-1/2 -translate-y-1/2 left-[20px]'} />
      <input
        type="text"
        className={
          '  h-[54px] outline-none focus:border-main py-[15px] pl-[54px] xs:pl-[50px] fold:pl-[50px] text-bodyXSmall'
        }
        placeholder={'검색어를 입력해주세요.'}
        onKeyDown={onKeyDown}
        onChange={onChange}
        value={value || ''}
      />
    </InputBox>
  );
};

export default SearchInput;

const InputBox = styled.div`
  position: relative;
  width: 100%;
  & * {
    transition: all 0.2s;
  }

  & input {
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(219, 219, 219, 1);

    &::placeholder {
      color: rgba(219, 219, 219, 1);
    }
    &:focus {
      border-color: rgba(0, 129, 255, 1);
    }
  }
`;
