import { BottomBtnWrapper, ContentContainer, PageTitle, TableWrapStyled } from 'styles/sc/admin';
import Select from '../../../components/admin/Select';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TextFieldInput } from '../../../components/admin/TextField';
import TextAreaInput from '../../../components/inputs/TextAreaInput';
import { TextArea } from '../../../components/admin/TextArea';
import styled from 'styled-components';
import { BUButton } from '../../../components/admin/BUButton';
import { faqCreate, faqDelete, faqDetail, faqEdit } from '../../../api/admin/faq';
import { Toast } from '../../../components/toast';
import { useNavigate, useParams } from 'react-router-dom';
import { ModalContext } from 'components/modal/modalContext';

const selectedOptions = [
  {
    name: '결제관리/정산관리',
    value: '1',
  },
  {
    name: '결제문의/정산문의',
    value: '2',
  },
  {
    name: '이용문의',
    value: '3',
  },
];

const FaqForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const mode = useMemo(() => {
    return id ? '수정' : '등록';
  }, [id]);

  const { openModal, closeModal } = useContext(ModalContext);
  const [inputs, setInputs] = useState({
    category: '1',
    title: '',
    content: '',
  });

  const valid = () => {
    let status = false;
    if (!inputs.title.length) {
      Toast.info('제목을 작성해주세요.');
      return document.querySelector('input[name=title]').focus();
    } else if (inputs.content.length < 10) {
      Toast.info('답변을 10자 이상 작성해주세요');
      return document.querySelector('textarea[name=content]').focus();
    } else {
      status = true;
    }
    return status;
  };

  useEffect(() => {
    if (!id) return;
    const getFaq = async () => {
      try {
        const { data, status } = await faqDetail(id);
        setInputs(data);
      } catch (e) {
        return Toast.error('FAQ 를 불러오는 중 오류가 발생하였습니다.');
      }
    };
    getFaq();

    return () => {
      setInputs({
        category: '1',
        title: '',
        content: '',
      });
    };
  }, []);

  const handleChange = (e) => {
    setInputs((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const onChangeSelect = (e) => {
    setInputs({
      ...inputs,
      category: e.currentTarget.value,
    });
  };

  const handleClick = async () => {
    try {
      if (!valid()) return;
      let responseStatus = null;
      let responseData = null;
      if (id) {
        const { data, status } = await faqEdit(id, inputs);
        responseStatus = status;
        responseData = data;
      } else {
        const { data, status } = await faqCreate(inputs);
        responseStatus = status;
        responseData = data;
      }
      if (responseStatus === 200) {
        Toast.success(`FAQ ${mode}이 완료되었습니다.`);
        navigate(`/ad/faq`);
      } else {
        const { message } = responseData;
        Toast.error(message);
      }
    } catch (e) {
      return Toast.error(`FAQ ${mode} 중 오류가 발생하였습니다.`);
    }
  };
  const handleOnDeleteData = useCallback(async () => {
    openModal('삭제하시겠습니까?', {
      confirm: async () => {
        faqDelete(id).then(({ status }) => {
          if (status === 200) {
            Toast.success('삭제 되었습니다.');
            closeModal();
            return navigate('/ad/faq');
          }
          return Toast.error('작업 처리 도중 오류가 발생하였습니다.');
        });
      },
    });
  }, [id]);

  return (
    <ContentContainer>
      <PageTitle>FAQ {mode}</PageTitle>
      <TableWrapStyled>
        <table>
          <tbody>
            <tr>
              <th>카테고리</th>
              <td>
                <Select
                  options={selectedOptions}
                  value={inputs.category}
                  onChange={onChangeSelect}
                  style={{ display: 'block', marginLeft: 0, width: '250px' }}
                />
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td>
                <TextFieldInput
                  width={'500px'}
                  height={'42px'}
                  onChange={handleChange}
                  name="title"
                  value={inputs.title}
                />
              </td>
            </tr>

            <tr>
              <th>답변</th>
              <td>
                <TextArea onChange={handleChange} name="content" value={inputs.content} />
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapStyled>
      <BottomBtnWrapper>
        {id && (
          <>
            <BUButton color="gray" size="small-1" onClick={() => navigate('/ad/faq')}>
              목록
            </BUButton>
            <BUButton color="red" size="small-1" onClick={handleOnDeleteData}>
              삭제
            </BUButton>
          </>
        )}
        <BUButton size="small-1" onClick={handleClick}>
          {id ? '수정' : '등록'}
        </BUButton>
      </BottomBtnWrapper>
    </ContentContainer>
  );
};

const ButtonWrap = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: center;
`;

export default FaqForm;
