import { ContentContainer, PageTitle, TableWrapStyled } from 'styles/sc/admin';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { answerCreate, answerEdit, inquiryDelete, inquiryDetail } from '../../../api/admin/question';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingPage } from '../../../components/loading/LoadingPage';
import { getFullDayFormatHypen } from '../../../utility/admin/timeFormat';
import { TextArea } from '../../../components/admin/TextArea';
import styled from 'styled-components';
import { BUButton } from '../../../components/admin/BUButton';
import { Toast } from '../../../components/toast';
import { ModalContext } from 'components/modal/modalContext';

const QuestionDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const { openModal, closeModal } = useContext(ModalContext);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const { data, status } = await inquiryDetail(id);
      setData(data);
      setIsUpdate(!!data.answer);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleAnswerChange = (e) => {
    setData((prev) => {
      return {
        ...prev,
        answer: { ...prev.answer, content: e.target.value },
      };
    });
  };

  const update = async () => {
    try {
      await answerEdit(data.answer.id, { content: data.answer.content });
      return Toast.success('답변이 수정되었습니다.');
    } catch (e) {
      return Toast.error('업데이트를 하는 중 오류가 발생하였습니다.');
    }
  };

  const create = async () => {
    try {
      await answerCreate({ inquiryId: data.id, content: data.answer.content });
      getData();
      return Toast.success('답변이 등록되었습니다.');
    } catch (e) {
      return Toast.error('등록 중 오류가 발생하였습니다.');
    }
  };
  const handleSubmit = () => {
    if (!data.answer?.content.length) {
      Toast.info('답변을 등록해주세요.');
      return document.querySelector('textarea[name=content]').focus();
    }
    if (isUpdate) {
      update();
    } else {
      create();
    }
  };

  const handleOnDeleteData = useCallback(async () => {
    openModal('삭제하시겠습니까?', {
      confirm: async () => {
        const { status } = await inquiryDelete(id);
        if (status !== 200) {
          return Toast.error('삭제 도중 오류가 발생하였습니다.');
        }
        Toast.success('삭제가 완료되었습니다.');
        closeModal();
        navigate(`/ad/question`);
      },
    });
  }, [id]);

  const goList = () => {
    navigate(`/ad/question`);
  };

  if (isLoading) return <LoadingPage />;

  return (
    <ContentContainer>
      <PageTitle>문의 상세보기</PageTitle>
      <TableWrapStyled>
        <DetailTable>
          <tbody>
            <tr>
              <th>등록일시</th>
              <td>{getFullDayFormatHypen(data.createdDate)}</td>
            </tr>
            <tr>
              <th>수정일시</th>
              <td>{getFullDayFormatHypen(data?.answer?.updatedDate || data.createdDate)}</td>
            </tr>
            <tr>
              <th>상태</th>
              <td>{isUpdate ? '답변완료' : '답변대기'}</td>
            </tr>
            {isUpdate && (
              <tr>
                <th>최종답변등록자</th>
                <td>{`${data.answer.user.username} (${data.answer.user.name})`}</td>
              </tr>
            )}
            <tr>
              <th>성명</th>
              <td>{data.name}</td>
            </tr>
            <tr>
              <th>연락처</th>
              <td>{data.phone}</td>
            </tr>
            <tr>
              <th>이메일</th>
              <td>{data.email}</td>
            </tr>
            <tr>
              <th>문의유형</th>
              <td>{data.inquiryType}</td>
            </tr>
            <tr>
              <th>제목</th>
              <td>{data.title}</td>
            </tr>
            <tr>
              <th>내용</th>
              <td>
                <div className="content">{data.content}</div>
              </td>
            </tr>
            <tr>
              <th>답변</th>
              <td>
                <TextArea onChange={handleAnswerChange} name="content" value={data.answer?.content} />
              </td>
            </tr>
          </tbody>
        </DetailTable>
      </TableWrapStyled>
      <ButtonWrap>
        <BUButton color="gray" size="small-1" onClick={goList}>
          목록
        </BUButton>
        <BUButton color="red" size="small-1" onClick={handleOnDeleteData}>
          삭제
        </BUButton>
        <BUButton size="small-1" onClick={handleSubmit}>
          {isUpdate ? '답변수정' : '답변등록'}
        </BUButton>
      </ButtonWrap>
    </ContentContainer>
  );
};

const DetailTable = styled.table`
  > tbody {
    > tr {
      > td {
        padding-left: 20px !important;
        padding-right: 20px !important;
      }
    }
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;
export default QuestionDetail;
