import React, { useEffect, useRef, useState } from 'react';
import Section from '../../../layout/web/Section';
import ServiceSectionTypography from '../../../components/web/texts/ServiceSectionTypography';
import ApplyProcedureCard from '../../../components/web/cards/ApplyProcedureCard';
import { applyProcedureItems, mApplyProcedureItems } from '../../../utility/web/items/applyService';
import styled from 'styled-components';
import { useMediaQuery } from '@mui/material';

const ApplyProcedure = () => {
  const xs = useMediaQuery('(max-width:799.9px)');
  const md = useMediaQuery('(max-width:1050px)');

  const applyCardRef = useRef(null);
  const [observe, setObserve] = useState(false);
  const callback = (entries, io) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.isIntersecting) {
        setObserve(true);

        entry?.target?.classList?.add('active');
        io.unobserve(entry.target);
      }
    });
  };
  const sections = new IntersectionObserver(callback, {
    threshold: [md ? 0.1 : 0.5],
  });
  useEffect(() => {
    if (applyCardRef.current) sections.observe(applyCardRef.current);
    return () => {
      if (applyCardRef.current) sections.unobserve(applyCardRef.current);
    };
  }, []);
  return (
    <Section className={'bg-darkF9'}>
      <Section
        maxSize
        className={
          'flex flex-col items-center justify-center h-full md:pb-[120px] sm:pb-[50px] xs:pb-[50px] fold:pb-[50px] pt-[180px] md:pt-[140px] sm:pt-[103px] xs:pt-[103px] fold:pt-[103px] transition-all '
        }>
        <ServiceSectionTypography
          title={'서비스 신청절차'}
          className={'text-dark1A mb-[89px] md:mb-[70px] sm:mb-[60px] xs:mb-[50px] fold:mb-[50px] '}
        />
        <FadeInYSection
          ref={applyCardRef}
          className={
            'gap-[30px] lg:gap-[40px] grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 w-full mb-[120px] md:mb-[90px] sm:mb-[70px] xs:mb-[50px] fold:mb-[50px] xs:px-[30px] fold:px-0'
          }>
          {(!xs ? mApplyProcedureItems : applyProcedureItems).map((item, index) => (
            <ApplyProcedureCard key={item.title} item={item} observe={observe} index={index} />
          ))}
        </FadeInYSection>
      </Section>
    </Section>
  );
};

export default ApplyProcedure;

const FadeInYSection = styled.div`
  .fade-in-up {
    opacity: 0;
    transform: translateY(100px);
    transition:
      opacity 1s,
      transform 0.7s;
  }
  &.active .fade-in-up {
    opacity: 1;
    transform: translateY(0);
  }
`;
