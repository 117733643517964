import React from 'react';
import Section from '../../../layout/web/Section';
import ServiceSectionTypography from '../../../components/web/texts/ServiceSectionTypography';
import { ReactComponent as Mapper } from '../../../assets/icons/web/company/mapper.svg';
import { ReactComponent as Phone } from '../../../assets/icons/web/company/phone.svg';
import { ReactComponent as Email } from '../../../assets/icons/web/company/email.svg';
import { ReactComponent as Fax } from '../../../assets/icons/web/company/fax.svg';
import DirectionsCardItem from '../../../components/web/cards/DirectionsCardItem';
import KakaoMap from '../../../components/web/maps/KakaoMap';
import { brown } from '@mui/material/colors';

const Directions = () => {
  return (
    <Section>
      <Section
        maxSize
        className={'h-full pt-[180px] md:pt-[140px] sm:pt-[103px] xs:pt-[103px] fold:pt-[103px] transition-all'}>
        <ServiceSectionTypography
          title={'오시는 길'}
          className={'text-dark1A text-center mb-[70px] md:mb-[60px] sm:mb-[50px] xs:mb-[40px] fold:mb-[40px]'}
        />
        <div
          className={
            'h-[600px] md:h-[500px] sm:h-[400px] xs:h-[300px] fold:h-[300px] w-full rounded-[15px] overflow-hidden relative mb-[60px]'
          }>
          <KakaoMap />
        </div>
        <div
          className={
            'flex justify-between px-[10px] directions:flex-col transition-all mb-[120px] md:mb-[80px] directions:mb-[50px]'
          }>
          <div className={'w-[46.3%] directions:w-full  xs:max-w-[260px] fold:max-w-[260px]'}>
            <DirectionsCardItem
              icon={<Mapper />}
              title={'주소'}
              content={`인천 연수구 인천타워대로323, A동 2907-09호 (송도 센트로드)`}
            />
            <div className={'w-full h-[1px] border-b-[1px] border-solid border-b-darkDB my-[30px] directions:hidden'} />
            <DirectionsCardItem icon={<Fax />} title={'팩스'} content={'032-232-5030'} />
          </div>
          <div className={'w-[46.3%] directions:w-full'}>
            <DirectionsCardItem icon={<Email />} title={'이메일'} content={'info@baumpns.com'} />
            <div className={'w-full h-[1px] border-b-[1px] border-solid border-b-darkDB my-[30px] directions:hidden'} />
            <DirectionsCardItem icon={<Phone />} title={'대표전화'} content={'(유선번호) 1833-6199'} />
          </div>
          <span className={'hidden  border-t-[1px] border-solid border-[#DBDBDB] mt-[10px] directions:block'} />
        </div>
      </Section>
    </Section>
  );
};

export default Directions;
