import { ContentContainer, PageTitle, TableWrapStyled } from 'styles/sc/admin';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';
import { applyCheck, applyDetail } from '../../../api/admin/applyService';
import { getFullDayFormatHypen } from '../../../utility/admin/timeFormat';
import { BUButton } from '../../../components/admin/BUButton';
import styled from 'styled-components';
import { fileBoardDataDeleteAPI } from '../../../api/admin/fileBoard';
import { Toast } from '../../../components/toast';
import { ModalContext } from '../../../components/modal/modalContext';
import Button from 'components/admin/Button';
import { bizNoFormatter, formatPhoneNumber } from 'utility/format';

const ApplyDetail = () => {
  const nav = useNavigate();
  const [data, setData] = useState({});

  const { id } = useParams();
  const { openModal, closeModal } = useContext(ModalContext);

  const fetchData = useCallback(async () => {
    const { data, status } = await applyDetail(id);
    setData(data);
  }, [id]);

  const handleCheck = useCallback(async () => {
    openModal('확인처리를 하시겠습니까 ?', {
      confirm: async () => {
        try {
          const { status } = await applyCheck(id);
          if (status === 200) {
            Toast.success('확인처리가 완료되었습니다.');
            fetchData();
          }
        } catch (e) {
          Toast.error('확인 처리 중 오류가 발생하였습니다.');
        } finally {
          closeModal();
        }
      },
    });
  }, [id]);

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <ContentContainer>
      <PageTitle>서비스 신청 상세보기</PageTitle>
      <TableWrapStyled>
        <DetailTable>
          <tbody>
            <tr>
              <th>등록일시</th>
              <td>{getFullDayFormatHypen(data?.createdDate)}</td>
            </tr>
            {data?.checker && (
              <>
                <tr>
                  <th>확인일시</th>
                  <td>{getFullDayFormatHypen(data?.updatedDate)}</td>
                </tr>
                <tr>
                  <th>확인자</th>
                  <td>{`${data?.checker} (${data?.checkerName})`}</td>
                </tr>
              </>
            )}
            <tr>
              <th>상태</th>
              <td> {data?.checker ? '확인' : '미확인'}</td>
            </tr>
            <tr>
              <th>회사명</th>
              <td>{data?.companyName}</td>
            </tr>
            <tr>
              <th>담당자</th>
              <td>{data?.manager}</td>
            </tr>
            <tr>
              <th>연락처</th>
              <td>{formatPhoneNumber(data?.phone)}</td>
            </tr>
            <tr>
              <th>이메일</th>
              <td>{data?.email}</td>
            </tr>
            <tr>
              <th>사업자등록번호</th>
              <td>{bizNoFormatter(data?.companyRegNum)}</td>
            </tr>
            <tr>
              <th>제목</th>
              <td>{data?.title}</td>
            </tr>
            <tr>
              <th>내용</th>
              <td>
                <div className="content">{data?.content}</div>
              </td>
            </tr>
          </tbody>
        </DetailTable>
      </TableWrapStyled>
      {/*120*/}
      <ButtonGroupWrap>
        <BUButton
          color="gray"
          size="small-1"
          onClick={() => {
            nav('/ad/apply');
          }}>
          목록
        </BUButton>
        {!data?.checker && (
          <BUButton size="small-1" onClick={handleCheck}>
            확인 처리
          </BUButton>
        )}
      </ButtonGroupWrap>
    </ContentContainer>
  );
};

const DetailTable = styled.table`
  > tbody {
    > tr {
      > td {
        padding-left: 20px !important;
        padding-right: 20px !important;
      }
    }
  }
`;

const ButtonGroupWrap = styled.div`
  margin-top: 120px;
  display: flex;
  justify-content: center;
`;
export default ApplyDetail;
