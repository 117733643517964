import Button from 'components/admin/Button';
import TextField from 'components/admin/TextField';
import { ModalContext } from 'components/modal/modalContext';
import { Toast } from 'components/toast';
import { useCallback, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  BodySmall,
  BottomBtnWrapper,
  ContentContainer,
  FormBox,
  FormBoxItem,
  FormBoxItemTitle,
  FormBoxItemValue,
  PageTitle,
} from 'styles/sc/admin';
import { getFullDayFormatHypen } from 'utility/admin/timeFormat';
import { updatePassword } from '../../../api/admin/auth';
import styled from 'styled-components';
import { passwordRegex } from 'utility/web/regex';

const PwChangeForm = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    password: '',
    newPassword: '',
    newPasswordCheck: '',
    updatedDate: '',
  });
  const { openModal, closeModal } = useContext(ModalContext);
  const { state } = useLocation();

  const { password, newPassword, newPasswordCheck, updatedDate } = form;

  // 폼 데이터 변경
  const onChangeData = (e, setData) => {
    const { name, value } = e.currentTarget;
    setData((preVal) => ({ ...preVal, [name]: value }));
  };
  // 유효성 검사
  const validationCheck = useCallback(() => {
    if (!password || password.length === 0) {
      Toast.info('현재 비밀번호를 입력해주세요.');
      return document.querySelector('input[name=password]').focus();
    }
    if (!newPassword || newPassword.length === 0) {
      Toast.info('새 비밀번호를 입력해주세요.');
      return document.querySelector('input[name=newPassword]').focus();
    }
    if (!passwordRegex(newPassword)) {
      Toast.info('비밀번호 양식에 맞게 입력해주세요.');
      return document.querySelector('input[name=newPassword]').focus();
    }
    if (!newPasswordCheck || newPasswordCheck.length === 0) {
      Toast.info('새 비밀번호 확인을 해주세요.');
      return document.querySelector('input[name=newPasswordCheck]').focus();
    }
    if (newPasswordCheck !== newPassword) {
      Toast.info('비밀번호가 일치하지 않습니다.');
      return document.querySelector('input[name=newPasswordCheck]').focus();
    }
    handleOnSave();
  });

  const handleOnSave = useCallback(async () => {
    openModal('수정하시겠습니까?', {
      confirm: async () => {
        updatePassword({ password: password, newPassword: newPassword })
          .then(({ data, status }) => {
            if (status === 200) {
              navigate('/ad/auth');
              return Toast.success('비밀번호가 변경되었습니다.');
            }
            if (status === 400) {
              const { message } = data;
              Toast.error(message);
              return document.querySelector('input[name=password]').focus();
            }
            return Toast.error('작업 처리 도중 오류가 발생하였습니다.');
          })
          .catch((err) => {
            console.log('err : ', err);
          });

        closeModal();
      },
    });
  }, [form]);

  return (
    <ContentContainer>
      <PageTitle>비밀번호 변경</PageTitle>
      <FormBox
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            validationCheck();
          }
        }}>
        <FormBoxItem>
          <FormBoxItemTitle>현재 비밀번호</FormBoxItemTitle>
          <FormBoxItemValue>
            <TextField
              placeholder="현재 비밀번호"
              height="40px"
              width="250px"
              type={'password'}
              name="password"
              value={password}
              onChange={(e) => {
                onChangeData(e, setForm);
              }}></TextField>
          </FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>새 비밀번호</FormBoxItemTitle>
          <FormBoxItemValue>
            <TextField
              placeholder="새 비밀번호"
              height="40px"
              width="250px"
              type={'password'}
              name="newPassword"
              value={newPassword}
              onChange={(e) => {
                onChangeData(e, setForm);
              }}></TextField>
            <PwCaption>비밀번호 양식 : 8~20자 영문,숫자,특수문자 조합</PwCaption>
          </FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>새 비밀번호 확인</FormBoxItemTitle>
          <FormBoxItemValue>
            <TextField
              placeholder="새 비밀번호 확인"
              height="40px"
              width="250px"
              type={'password'}
              name="newPasswordCheck"
              value={newPasswordCheck}
              onChange={(e) => {
                onChangeData(e, setForm);
              }}></TextField>
          </FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>등록일시</FormBoxItemTitle>
          <FormBoxItemValue style={{ color: 'var(--color-dark-3)' }}>
            {getFullDayFormatHypen(state.updatedDate)}
          </FormBoxItemValue>
        </FormBoxItem>
      </FormBox>
      <BottomBtnWrapper>
        <Button
          style={{
            color: 'white',
            marginRight: '12px',
          }}
          width={'120px'}
          height={'42px'}
          color={'admin_navy'}
          onClick={() => {
            validationCheck();
          }}>
          <span>저장</span>
        </Button>
      </BottomBtnWrapper>
    </ContentContainer>
  );
};
export default PwChangeForm;

const PwCaption = styled.span`
  margin-left: 12px;
  color: #e53535;
  ${BodySmall};
`;
