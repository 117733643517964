import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/tailwind.css';
import 'styles/css/index.css';

import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Loading from './components/loading/Loading';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

const STYLED_THEME = {
  palette: {
    primary: {
      background: 'rgba(0, 129, 255, 1)',
      color: '#FFFFFF',
    },
    dark: {
      background: 'rgba(26, 26, 26, 1)',
      color: '#FFFFFF',
    },
    gray: {
      background: '#c7c9d9',
      color: '#FFFFFF',
    },
    red: {
      background: '#d9534f',
      color: '#FFFFFF',
    },
  },
};

root.render(
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <StyledThemeProvider theme={STYLED_THEME}>
        <BrowserRouter>
          <App />
          <Loading />
        </BrowserRouter>
      </StyledThemeProvider>
    </QueryClientProvider>
  </RecoilRoot>
);
