import React from 'react';
import { ReactComponent as EasyService } from '../../assets/icons/easyService.svg';
import { ReactComponent as CustomServer } from '../../assets/icons/customizedServices.svg';
import { ReactComponent as LawTeamServer } from '../../assets/icons/companyLawTeam.svg';
import { ReactComponent as CooperativeCompany } from '../../assets/icons/cooperativeCompany.svg';
import { ReactComponent as Pay } from '../../assets/icons/payIcon.svg';
import { ReactComponent as Sup } from '../../assets/icons/supplementaryServicesIcon.svg';
import { ReactComponent as Platform } from '../../assets/icons/platformServiceIcon.svg';

export const paymentSectionItems = [
	{
		icon: (
			<Pay
				className={
					'w-[73px] h-[73px] md:w-[63px] md:h-[63px] sm:w-[55px] sm:h-[55px] xs:w-[49px] xs:h-[49px] fold:w-[49px] fold:h-[49px]'
				}
			/>
		),

		title: '전자결제 서비스',
		content: `인터넷을 통한 온라인 결제 시 복잡한 시스템 구축 및 결제 단말기 없이 소비자가 신용카드 및 다양한 결제 수단을 이용하여 안전하게 결제할 수 있도록 지원하는 서비스입니다.`,
	},
	{
		icon: (
			<Platform
				className={
					'w-[73px] h-[73px] md:w-[63px] md:h-[63px] sm:w-[55px] sm:h-[55px] xs:w-[49px] xs:h-[49px] fold:w-[49px] fold:h-[49px]'
				}
			/>
		),

		title: '플랫폼 서비스',
		content:
			'스마트폰 APP 설치 후 결제하실 카드 번호 등록 또는 은행 계좌 연결을 통하여 카드 없이도 지문인증과 APP 결제 비밀번호를 통해 장소의 규제 없이 어디서든 간편하게 결제가 가능합니다.',
	},
	{
		icon: (
			<Sup
				className={
					'w-[73px] h-[73px] md:w-[63px] md:h-[63px] sm:w-[55px] sm:h-[55px] xs:w-[49px] xs:h-[49px] fold:w-[49px] fold:h-[49px]'
				}
			/>
		),

		title: '부가 서비스',
		content:
			'가맹점 및 고객사에 필요한 금융, 마케팅, 소싱 등 영업관리 및 마케팅 서비스입니다. 뿐만 아니라 국내가 아닌 해외에서도 위챗 페이, 해외송금업 등 여러 서비스를 통하여 결제하실 수 있도록 지원하는 토탈 솔루션 서비스입니다.',
	},
];

export const ServerSectionItems = [
	{
		icon: (
			<EasyService
				className={
					'w-[40px] h-[40px] md:w-[35px] md:h-[35px] sm:w-[27px] sm:h-[27px] xs:w-[20px] xs:h-[20px] fold:w-[20px] fold:h-[20px]'
				}
			/>
		),
		title: '간단한 서비스 계약',
		content: '누구나 쉽고 편리하게\n 서비스 계약 진행 가능',
	},
	{
		icon: (
			<CustomServer
				className={
					'w-[40px] h-[40px] md:w-[35px] md:h-[35px] sm:w-[27px] sm:h-[27px] xs:w-[20px] xs:h-[20px] fold:w-[20px] fold:h-[20px]'
				}
			/>
		),
		title: '고객 맞춤 서비스',
		content: '문의사항이나 불편사항\n 접수 시 일대일 맞춤 상담\n 서비스 제공',
	},
	{
		icon: (
			<LawTeamServer
				className={
					'w-[40px] h-[40px] md:w-[35px] md:h-[35px] sm:w-[27px] sm:h-[27px] xs:w-[20px] xs:h-[20px] fold:w-[20px] fold:h-[20px]'
				}
			/>
		),
		title: '자체법무팀 운영',
		content: '24시 모니터링을\n 통하여 사건, 사고 방지 및\n 필요 자료 제공',
	},
	{
		icon: (
			<CooperativeCompany
				className={
					'w-[40px] h-[40px] md:w-[35px] md:h-[35px] sm:w-[27px] sm:h-[27px] xs:w-[20px] xs:h-[20px] fold:w-[20px] fold:h-[20px]'
				}
			/>
		),
		title: '다양한 협력사',
		content:
			'여러 PG사 및 다양한 플랫폼\n 회사와 협약으로 수많은\n 편의성 제공',
	},
];
