import http from '../http';
import { getDayFormatHypenV2 } from '../../utility/admin/timeFormat';
export const inquiryList = (params) => {
  return http.get('/inquiry', {
    params: {
      ...params,
      startDate: getDayFormatHypenV2(params.startDate),
      endDate: getDayFormatHypenV2(params.endDate),
    },
  });
};

export const inquiryDetail = (id) => {
  return http.get(`/inquiry/${id}`);
};

export const answerCreate = (data) => {
  return http.post(`/answer`, data);
};

export const answerEdit = (id, data) => {
  // return http.put(`/answer/${id}`, data);
  return http.post(`/answer/${id}/put`, data);
};

export const inquiryDelete = (id) => {
  // return http.delete(`/inquiry/${id}`);
  return http.get(`/inquiry/${id}/delete`);
};
