import { ContentContainer, PageTitle } from 'styles/sc/admin';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { faqList } from '../../../api/admin/faq';
import Table from '../../../components/admin/Table';
import { faqColumn } from '../../../utility/admin/tableColumn';
import MainPagination from '../../../components/web/pagination/MainPagination';
import SearchInputForm from '../../../components/admin/SearchInputForm';
import { faqSearchItems } from '../../../utility/admin/searchItems';
import { useNavigate } from 'react-router-dom';

const FaqList = () => {
  const nav = useNavigate();
  const [searchForm, setSearchForm] = useState({
    startDate: '',
    endDate: '',
    category: '',
    searchWord: '',
  });

  const [pageOffSet, setPageOffSet] = useState({
    page: 1,
    size: 10,
    startDate: '',
    endDate: '',
    category: '',
    searchWord: '',
  });
  const [errorCount, setErrorCount] = useState(0);

  const { data, refetch, failureCount } = useQuery({
    queryKey: ['faqList'],
    queryFn: () => faqList(pageOffSet),
    enabled: errorCount === 1,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setErrorCount(failureCount);
  }, [failureCount]);

  useEffect(() => {
    refetch();
  }, [pageOffSet]);
  const handleOnChange = ({ value, name }) => {
    if (name === 'page' || name === 'size') return setPageOffSet((prev) => ({ ...prev, [name]: value }));
    else
      return setSearchForm((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
  };
  const handelOnSearch = useCallback(() => {
    setErrorCount(0);
    return setPageOffSet((prev) => ({ ...prev, ...searchForm, page: 1 }));
  }, [searchForm, pageOffSet]);

  return (
    <ContentContainer>
      <PageTitle>FAQ 리스트</PageTitle>
      <SearchInputForm
        onClickSearch={() => handelOnSearch()}
        state={{ ...searchForm, size: pageOffSet.size }}
        onClickCreated={() => nav('/ad/faq_reg')}
        onChange={handleOnChange}
        searchItems={faqSearchItems}
      />
      <Table
        items={data?.data?.content}
        rows={faqColumn}
        pageMeta={{
          page: data?.data?.number + 1 || 0,
          pageSize: data?.data?.size || 0,
          total: data?.data?.totalElements || 0,
        }}
        onClick={({ id }) => nav(`/ad/faq_edit/${id}`)}
      />
      {data?.data?.totalElements > 0 && (
        <MainPagination
          total={data?.data?.totalElements || 1}
          limit={pageOffSet.size}
          currentPage={pageOffSet.page}
          onChange={handleOnChange}
        />
      )}
    </ContentContainer>
  );
};
export default FaqList;
