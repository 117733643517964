import React, { useState } from 'react';
import TextInput from '../../inputs/TextInput';
import EmailInput from '../../inputs/EmailInput';
import TextAreaInput from '../../inputs/TextAreaInput';
import Selected from '../../inputs/Selected';
import PasswordInput from '../../inputs/PasswordInput';
import NumberInput from '../../inputs/NumberInput';
import { koreanRegex } from '../../../utility/web/regex';

const TextInputForm = ({
	title,
	type,
	setValue,
	options = [],
	value,
	onlyNum,
	maxLen,
	essential,
	id,
	placeholder,
	className,
	warning,
	disabled,
}) => {
	const onChange = ({ target: { id, value } }) => {
		const pattern = /\s/g;
		if ((onlyNum && isNaN(+value)) || (maxLen && value.length > maxLen)) return;
		if (id === 'email' && koreanRegex(value)) return;
		if (
			!(
				id === 'content' ||
				id === 'title' ||
				id === 'companyName' ||
				id === 'inquiryType'
			) &&
			pattern.test(value)
		)
			return;

		setValue((item) => ({
			...item,
			[id]: value,
		}));
	};
	const [pwOnHidden, setPwOnHidden] = useState(true);
	const [onFocus, setOnFocus] = useState(false);

	return (
		<div
			className={`flex flex-row items-center justify-between mb-[30px] sm:flex-col sm:items-start sm:mb-[20px] xs:flex-col xs:gap-[10px] xs:items-start xs:mb-[20px] fold:flex-col fold:gap-[10px] fold:items-start fold:mb-[20px] sm: w-full`}>
			<div className={'lg:min-w-[135px] md:min-w-[135px] '}>
				<div
					className={
						'pr-[5px] text-dark1A inline relative text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall font-[600] xs:font-[600] fold:font-[600] leading-[30px] lg:mr-[20px] md:mr-[20px] sm:mb-[20px] xs:mb-[20px] fold:mb-[20px]'
					}>
					{title || ''}
					<div
						className={`${!essential && 'hidden'} ${
							warning
								? 'bg-[#FF3B3B]'
								: (maxLen ? value.length === maxLen : value) || onFocus
								? 'bg-main'
								: 'bg-darkDB'
						} w-[5px] h-[5px] absolute top-0 left-full rounded-full`}
					/>
				</div>
			</div>
			{type === 'text' ? (
				<TextInput
					onChange={onChange}
					value={value}
					id={id}
					placeholder={placeholder}
					setFocus={setOnFocus}
					className={className}
				/>
			) : type === 'email' ? (
				<EmailInput
					onChange={onChange}
					value={value}
					id={id}
					options={options}
					placeholder={placeholder}
					setFocus={setOnFocus}
					className={className}
					warning={warning}
				/>
			) : type === 'textArea' ? (
				<TextAreaInput
					onChange={onChange}
					value={value}
					id={id}
					placeholder={placeholder}
					setFocus={setOnFocus}
					className={className}
				/>
			) : type === 'select' ? (
				<Selected
					onChange={onChange}
					options={options}
					value={value}
					id={id}
					placeholder={placeholder}
					setFocus={setOnFocus}
					className={className}
				/>
			) : type === 'password' ? (
				<PasswordInput
					hidden={pwOnHidden}
					disabled={disabled}
					onClick={() => setPwOnHidden(!pwOnHidden)}
					onChange={onChange}
					value={value}
					id={id}
					placeholder={placeholder}
					setFocus={setOnFocus}
					className={className}
				/>
			) : type === 'number' ? (
				<NumberInput
					hidden={pwOnHidden}
					disabled={disabled}
					onClick={() => setPwOnHidden(!pwOnHidden)}
					onChange={onChange}
					value={value}
					id={id}
					placeholder={placeholder}
					setFocus={setOnFocus}
					className={className}
				/>
			) : null}
		</div>
	);
};

export default TextInputForm;
