import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as MenuArrow } from '../../../assets/icons/menuArrow.svg';
import NotData from '../lists/NotData';

const AccordionList = ({ items }) => {
	const [expanded, setExpanded] = useState(0);

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};
	useEffect(() => {
		setExpanded(0);
	}, [items]);
	return (
		<div className={'w-full text-dark1A bg-white h-auto'}>
			{items?.map((item, index) => (
				<CustomAccordion
					key={index}
					expanded={expanded === item.id}
					onChange={handleChange(item.id)}>
					<CustomAccordionSummary
						aria-controls='panel1d-content'
						id='panel1d-header'
						className={
							'p-0 text-bodyNormal sm:text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall'
						}>
						<div
							className={'flex flex-row items-center justify-between w-full'}>
							<span className={'w-[90%]'}>Q. {item.title} </span>
							<MenuArrow className={'w-[11px] h-[7px]'} />
						</div>
					</CustomAccordionSummary>
					<CustomAccordionDetails
						className={
							'text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall leading-[30px] pt-[10px] pl-[60px] text-dark49'
						}>
						{item?.content}
					</CustomAccordionDetails>
				</CustomAccordion>
			))}
			{(!items || items?.length === 0) && (
				<NotData text={'등록된 질문이 없습니다.'} />
			)}
		</div>
	);
};

export default AccordionList;

const CustomAccordion = styled(Accordion)`
	border: none !important;
	white-space: pre-line;

	&.MuiPaper-root {
		border-radius: 0 !important;
		box-shadow: none;
		padding: 0 40px 20px;

		@media (max-width: 499.9px) {
			padding: 0 20px 10px;
		}
		&.MuiPaper-elevation {
			margin: 0;

			&.Mui-expanded {
				background: #f9f9f9;
				padding: 0 40px 30px;
				border-radius: 10px !important;
				@media (max-width: 499.9px) {
					padding: 0 20px 20px;
				}
			}
		}
	}

	&.MuiAccordionSummary-root {
		padding: 0;
	}

	&.MuiAccordion-root:before {
		display: none;
	}
`;
const CustomAccordionSummary = styled(AccordionSummary)`
	&.MuiButtonBase-root {
		color: #1a1a1a;
		padding: 30px 0 10px;
		height: auto;
		min-height: 0;

		&.Mui-expanded {
		}

		& .MuiAccordionSummary-content {
			margin: 0;
			& > .MuiTypography-root {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			&.Mui-expanded {
				color: #0081ff;

				&.MuiAccordionSummary-contentGutters svg {
					transform: rotate(180deg);
					& path {
						stroke: #0081ff;
					}
				}
			}
		}
		@media (max-width: 499.9px) {
			&.MuiButtonBase-root {
				color: #1a1a1a;
				padding: 20px 0 10px;
				height: auto;
				min-height: 0;
			}
		}
		svg {
			min-width: 5px;
			min-height: 5px;
			transition: transform 0.4s;
		}
	}
`;
const CustomAccordionDetails = styled(AccordionDetails)`
	&.MuiAccordionDetails-root {
		height: auto;
		padding: 0 24px;
		@media (max-width: 499.9px) {
			padding: 0;
		}
		&.Mui-expanded {
			background: #f9f9f9;
		}
	}
`;
