import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as MenuArrow } from '../../../assets/icons/menuArrow.svg';
import { Link } from 'react-router-dom';
import MainColorBtn from '../button/MainColorBtn';

const NavAccordion = ({ items, status, setState }) => {
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  useEffect(() => {
    if (!status) setExpanded('');
  }, [status]);

  return (
    <div className={'w-full min-h-[100vh] xs:min-h-[85vh] fold:min-h-[85vh] text-dark1A bg-white'}>
      {items.map(
        (item) =>
          item.items[0].subTitle && (
            <CustomAccordion key={item.title} expanded={expanded === item.title} onChange={handleChange(item.title)}>
              <CustomAccordionSummary aria-controls="panel1d-content" id="panel1d-header" className={'p-0'}>
                <Typography>
                  {item.title} <MenuArrow />
                </Typography>
              </CustomAccordionSummary>
              {
                <CustomAccordionDetails>
                  {item.items.map((subItem) => (
                    <Typography key={subItem.subTitle}>
                      <Link className={'text-dark49'} to={subItem.link} onClick={() => setState(false)}>
                        {subItem.subTitle}
                      </Link>
                    </Typography>
                  ))}
                </CustomAccordionDetails>
              }
            </CustomAccordion>
          )
      )}
      <div className={'w-full flex items-center justify-center text-bodyXSmall text-white px-[20px]'}>
        <MainColorBtn text={'가맹점 관리'} className={'py-[13px] rounded-[10px] w-full '} />
      </div>
    </div>
  );
};

export default NavAccordion;

const CustomAccordion = styled(Accordion)`
  border: none !important;
  & * {
    font-family: Pretendard !important;
  }
  &.MuiPaper-root {
    border-radius: 0 !important;
    box-shadow: none;
    &.MuiPaper-elevation {
      margin: 0;
      padding: 0;
    }
  }

  &.MuiAccordionSummary-root {
    padding: 0;
  }
  &.MuiAccordion-root:before {
    display: none;
  }
`;
const CustomAccordionSummary = styled(AccordionSummary)`
  &.MuiButtonBase-root {
    background: initial;
    color: #1a1a1a;
    height: 60px;
    @media screen and (max-width: 499.9px) {
      & > div > p {
        font-weight: 500;
      }
    }
    &.Mui-expanded {
      background: #f9f9f9;
      color: #0081ff;
      min-height: 60px;

      & svg {
        path {
          stroke: #0081ff;
        }
      }
    }
    & .MuiAccordionSummary-content {
      & > .MuiTypography-root {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
      }
      &.Mui-expanded {
        &.MuiAccordionSummary-contentGutters svg {
          transform: rotate(180deg);
        }
      }
    }
    svg {
      transition: transform 0.4s;
    }
  }
`;
const CustomAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0 16px;
    & p {
      padding-left: 16px;
      display: flex;
      align-items: center;
      justify-content: start;
      height: 60px;
    }
  }
`;
