import React, { useEffect, useState } from 'react';
import Section from '../../../../layout/web/Section';
import MainColorBtn from '../../../../components/web/button/MainColorBtn';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getQuestionDetail } from '../../../../api/web/customerService/question';
import { getAnswerFormatHypen, getDayFormatHypenV2 } from '../../../../utility/admin/timeFormat';
import styled from '@emotion/styled';

const QuestionDetail = () => {
  const [enabledRefetch, setEnabledRefetch] = useState(true);

  const nav = useNavigate();
  const { state } = useLocation();
  const infoState = state;
  const { data } = useQuery({
    queryKey: ['questionDetail'],
    queryFn: () => state && getQuestionDetail(state?.inquiryId),
    enabled: enabledRefetch,
  });

  useEffect(() => {
    if (!state) {
      nav('/customerService/question');
    } else {
      setEnabledRefetch(false);
      window.history.replaceState({}, document.title);
    }
  }, []);

  return (
    <Page>
      <Section
        maxSize
        className={'h-full pt-[180px] md:pt-[140px] sm:pt-[103px] xs:pt-[103px] fold:pt-[103px] transition-all '}>
        <div
          className={'border-solid border-t-dark1A mb-[100px] md:mb-[80px] sm:mb-[60px] xs:mb-[50px] fold:mb-[50px]'}>
          <div
            className={
              'flex flex-row items-center justify-between py-[15px] border-b-[1px] border-b-darkDB px-[30px] xs:px-[20px] fold:px-[20px] text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall border-t-dark1A border-t-2 border-solid'
            }>
            <div className={'  text-dark1A leading-[30px]'}>{data?.data?.title}</div>
            <div className={' text-dark49 text-bodyXSmall'}>{getDayFormatHypenV2(data?.data?.createdDate)}</div>
          </div>
          <div
            className={
              'px-[30px] xs:py-[20px] fold:py-[20px] xs:px-[20px] fold:px-[20px] py-[25px] whitespace-pre-line text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall xs:leading-[30px] fold:leading-[30px] leading-[30px] text-dark49 mb-[70px]'
            }>
            {data?.data?.content}
          </div>
          {data?.data?.answer && (
            <div
              className={
                'px-[50px] py-[70px] md:px-[40] md:py-[60px] sm:px-[30px] sm:py-[50px] xs:py-[30px] xs:px-[20px] fold:py-[30px] fold:px-[20px] bg-darkF9 rounded-[10px] text-bodyNormal xs:text-bodyXSmall fold:text-bodyXSmall leading-[30px]'
              }>
              <div
                className={
                  'flex flex-row xs:flex-col xs:items-start fold:flex-col fold:items-start items-center justify-start mb-[20px]'
                }>
                <div className={'mr-[10px] text-main   xs:text-[16px] fold:text-[16px] xs:font-[600] fold:font-[600]'}>
                  {data?.data?.answer?.user?.username}
                </div>
                <div className={'text-[rgba(163,163,163)] font-[500] text-[18px] xs:text-[16px] fold:text-[16px]'}>
                  {getAnswerFormatHypen(data?.data?.answer?.user?.createdDate)}
                </div>
              </div>
              <div className={'text-dark49 text-[18px] xs:text-[16px] fold:text-[16px] break-word word-break break-all whitespace-pre-line'}>
                {data?.data?.answer?.content}
              </div>
            </div>
          )}
        </div>
        <div
          className={
            'flex items-center justify-center mb-[120px] md:mb-[90px] sm:mb-[70px] xs:mb-[50px] fold:mb-[50px]'
          }>
          {data?.data?.answer ? (
            <MainColorBtn
              onClick={() => nav(-1)}
              text={'목록으로 돌아가기'}
              className={
                'rounded-[15px] xs:rounded-[10px] fold:rounded-[10px] text-bodySmall leading-[30px] xs:text-bodyXSmall fold:text-bodyXSmall xs:leading-[30px] fold:leading-[30px] px-[51.5px] py-[16px] md:px-[40px] md:py-[14px] sm:px-[35px] sm:py-[14px] xs:px-[30px] xs:py-[10px] fold:px-[30px] fold:py-[10px] transition-all'
              }
            />
          ) : (
            <EditBtnBox className={'flex flex-row gap-[20px]'}>
              <MainColorBtn
                onClick={() => nav(-1)}
                text={'목록'}
                className={
                  'rounded-[15px] xs:rounded-[10px] fold:rounded-[10px] text-bodySmall leading-[30px] xs:text-bodyXSmall fold:text-bodyXSmall xs:leading-[30px] fold:leading-[30px] px-[51.5px] py-[16px] md:px-[40px] md:py-[14px] sm:px-[35px] sm:py-[14px] xs:px-[30px] xs:py-[10px] fold:px-[30px] fold:py-[10px] transition-all list'
                }
              />
              <MainColorBtn
                onClick={() =>
                  nav('/customerService/question/create', { state: { type: 'edit', ...infoState, ...data?.data } })
                }
                text={'수정'}
                className={
                  'rounded-[15px] xs:rounded-[10px] fold:rounded-[10px] text-bodySmall leading-[30px] xs:text-bodyXSmall fold:text-bodyXSmall xs:leading-[30px] fold:leading-[30px] px-[51.5px] py-[16px] md:px-[40px] md:py-[14px] sm:px-[35px] sm:py-[14px] xs:px-[30px] xs:py-[10px] fold:px-[30px] fold:py-[10px] transition-all cancel'
                }
              />
            </EditBtnBox>
          )}
        </div>
      </Section>
    </Page>
  );
};

export default QuestionDetail;

const Page = styled(Section)`
  & * {
    transition: 0.2s;
  }
`;

const EditBtnBox = styled.div`
  & > button {
    &.list {
      background: #c7c9d9;
    }
    &.cancel {
      background: rgb(0, 129, 255);
    }
  }
`;
