import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from '@mui/material';

const MainSectionCard = ({ item, className, index }) => {
	const sm = useMediaQuery('(max-width: 700px)');
	const cardRef = useRef(null);

	const callback = (entries, io) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				setTimeout(
					() => {
						entry.target.classList.add('fade-in-up');
					},
					1000 * (index / 5),
				);

				io.unobserve(entry.target);
			}
		});
	};
	const sections = new IntersectionObserver(callback, {
		threshold: [sm ? 0.1 : 0.6],
	});
	useEffect(() => {
		if (cardRef.current) sections.observe(cardRef.current);
		return () => {
			if (cardRef.current) sections.unobserve(cardRef.current);
		};
	}, []);

	return (
		<Card
			ref={cardRef}
			className={` flex flex-col justify-start text-center rounded-[35px] max-h-[550px] min-h-[380px] max-w-[418px] min-w-[280px] sm:min-h-[370px] xs:min-h-[360px] fold:min-h-[360px] px-[65px] md:px-[20px] sectionCardSm:px-[25px] sm:px-[30px] xs:px-[20px] fold:px-[20px] pt-[140px] md:pt-[120px] sectionCardSm:pt-[90px] sm:pt-[60px] xs:pt-[56px] fold:pt-[56px] border-[1px] border-solid border-[#CFCFCF] hover:bg-main  hover:text-white opacity-0 translate-y-[100px] ${
				className || ''
			}`}>
			<div
				className={
					'flex items-center justify-center mb-[20px] md:mb-[18px] sm:mb-[15px] xs:mb-[15px] fold:mb-[15px]'
				}>
				{item.icon}
			</div>
			<div
				className={
					'text-dark1A mb-[15px] font-[600]  text-bodyLarge md:text-bodyMedium md:font-[600] sm:text-bodySmall sm:font-bold xs:text-bodyNormal xs:font-[600] fold:text-bodyNormal fold:font-[600]'
				}>
				{item.title}
			</div>
			<div
				className={
					'text-dark49 text-bodySmall  break-keep whitespace-pre-wrap leading-[30px] md:leading-[28px] sectionCardSm:text-bodyXSmall sectionCardSm:leading-[26px] xs:text-bodyXSmall fold:text-bodyXSmall '
				}>
				{item.content}
			</div>
		</Card>
	);
};

export default MainSectionCard;

const Card = styled.div`
	width: 33%;
	height: 40vw;
	background-color: #fff;
	box-sizing: border-box;
	transition: all 0.3s;
	& * {
		transition: all 0.3s;
	}

	&:hover {
		transform: translateY(-20px) !important;
		box-shadow: 3px 8px 10px 0px #00000029;

		svg {
			& .fillLine {
				fill: #fff;
			}

			& .strokeLine {
				stroke: #fff;
			}
		}
	}

	@media screen and (max-width: 985px) {
		padding-top: 60px;
	}

	&:hover div {
		color: #fff;
	}
`;
