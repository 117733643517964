import React, { memo, useCallback, useEffect, useState } from 'react';
import Section from '../../../../layout/web/Section';
import ServiceSectionTypography from '../../../../components/web/texts/ServiceSectionTypography';
import NotIceList from '../../../../components/web/lists/NotIceList';
import SearchInput from '../../../../components/inputs/SearchInput';
import { getFixNoticeList, getNoticeList } from '../../../../api/web/customerService/notice';
import { useQuery } from '@tanstack/react-query';
import MainPagination from '../../../../components/web/pagination/MainPagination';
import { useSetRecoilState } from 'recoil';
import { loadingState } from '../../../../recoil/auth';

const ServiceNotice = () => {
  const [searchWord, setSearchWord] = useState('');
  const [pageOffset, setPageOffset] = useState({
    searchWord: '',
    page: 1,
    size: 10,
  });
  const setLoading = useSetRecoilState(loadingState);

  const fixNotices = useQuery({
    queryKey: ['fixNoticeList', pageOffset.searchWord],
    queryFn: () => !pageOffset.searchWord && getFixNoticeList({ searchOption: 1 }),
  });
  const notices = useQuery({
    queryKey: ['noticeList', pageOffset.searchWord],
    queryFn: () => getNoticeList({ ...pageOffset, searchOption: pageOffset.searchWord ? '' : 0 }),
  });
  useEffect(() => {
    notices?.refetch();
  }, [pageOffset.page]);

  useEffect(() => {
    setLoading(notices.isFetching);
  }, [notices.isFetching]);

  const handleSearchOnChange = ({ target: { value } }) => {
    setSearchWord(value);
  };

  const handleInputOnKeyDown = useCallback(
    ({ key }) => {
      if (key === 'Enter') {
        return setPageOffset({
          ...pageOffset,
          page: 1,
          searchWord,
        });
      }
    },
    [searchWord]
  );
  const handleOnChangeCurrentPage = useCallback(
    ({ name, value }) => {
      setPageOffset({
        ...pageOffset,
        [name]: value,
      });
    },
    [pageOffset]
  );

  return (
    <Section>
      <Section
        maxSize
        className={
          'h-full pt-[180px] md:pt-[140px] sm:pt-[103px] xs:pt-[103px] fold:pt-[103px] transition-all min-w-[350px]'
        }>
        <ServiceSectionTypography
          title={'공지사항'}
          className={'text-dark1A text-center mb-[60px] md:mb-[50px] sm:mb-[40px] xs:mb-[30px] fold:mb-[30px]'}
        />
        <SearchInput onChange={handleSearchOnChange} value={searchWord} onKeyDown={handleInputOnKeyDown} />
        <div
          className={
            'border-solid border-t-dark1A border-t-2 border-b-darkDB border-b-[1px] mb-[60px] md:mb-[50px] sm:mb-[40px] xs:mb-[30px] fold:mb-[30px] transition-all'
          }>
          <NotIceList
            fixItems={fixNotices?.data?.data || []}
            items={notices?.data?.data?.content || []}
            isSearch={!!pageOffset.searchWord}
            pageMeta={{
              page: notices?.data?.data?.number + 1 || 0,
              pageSize: notices?.data?.data?.size || 0,
              total: notices?.data?.data?.totalElements || 0,
            }}
          />
        </div>
        {notices?.data?.data?.content && !(notices?.data?.data?.content?.length === 0) && (
          <div className={'mb-[120px] md:mb-[90px] sm:mb-[60px] xs:mb-[50px] fold:mb-[50px] transition-all'}>
            <MainPagination
              total={notices?.data?.data?.totalElements || 1}
              limit={10}
              currentPage={pageOffset.page}
              onChange={handleOnChangeCurrentPage}
            />
          </div>
        )}
      </Section>
    </Section>
  );
};

export default memo(ServiceNotice);
