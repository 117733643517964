import React, { useContext, useEffect, useState } from 'react';
import ServiceSectionTypography from '../../../components/web/texts/ServiceSectionTypography';
import Section from '../../../layout/web/Section';
import TextInputForm from '../../../components/web/inputForm/TextInputForm';
import MainColorBtn from '../../../components/web/button/MainColorBtn';
import CheckBox from '../../../components/inputs/CheckBox';
import { emailRegex } from '../../../utility/web/regex';
import { emailSelectedOptions } from '../../../utility/web/selectedOptions';
import { useSetRecoilState } from 'recoil';
import { loadingState } from '../../../recoil/auth';
import { postApplyService } from '../../../api/web/applyService';
import { useNavigate } from 'react-router-dom';
import { Toast } from '../../../components/toast';
import { ModalContext } from '../../../components/modal/modalContext';

const ServiceApply = () => {
	const [checked, setChecked] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [emailWarning, setEmailWarning] = useState(false);
	const nav = useNavigate();
	const setLoading = useSetRecoilState(loadingState);
	const [applyInfo, setApplyInfo] = useState({
		companyName: '',
		phone: '',
		email: '',
		title: '',
		companyRegNum: '',
		content: '',
		manager: '',
	});
	const { openModal, closeModal } = useContext(ModalContext);
	const { companyName, manager, phone, email, title, companyRegNum, content } =
		applyInfo;
	const handleApplyOnclick = async () => {
		if (!emailRegex(email)) return setEmailWarning(true);

		openModal('서비스를 신청 하시겠습니까?', {
			confirm: async () => {
				setLoading(true);
				closeModal();
				await postApplyService(applyInfo)
					.then(() => {
						setLoading(false);
					})
					.catch((err) => console.log(err));
				openModal(
					'서비스 신청이 정상적으로 접수되었습니다.',
					{
						confirm: async () => {
							closeModal();
							nav('/');
						},
					},
					{ type: 'success' },
				);
			},
		});
	};
	useEffect(() => {
		if (
			companyName &&
			manager &&
			phone &&
			phone.length === 11 &&
			email &&
			title &&
			companyRegNum &&
			content &&
			checked &&
			companyRegNum.length === 10
		) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [applyInfo, checked]);
	return (
		<Section>
			<Section
				maxSize
				className={
					'pt-[180px] md:pt-[140px] xs:pt-[103px] fold:pt-[103px] sm:pt-[103px] transition-all'
				}>
				<ServiceSectionTypography
					title={'서비스 신청'}
					className={
						'text-dark1A text-center mb-[100px] md:mb-[70px] sm:mb-[60px] xs:mb-[50px] fold:mb-[50px]'
					}
				/>
				<div
					className={
						'lg:px-[50px] md:px-[40px] sm:px-[30px] xs:px-[10px] fold:px-[10px] pt-[20px] py-[30px] xs:pb-[10px] fold:pb-[10px] border-t-[2px] border-b-[1px] border-b-darkDB border-dark1A border-solid mb-[20px] lg:mb-[60px] md:mb-[45px] sm:mb-[30px]'
					}>
					<TextInputForm
						type={'text'}
						title={'회사명'}
						id={'companyName'}
						placeholder={'회사명을 입력해주세요.'}
						setValue={setApplyInfo}
						value={companyName}
						essential
					/>
					<TextInputForm
						type={'text'}
						title={'담당자'}
						id={'manager'}
						placeholder={'담당자를 입력해주세요.'}
						setValue={setApplyInfo}
						value={manager}
						essential
					/>
					<TextInputForm
						type={'number'}
						title={'연락처'}
						id={'phone'}
						placeholder={'연락처를 입력해주세요.'}
						setValue={setApplyInfo}
						value={phone}
						essential
						onlyNum
						maxLen={11}
					/>

					<TextInputForm
						type={'email'}
						title={'이메일'}
						id={'email'}
						options={emailSelectedOptions}
						placeholder={'이메일을 입력해주세요.'}
						setValue={setApplyInfo}
						value={email}
						warning={emailWarning}
						essential
					/>
					<TextInputForm
						type={'number'}
						title={'사업자등록번호'}
						id={'companyRegNum'}
						placeholder={'사업자등록번호 입력해주세요.'}
						maxLen={10}
						setValue={setApplyInfo}
						value={companyRegNum}
						essential
						onlyNum
					/>
					<TextInputForm
						type={'text'}
						title={'제목'}
						id={'title'}
						placeholder={'제목을 입력해주세요.'}
						setValue={setApplyInfo}
						value={title}
						essential
					/>
					<TextInputForm
						type={'textArea'}
						title={'내용'}
						id={'content'}
						placeholder={'내용을 입력해주세요.'}
						setValue={setApplyInfo}
						value={content}
						essential
					/>
				</div>
				<div
					className={
						'px-0 my-[20px] xs:mb-0 fold:mb-0 lg:px-[50px] md:px-[40px] sm:px-[30px]'
					}>
					<div
						className={
							'flex flex-row items-center justify-start my-[20px] gap-[10px]'
						}>
						<CheckBox value={checked} setValue={setChecked} />
						<div
							className={
								'text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall font-[600] leading-[30px]'
							}>
							개인정보수집 및 이용 약관 동의
						</div>
					</div>
					<div
						className={
							'bg-darkF9 pl-[36px] py-[20px] xs:px-[20px] fold:px-[20px] overflow-y-scroll overflow-x-hidden text-bodyXSmall xs:text-bodyXXSmall fold:text-bodyXXSmall leading-[24px] xs:leading-[23px] fold:leading-[23px] max-h-[184px] whitespace-pre-wrap border-[1px] border-darkDB border-solid'
						}>
						<div className={'mb-[20px]'}>
							<div className={'text-dark49'}>개인정보의 수집 및 이용안내</div>
							<div>
								<div className={'text-[rgba(155,155,155)]'}>
									바움피앤에스 주식회사(이하 "을")는 고객(이하 “갑”)의
									개인정보를 중요시하며 개인정보 보호법, 정보통신망 이용촉진 및
									정보보호 등에 관한 법률 등 관계법령을 준수하고 있습니다.
								</div>
								<div className={'text-[rgba(155,155,155)]'}>
									“을”은 개인정보취급방침을 통하여 “갑”의 개인정보가 어떠한
									용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한
									조치가 취해지고 있는지 알려드립니다.
								</div>
							</div>
						</div>
						<div className={'mb-[20px]'}>
							<div className={'text-dark49'}>1. 개인정보의 수집·이용목적</div>
							<div>
								<div className={'text-[rgba(155,155,155)]'}>
									“을”은 다음과 같은 목적으로 ”갑”의 개인정보 항목을 수집 및
									이용하고 있습니다.
								</div>
								<div className={'text-[rgba(155,155,155)]'}>- 서비스 신청</div>
							</div>
						</div>
						<div className={'mb-[20px]'}>
							<div className={'text-dark49'}>2. 수집하는 개인정보의 항목</div>
							<div className={'text-[rgba(155,155,155)]'}>
								서비스 신청서 작성을 위해 "을"의 아래와 같은 개인정보를
								수집합니다. <br />- 담당자(성명), 연락처, 이메일
							</div>
						</div>
						<div className={'mb-[20px]'}>
							<div className={'text-dark49'}>
								3. 개인정보의 보유 및 이용기간
							</div>
							<div>
								<div className={'text-[rgba(155,155,155)]'}>
									개인정보는 원칙적으로 개인정보의 수집·이용목적이 달성되면 지체
									없이 파기 합니다.
								</div>
								<div className={'text-[rgba(155,155,155)]'}>
									단, 전자금융거래법, 전자상거래 등에서의 소비자 보호에 관한
									법률 등 관계법령에 의하여 보존할 필요가 있는 경우 당해
									법령에서 정한 기간 동안 개인정보를 보존합니다.
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className={
						'w-full flex items-center justify-center pb-[120px] pt-[80px] xs:py-[50px] fold:py-[50px] '
					}>
					<MainColorBtn
						onClick={handleApplyOnclick}
						disabled={disabled}
						className={
							'rounded-[15px] xs:rounded-[10px] fold:rounded-[10px] text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall leading-[30px] xs:leading-[30px] fold:leading-[30px] px-[100px] py-[16px]  xs:px-[60px] xs:py-[10px] fold:px-[60px] fold:py-[10px] transition-all'
						}
						text={'등록'}
					/>
				</div>
			</Section>
		</Section>
	);
};

export default ServiceApply;
