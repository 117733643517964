import { ContentContainer, PageTitle } from 'styles/sc/admin';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { fileBoardDataGetAPI } from '../../../api/admin/fileBoard';
import Table from '../../../components/admin/Table';
import { referenceRoomColumn } from '../../../utility/admin/tableColumn';
import MainPagination from '../../../components/web/pagination/MainPagination';
import SearchInputForm from '../../../components/admin/SearchInputForm';
import { useNavigate } from 'react-router-dom';
import { referenceSearchItems } from '../../../utility/admin/searchItems';

const ReferenceList = () => {
  const nav = useNavigate();
  const [errorCount, setErrorCount] = useState(0);
  const [searchForm, setSearchForm] = useState({
    startDate: '',
    endDate: '',
    searchOption: '',
    searchWord: '',
  });
  const [pageOffSet, setPageOffSet] = useState({
    page: 1,
    size: 10,
    startDate: '',
    endDate: '',
    searchOption: '',
    searchWord: '',
  });

  // "1: PG서비스, 2: 통신개통, 3: BigOne ERP",
  const { data, refetch, failureCount } = useQuery({
    queryKey: ['fileBoardDataCreateAPI'],
    queryFn: () => fileBoardDataGetAPI(pageOffSet),
    enabled: errorCount === 1,
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    setErrorCount(failureCount);
  }, [failureCount]);

  useEffect(() => {
    refetch();
  }, [pageOffSet]);

  const handleOnChange = ({ value, name }) => {
    if (name === 'page' || name === 'size') return setPageOffSet((prev) => ({ ...prev, [name]: value }));
    else
      return setSearchForm((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
  };
  const handelOnSearch = useCallback(() => {
    setErrorCount(0);
    return setPageOffSet((prev) => ({ ...prev, ...searchForm, page: 1 }));
  }, [searchForm, pageOffSet]);

  return (
    <ContentContainer>
      <PageTitle>자료실 리스트</PageTitle>
      <SearchInputForm
        className="reference_search"
        searchItems={referenceSearchItems}
        onClickCreated={() => nav('/ad/reference_reg')}
        onClickSearch={() => handelOnSearch()}
        state={{ ...searchForm, size: pageOffSet.size }}
        onChange={handleOnChange}
      />
      <Table
        items={data?.data?.content}
        rows={referenceRoomColumn}
        pageMeta={{
          page: data?.data?.number + 1 || 0,
          pageSize: data?.data?.size || 0,
          total: data?.data?.totalElements || 0,
        }}
        onClick={({ id }) => nav(`/ad/reference_edit/${id}`)}
      />

      {data?.data?.totalElements > 0 && (
        <MainPagination
          total={data?.data?.totalElements || 1}
          limit={pageOffSet.size}
          currentPage={pageOffSet.page}
          onChange={handleOnChange}
        />
      )}
    </ContentContainer>
  );
};
export default ReferenceList;
