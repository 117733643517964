import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as MenuArrow } from '../../assets/icons/menuArrow.svg';

const EmailInput = ({
	value,
	options,
	id,
	onChange,
	placeholder,
	setFocus,
	className,
	warning,
}) => {
	const [isActive, setIsActive] = useState(false);
	const [selectedValue, setSelectedValue] = useState('');
	const emailInputRef = useRef(null);
	const handleSelectOnChange = useCallback(
		(email) => {
			setSelectedValue(email);
			setIsActive(false);
			const indexOf = value.indexOf('@');
			if (!email && indexOf === -1) return;
			if (!email && indexOf)
				return onChange({
					target: {
						id: id,
						value: `${value.slice(0, indexOf)}`,
					},
				});

			if (email && indexOf === -1)
				return onChange({
					target: {
						id: id,
						value: `${value}@${email}`,
					},
				});

			if (email && indexOf)
				return onChange({
					target: {
						id: id,
						value: `${value.slice(0, indexOf)}@${email}`,
					},
				});
		},
		[value],
	);

	useEffect(() => {
		if (warning) emailInputRef.current.focus();
	}, [warning]);
	return (
		<div
			className={`flex-1 w-full flex fold:flex-col max-w-[950px] transition-all gap-[14px] ${
				warning && 'fold:gap-[40px]'
			} h-[50px] sm:h-[48px] xs:h-[44px] fold:h-[44px] ${
				warning && 'mb-[20px]'
			}`}>
			<div className={'w-full max-w-[463px] flex flex-col relative'}>
				<input
					type='text'
					ref={emailInputRef}
					className={`w-full h-[50px] sm:h-[48px] xs:h-[44px] fold:h-[44px] border-[1px] ${
						warning ? 'border-[#FF3B3B]' : 'border-darkDB focus:border-main'
					} rounded-[10px] outline-none py-[13px] px-[20px] text-dark49 text-bodyXSmall leading-[24px] placeholder:text-darkDB ${
						className || ''
					}`}
					autoComplete='off'
					id={id}
					value={value || ''}
					onChange={onChange}
					placeholder={placeholder}
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)}
				/>
				{warning && (
					<span
						className={
							'absolute top-full translate-y-1/4 text-[#FF3B3B] text-bodyXSmall'
						}>
						이메일 형식이 올바르지 않습니다.
					</span>
				)}
			</div>
			<DropDownMenu
				tabIndex='0'
				onBlur={() => setIsActive(false)}
				className={`text-dark1A text-bodyXSmall min-w-[143px] lg:w-[240px] md:w-[240px] sm:w-[143px]  fold:min-w-full`}>
				<div
					onClick={() => setIsActive(!isActive)}
					className={`w-full h-[50px] sm:h-[48px] xs:h-[44px] fold:h-[44px] flex items-center justify-between pl-[15px] pr-[25px] border-darkDB border-[1px] border-solid rounded-[10px] py-[15px] pt-[16px] ${
						isActive
							? 'border-b-[#fff] rounded-bl-none rounded-br-none border-b-none border-main '
							: ''
					} `}>
					{selectedValue || '직접입력'}
					<MenuArrow
						style={{
							transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
						}}
					/>
				</div>
				<DropDown
					className={`absolute left-0 right-0 px-[5px] w-full min-w-[143px] z-[2] ${
						isActive
							? 'lg:h-[200px] md:h-[200px] sm:h-[200px] xs:h-[184px] fold:h-[184px] pb-[5px]'
							: 'h-0 border-none'
					} overflow-hidden border-[1px] border-solid border-main `}>
					{options.map((option) => {
						return (
							option.value !== selectedValue && (
								<li
									key={option.value}
									className={`${
										option.value &&
										value?.includes(option.value) &&
										'bg-main text-white'
									}`}
									onClick={() => handleSelectOnChange(option.value)}>
									{option.label}
								</li>
							)
						);
					})}
				</DropDown>
			</DropDownMenu>
		</div>
	);
};

export default EmailInput;

const DropDownMenu = styled.div`
	flex: 1;
	position: relative;
	max-width: 240px;
	max-height: 50px;
	cursor: pointer;
	& * {
		transition: all 0.2s;
	}
`;

const DropDown = styled.ul`
	transform: translateY(-1px);
	flex-direction: column;
	background: rgba(255, 255, 255, 1);
	border-top: none;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;

	& li {
		min-height: 25%;
		padding-left: 10px;
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: center;

		&:hover {
			background-color: rgba(30, 144, 255, 1);
			color: rgba(255, 255, 255, 1);
		}
	}
`;
