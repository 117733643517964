import React, { memo } from 'react';
import { SearchBox, SearchItem, SearchKey, SearchValue } from '../../styles/sc/admin';
import { CustomDatePicker as DatePicker } from './DatePicker';
import TextField from './TextField';
import Button from './Button';
import styled from 'styled-components';
import Radios from './Radios';
import Select from './Select';

const pageSize = [
  { name: '10개씩 보기', value: '10' },
  { name: '50개씩 보기', value: '50' },
  { name: '100개씩 보기', value: '100' },
];

const SearchInputForm = ({
  className = '',
  state,
  searchItems,
  onClickSearch,
  onClickCreated,
  onChange,
  createdBtnHidden,
  createdBtnText,
}) => {
  const handleOnChanges = ({ name, value }) => {
    onChange({ name: name, value: `${value === 0 ? '' : value}` });
  };
  const datePicker = (item) => (
    <SearchItem key={item.name}>
      <SearchKey>{item.label}</SearchKey>
      <SearchValue>
        <DatePicker
          startDate={state.startDate}
          setStartDate={(value) => onChange({ value: value, name: 'startDate' })}
          endDate={state.endDate}
          setEndDate={(value) => onChange({ value: value, name: 'endDate' })}
        />
        <Button
          type={'outlined'}
          height={'42px'}
          width={'88px'}
          color={'admin_navy'}
          style={{ marginLeft: '12px', boxShadow: 'none', color: 'white' }}
          onClick={() => {
            onChange({ value: '', name: 'startDate' });
            onChange({ value: '', name: 'endDate' });
          }}>
          전체
        </Button>
      </SearchValue>
    </SearchItem>
  );
  const searchWordInput = (item) => (
    <SearchItem key={item.name}>
      <SearchKey>{item.label}</SearchKey>
      <SearchValue>
        <TextField
          height="42px"
          width="385px"
          placeholder={item.placeholder || '검색어를 입력해주세요.'}
          name="searchWord"
          value={state?.searchWord}
          onChange={({ target: { value } }) => handleOnChanges({ name: item.name, value: value })}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onClickSearch();
            }
          }}></TextField>
      </SearchValue>
    </SearchItem>
  );
  const radioInput = (item) => (
    <SearchItem key={item.name}>
      <SearchKey>{item.label}</SearchKey>
      <Radios
        items={item?.options || []}
        value={state[item?.name] || ''}
        onChange={({ target: { value } }) => handleOnChanges({ name: item.name, value: value })}
      />
    </SearchItem>
  );
  const selectedInput = (item) => (
    <SearchItem key={item.name}>
      <SearchKey>{item.label}</SearchKey>
      <SearchValue>
        {item?.options?.length > 1 ? (
          <Select
            width={'385px'}
            options={item?.options || []}
            value={`${state[item?.name]}` || ''}
            onChange={({ target: { value } }) => handleOnChanges({ name: item.name, value: value })}
          />
        ) : (
          <div className={'flex items-center justify-center text-[14px] font-[400] text-dark3A '}>
            <span
              className={
                'px-[16px] h-[40px] py-[8px] rounded-[6px] border-solid border border-[#ebebf0] flex items-center justify-center'
              }>
              {item?.options[0]?.name}
            </span>
          </div>
        )}
      </SearchValue>
    </SearchItem>
  );

  return (
    <div>
      <SearchBox className={className}>
        <div className={'grid grid-cols-2'}>
          {searchItems?.map((item) => {
            switch (item.type) {
              case 'date':
                return datePicker(item);
              case 'select':
                return selectedInput(item);
              case 'text':
                return searchWordInput(item);
              case 'radio':
                return radioInput(item);
              default:
                return null;
            }
          })}
        </div>
      </SearchBox>
      <Button
        width={'80px'}
        height={'46px'}
        color={'admin_main'}
        style={{
          color: 'white',
          margin: '0 0 24px auto',
        }}
        onClick={() => onClickSearch()}>
        <span>검색</span>
      </Button>

      <div className={`mb-[8px] flex items-center ${createdBtnHidden ? 'justify-end' : 'justify-between'}`}>
        {!createdBtnHidden && (
          <BtnWrapper>
            <Button
              width={'80px'}
              height={'42px'}
              color="#e53535"
              style={{
                color: 'white',
                marginRight: '8px',
              }}
              onClick={() => onClickCreated()}>
              <span>{createdBtnText ? createdBtnText : '등록'}</span>
            </Button>
          </BtnWrapper>
        )}
        <Select
          width={'110px'}
          options={pageSize}
          value={`${state?.size}` || ''}
          onChange={({ target: { value } }) => handleOnChanges({ name: 'size', value: +value })}
        />
      </div>
    </div>
  );
};

export default memo(SearchInputForm);

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
