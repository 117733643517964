import http from '../../http';

export const getNoticeList = (params) => {
  return http.get('/notice', { params: params });
};

export const getFixNoticeList = () => {
  return http.get('/notice-fix');
};

export const getNoticeDetail = (params) => {
  return http.get(`/notice/${params}`);
};
