import React, { useEffect, useRef } from 'react';
import Section from '../../../layout/web/Section';
import ServiceSectionTypography from '../texts/ServiceSectionTypography';
import ServiceCard from '../cards/ServiceCard';
import styled from 'styled-components';

const ServiceCardList = ({ items, title }) => {
  const serviceCardRef = useRef(null);

  const callback = (entries, io) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('active');
        io.unobserve(entry.target);
      }
    });
  };
  const sections = new IntersectionObserver(callback, {
    threshold: [0.4],
  });
  useEffect(() => {
    if (serviceCardRef.current) sections.observe(serviceCardRef.current);
    return () => {
      if (serviceCardRef.current) sections.unobserve(serviceCardRef.current);
    };
  }, []);
  return (
    <FadeInYSection
      maxSize
      className={
        'py-[100px] md:py-[90px]  sm:py-[70px] xs:py-[50px] fold:py-[50px] transition-all duration-400 ease-in-out w-full'
      }>
      <div className={'flex flex-col items-center justify-center'} ref={serviceCardRef}>
        <ServiceSectionTypography
          title={title || ''}
          className={'text-dark1A mb-[80px] md:mb-[70px] sm:mb-[60px] xs:mb-[40px] fold:mb-[40px] fade-in-up'}
        />
        <div
          className={`w-full grid ${
            items.length < 4
              ? 'grid-cols-3 fold:grid-cols-1 gap-[25px] md:gap-[20px] sm:gap-[15px] xs:gap-[8px] fold:gap-[8px]'
              : 'grid-cols-2 gap-[20px] lg:gap-[40px] md:gap-[30px]'
          }`}>
          {items.map((item, index) => (
            <ServiceCard item={item} key={index} col={items.length < 4} index={index} />
          ))}
        </div>
      </div>
    </FadeInYSection>
  );
};

export default ServiceCardList;

const FadeInYSection = styled(Section)`
  .fade-in-up {
    opacity: 0;
    transform: translateY(100px);
    transition:
      opacity 1s,
      transform 0.7s;
  }
  & .active .fade-in-up {
    opacity: 1;
    transform: translateY(0);
  }
`;
