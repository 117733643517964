// 고객센터/자주묻는 질문/결제, 정산문의 리스트
export const FAQTabItems = [
  {
    title: '결제관리/정산관리',
    link: '/customerService/frequentlyAskedQuestion/paymentManagement',
  },
  {
    title: '결제문의/정산문의',
    link: '/customerService/frequentlyAskedQuestion/paymentInquiry',
  },
  {
    title: '이용문의',
    link: '/customerService/frequentlyAskedQuestion/inquiryAboutUse',
  },
];
// 고객센터/자주묻는 질문/결제, 정산문의 리스트
export const mFAQTabItems = [
  {
    title: '결제/정산관리',
    link: '/customerService/frequentlyAskedQuestion/paymentManagement',
  },
  {
    title: '결제/정산문의',
    link: '/customerService/frequentlyAskedQuestion/paymentInquiry',
  },
  {
    title: '이용문의',
    link: '/customerService/frequentlyAskedQuestion/inquiryAboutUse',
  },
];
