import { useNavigate, useLocation } from 'react-router-dom';
import { converterUserRoleName, getMenuTitle } from 'const/admin';

import { ReactComponent as Logout } from 'assets/icons/admin/logout.svg';
import { useCallback, useMemo } from 'react';
import Button from 'components/admin/Button';
import { styled } from 'styled-components';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { userState, useNavMenuState } from 'recoil/auth';
import defaultAvartar from 'assets/icons/admin/default_avartar.png';
import { BodySmall, Heading5 } from 'styles/sc/admin';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const userInfo = useRecoilValue(userState);
  const resetUser = useResetRecoilState(userState);
  const resetMenu = useResetRecoilState(useNavMenuState);

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    resetUser();
    resetMenu();
    navigate('/ad/login');
  };

  const displayUserRoleName = useMemo(() => {
    if (!userInfo) return '';
    return converterUserRoleName(userInfo.userRole);
  }, [userInfo]);

  const headerTitle = useMemo(() => {
    const nowPath = location.pathname.split('/')[2];
    return getMenuTitle(nowPath);
  }, [location.pathname]);
  return (
    <MainHeader>
      <h5>{headerTitle}</h5>
      <HeaderBtnWrapper>
        <UserInfoTag
          onClick={() => {
            navigate('/ad/auth');
          }}>
          <img src={defaultAvartar} alt="유저 이미지"></img>
          <div>
            <span>{displayUserRoleName}</span>
            <span>
              {userInfo?.name || '-'}
              {userInfo?.name && <span>님</span>}
            </span>
          </div>
        </UserInfoTag>
        <Button
          width={'120px'}
          height={'42px'}
          onClick={() => {
            logout();
          }}>
          <Logout></Logout>
          <span>로그아웃</span>
        </Button>
      </HeaderBtnWrapper>
    </MainHeader>
  );
};

export default Header;

const MainHeader = styled.div`
  height: 65px;
  border-bottom: 1px solid #ebebf0;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > h5 {
    ${Heading5};
    color: var(--color-dark-1);
  }
`;

const HeaderBtnWrapper = styled.div`
  display: flex;
  font-size: 14px;
`;

const UserInfoTag = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
  &:hover {
    opacity: 0.8;
  }
  > img {
    width: 42px;
    height: 42px;
  }
  > div {
    margin-left: 4px;
    > span:first-child {
      ${BodySmall};
      color: var(--color-dark-2);
      margin-right: 4px;
    }
    > span:last-child {
      ${BodySmall};
      color: var(--color-dark-1);
      > span {
        margin-left: 4px;
        color: var(--color-dark-2);
      }
    }
  }
`;
