import { useContext } from 'react';
import { ModalContext } from './modalContext';
import { styled } from 'styled-components';

const Modal = () => {
  const { modalContent, openModal, modal, confirm } = useContext(ModalContext);
  if (modal) {
    return (
      <ModalWrapper>
        <ModalBox>
          <div className="title">{modalContent}</div>
          <div className={'btnBox'}>
            <button className="cancel" onClick={() => openModal()}>
              취소
            </button>
            <button className="success" onClick={() => confirm()}>
              확인
            </button>
          </div>
        </ModalBox>
      </ModalWrapper>
    );
  } else return null;
};

export default Modal;

const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 21;
  background-color: rgba(58, 58, 60, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
`;

const ModalBox = styled.div`
  position: relative;
  background-color: #fff;
  padding: 40px 35px 40px 40px;
  min-width: 250px;
  box-shadow:
    0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 20px;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
  display: flex;
  flex-direction: row;
  gap: 6px;
  transition: all 0.2s;
  margin: 0 40px;
  @media all and (max-width: 600px) {
    gap: 0;
    padding: 30px 91px;
    flex-direction: column;
  }
  @media all and (max-width: 365px) {
    gap: 0;
    padding: 30px 50px;
    flex-direction: column;
  }
  & * {
    transition: all 0.2s;
  }
  & .title {
    padding-bottom: 96px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: var(--color-dark-1);
    text-wrap: wrap;
    word-break: keep-all;
    @media all and (max-width: 600px) {
      font-size: 18px;
      padding-bottom: 20px;
    }
  }
  & .btnBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: end;
    & button {
      padding: 10px 40px;
      height: 44px;
      border-radius: 10px;
      cursor: pointer;
      &.cancel {
        margin-right: 10px;
        color: #fff;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        background: rgba(73, 73, 73, 1);
      }
      &.success {
        background: var(--color-admin-main);
        color: #fff;
      }
      @media all and (max-width: 600px) {
        padding: 10px 30px;
      }
    }
  }
  @media all and (max-width: 510px) {
  }
`;
