import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as MenuArrow } from '../../assets/icons/menuArrow.svg';

const Selected = ({ value, options, id, onChange, placeholder }) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value || '');
  const activeOptionsCount = options?.filter((option) => option.label !== selectedValue).length;

  const handleSelectOnChange = useCallback(
    (option) => {
      setSelectedValue(option.label);
      setIsActive(false);
      onChange({ target: { id, value: option.value } });
    },
    [value]
  );
  useEffect(() => {
    if (value) {
      const selectedOption = options?.find((option) => option.value === value);
      if (selectedOption) setSelectedValue(selectedOption.label);
    }
  }, [value]);

  return (
    <div
      className={
        'flex-1 w-full flex fold:flex-col max-w-[950px] transition-all gap-[14px] h-[50px] sm:h-[48px] xs:h-[44px] fold:h-[44px]'
      }>
      <DropDownMenu
        className={`text-dark1A text-bodyXSmall min-w-[143px]  fold:min-w-full max-w-[463px]`}
        tabIndex={0}
        onBlur={() => setIsActive(false)}>
        <div
          onClick={() => setIsActive(!isActive)}
          onBlur={() => setIsActive(false)}
          className={`w-full h-[50px] sm:h-[48px] xs:h-[44px] fold:h-[44px] flex items-center justify-between pl-[20px] pr-[25px] border-darkDB border-[1px] border-solid rounded-[10px] ${
            isActive ? 'border-b-[#fff] rounded-bl-[0] rounded-br-[0] border-main ' : ''
          }`}>
          {selectedValue || placeholder}
          <MenuArrow
            style={{
              transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        </div>
        <DropDown
          className={`absolute left-0 right-0 w-full  z-[2] ${
            isActive ? `h-[155px] transition-all` : 'h-0 border-none'
          } overflow-hidden border-[1px] border-solid border-main `}>
          {options?.map((option) => {
            return (
              <li className={`h-[50px] `} key={option.value} onClick={() => handleSelectOnChange(option)}>
                {option.label}
              </li>
            );
          })}
        </DropDown>
      </DropDownMenu>
    </div>
  );
};

export default Selected;

const DropDownMenu = styled.div`
  flex: 1;
  position: relative;
  max-height: 50px;
  cursor: pointer;
  & * {
    transition: all 0.2s;
  }
`;

const DropDown = styled.ul`
  padding: 0 5px;
  flex-direction: column;
  background: rgba(255, 255, 255, 1);
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transform: translateY(-1px);
  & li {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    &:hover {
      background-color: rgba(30, 144, 255, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
`;
