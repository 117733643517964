import React from 'react';

const Section = ({ children, className, maxSize }) => {
  return (
    <div className={`w-full h-auto mx-auto ${className || ''} ${maxSize && 'max-w-[1420px] px-[20px]  min-w-[280px]'}`}>
      {children}
    </div>
  );
};

export default Section;
