import React, { useCallback, useContext, useState } from 'react';
import {
  ContentContainer,
  FormBox,
  FormBoxItem,
  FormBoxItemTitle,
  FormBoxItemValue,
  PageTitle,
} from '../../../styles/sc/admin';
import { useLocation, useNavigate } from 'react-router-dom';
import TextField from '../../../components/admin/TextField';
import { getDayFormatHypenV2 } from '../../../utility/admin/timeFormat';
import { BUButton } from '../../../components/admin/BUButton';
import { ModalContext } from '../../../components/modal/modalContext';
import { upDateUserDetailPw } from '../../../api/admin/user';
import { Toast } from '../../../components/toast';

const EditPw = () => {
  const nav = useNavigate();
  const { openModal, closeModal } = useContext(ModalContext);
  const [newPassWord, setNewPassWord] = useState({
    newPassWord: '',
    newPassWordCheck: '',
  });
  const { state } = useLocation();
  const handleOnChangePassWord = ({ target: { name, value } }) => {
    setNewPassWord((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleOnClickSaveNewPassWord = useCallback(() => {
    if (newPassWord.newPassWord !== newPassWord.newPassWordCheck) return Toast.info('비밀번호가 일치하지 않습니다.');
    openModal('비밀번호를 변경하시겠습니까?', {
      confirm: async () => {
        upDateUserDetailPw(state.id, {
          password: newPassWord.newPassWord,
        }).then(({ status }) => {
          if (status === 200) {
            Toast.success('비밀번호가 변경되었습니다.');
            nav(`/ad/user_edit/${state.id}`);
          } else {
            Toast.error('작업 처리 도중 오류가 발생하였습니다.');
          }
          closeModal();
        });
      },
    });
  }, [newPassWord]);
  return (
    <ContentContainer>
      <PageTitle>관리자 정보변경</PageTitle>
      <FormBox>
        <FormBoxItem>
          <FormBoxItemTitle>새 비밀번호</FormBoxItemTitle>
          <FormBoxItemValue>
            <TextField
              type="password"
              placeholder="새 비밀번호"
              height="40px"
              width="250px"
              name="newPassWord"
              value={newPassWord.newPassWord}
              onChange={handleOnChangePassWord}
            />
          </FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>새 비밀번호 확인</FormBoxItemTitle>
          <FormBoxItemValue>
            <TextField
              type="password"
              placeholder="새 비밀번호 확인"
              height="40px"
              width="250px"
              name="newPassWordCheck"
              value={newPassWord.newPassWordCheck}
              onChange={handleOnChangePassWord}
            />
          </FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>변경 이력</FormBoxItemTitle>
          <FormBoxItemValue>
            <TextField
              placeholder="변경 이력"
              height="40px"
              width="250px"
              name="name"
              value={getDayFormatHypenV2(state?.updatedDate) || ''}
              disabled
            />
          </FormBoxItemValue>
        </FormBoxItem>
      </FormBox>
      <div className={'flex items-center justify-center'}>
        <BUButton onClick={() => nav(`/ad/user_edit/${state.id}`)} color={'gray'}>
          뒤로가기
        </BUButton>
        <BUButton onClick={handleOnClickSaveNewPassWord}>저장</BUButton>
      </div>
    </ContentContainer>
  );
};

export default EditPw;
