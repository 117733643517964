import Button from 'components/admin/Button';
import Checkbox from 'components/admin/Checkbox';
import TextField from 'components/admin/TextField';
import { Toast } from 'components/toast';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import logo from 'assets/logos/mainLogoDark.svg';
import { loginAction } from 'api/admin/auth';
import { useNavMenuState, userState } from 'recoil/auth';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { getNavMenu } from 'const/admin';

const Login = () => {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    id: '',
    password: '',
  });
  const [cookies, setCookie, removeCookie] = useCookies(['saveUserId']);
  const [saveId, setSaveId] = useState('');

  const [user, setUser] = useRecoilState(userState);
  const setMenu = useSetRecoilState(useNavMenuState);

  const onChangeData = (e, setData) => {
    const { name, value } = e.currentTarget;
    setData((preVal) => ({ ...preVal, [name]: value }));
  };

  // 아이디 저장
  const handleOnChange = (checked) => {
    setSaveId(checked);
    if (checked) {
      if (loginData.id?.length > 0) {
        setCookie('saveUserId', loginData.id);
      }
    } else {
      removeCookie('saveUserId');
    }
  };

  const login = async () => {
    const { id, password } = loginData;
    if (id.length === 0 || !id) {
      Toast.info('아이디를 입력해주세요');
      return document.querySelector('input[name=id]').focus();
    }
    if (password.length === 0 || !password) {
      Toast.info('비밀번호를 입력해주세요');
      return document.querySelector('input[name=password]').focus();
    }

    const { data, status } = await loginAction({ id, password });
    if (status !== 200) {
      return Toast.error('아이디 혹은 비밀번호를 확인해주세요');
    }
    const { tokenResponse, userResponse } = data;

    localStorage.setItem('accessToken', tokenResponse.accessToken);
    localStorage.setItem('refreshToken', tokenResponse.refreshToken);
    setUser(userResponse);

    const menus = getNavMenu(userResponse.userRole);
    setMenu(menus);

    navigate('/ad/home');
  };

  /*페이지가 최초 렌더링 될 경우*/
  useEffect(() => {
    /*저장된 쿠키값이 있으면, CheckBox TRUE 및 UserID에 값 셋팅*/
    if (cookies.saveUserId !== undefined) {
      setLoginData((preVal) => ({ ...preVal, id: cookies.saveUserId }));
      setSaveId(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <LoginWrapper>
      <LoginBox>
        <LoginForm
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              login();
            }
          }}>
          <Logo>
            <img src={logo}></img>
          </Logo>
          <TextField
            placeholder="아이디"
            height="46px"
            width="100%"
            name="id"
            value={loginData.id}
            onChange={(e) => {
              onChangeData(e, setLoginData);
            }}></TextField>
          <TextField
            placeholder="비밀번호"
            height="46px"
            width="100%"
            name="password"
            type="password"
            value={loginData.password}
            onChange={(e) => {
              onChangeData(e, setLoginData);
            }}></TextField>
          <Checkbox
            checked={saveId}
            onChange={(value) => {
              handleOnChange(value);
            }}>
            아이디 저장
          </Checkbox>
          <Button
            style={{
              color: 'white',
              marginTop: '24px',
              fontSize: '16px',
              fontWeight: 700,
            }}
            width={'100%'}
            height={'50px'}
            color={'#134575'}
            onClick={() => {
              login();
            }}>
            <span>로그인</span>
          </Button>
        </LoginForm>
      </LoginBox>
    </LoginWrapper>
  );
};

export default Login;

const LoginWrapper = styled.div`
  user-select: none;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LoginBox = styled.div`
  width: 400px;
  padding: 40px 24px;
`;
const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 44px;
  > img {
    width: 124px;
  }
`;

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  > h2 {
    margin-bottom: 12px;
    font-size: 36px;
    line-height: 1.3;
    font-weight: 700;
  }
  > input {
    margin-bottom: 20px;
    font-size: 16px;
  }
`;
