import {
  fileBoardDataCreateAPI,
  fileBoardDataDeleteAPI,
  fileBoardDataGetDetailAPI,
  fileBoardDataUpdateAPI,
} from 'api/admin/fileBoard';
import Button from 'components/admin/Button';
import Select from 'components/admin/Select';
import TextField from 'components/admin/TextField';
import { Uploader } from 'components/admin/Uploader';
import { ModalContext } from 'components/modal/modalContext';
import { Toast } from 'components/toast';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  BottomBtnWrapper,
  ContentContainer,
  FormBox,
  FormBoxItem,
  FormBoxItemTitle,
  FormBoxItemValue,
  PageTitle,
  UploadBtn,
} from 'styles/sc/admin';
import { getDayFormatHypenV2 } from '../../../utility/admin/timeFormat';
import { BUButton } from 'components/admin/BUButton';

// const INIT_TYPE_OPTIONS = [
//   { name: '선택', value: '' },
//   { name: 'PG 서비스', value: '1' },
//   { name: '통신개통', value: '2' },
//   { name: 'BizOne ERP', value: '3' },
// ];

const ReferenceForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [dataForm, setDataForm] = useState({
    title: '',
    type: 1,
    file: null,
    createdDate: '',
    updatedDate: '',
  });
  const [fileName, setFileName] = useState('');
  const { openModal, closeModal } = useContext(ModalContext);
  const { title, type, file } = dataForm;

  const getData = useCallback(async () => {
    await fileBoardDataGetDetailAPI(id)
      .then(({ data }) => {
        setDataForm(data);
        setFileName(data?.file?.originalFilename);
      })
      .catch();
  }, [dataForm]);

  const onChangeData = (e, setData) => {
    const { name, value } = e.currentTarget;
    setData((preVal) => ({ ...preVal, [name]: value }));
  };

  // 파일 선택시 이벤트
  const onChangeFile = async (e) => {
    let file = e.target.files[0];
    if (!file) return;

    // 유효성검사
    // 1. 확장자 검사
    // if (
    //   file.type !== 'image/png' &&
    //   file.type !== 'image/jpeg' &&
    //   file.type !== 'image/bmp '
    // ) {
    //   e.target.value = '';
    //   return Toast.info(
    //     '다음과 같은 확장자만 업로드 할 수 있습니다.(.png, .jpeg, .jpg, .bmp)'
    //   );
    // }
    // 2. 6MB 이하 업로드 가능
    if (file.size > 6291456) {
      e.target.value = '';
      return Toast.info('6MB 이하인 파일만 업로드 가능합니다.');
    }

    setDataForm((preVal) => ({
      ...preVal,
      file,
    }));
    setFileName(file.name);

    e.target.value = '';
  };

  // 유효성 검사
  const validationCheck = useCallback(() => {
    if (!title || title.length === 0) {
      Toast.info('자료명은 필수 입력값입니다.');
      return document.querySelector('input[name=title]').focus();
    }
    // if (!type) {
    //   return Toast.info('자료타입을 선택해주세요.');
    // }
    if (!file || file.length === 0) {
      return Toast.info('파일을 선택해주세요.');
    }
    handleOnSave();
  }, [title, type, file]);

  // 저장
  const handleOnSave = useCallback(async () => {
    openModal(`${id ? '수정' : '등록'}하시겠습니까?`, {
      confirm: async () => {
        const formData = new FormData();
        if (id) formData.append('id', dataForm.id);
        formData.append('title', title);
        formData.append('type', type);
        if (id) {
          if (!file?.id) {
            formData.append('responseFile', file);
          }
        } else {
          formData.append('file', file);
        }
        const { status } = await (id ? fileBoardDataUpdateAPI : fileBoardDataCreateAPI)(formData);
        if (status !== 200) {
          Toast.error('작업 처리 도중 오류가 발생하였습니다.');
        } else {
          Toast.success(`${id ? '수정' : '등록'}하였습니다.`);
          navigate('/ad/reference');
        }
        closeModal();
      },
    });
  }, [dataForm]);

  const handleOnDeleteData = useCallback(async () => {
    openModal('삭제하시겠습니까?', {
      confirm: async () => {
        fileBoardDataDeleteAPI(id).then(({ status }) => {
          if (status === 200) {
            Toast.success('삭제 되었습니다.');
            closeModal();
            return navigate('/ad/reference');
          }
          return Toast.error('작업 처리 도중 오류가 발생하였습니다.');
        });
      },
    });
  }, [id]);

  useEffect(() => {
    if (id) getData();
  }, []);
  return (
    <ContentContainer>
      <PageTitle>{id ? '자료수정' : '자료등록'}</PageTitle>
      <FormBox>
        {id && (
          <>
            <FormBoxItem>
              <FormBoxItemTitle>등록 일시</FormBoxItemTitle>
              <FormBoxItemValue>
                <TextField
                  disabled
                  placeholder="등록일시"
                  height="42px"
                  width="250px"
                  name="title"
                  value={getDayFormatHypenV2(dataForm.createdDate)}
                />
              </FormBoxItemValue>
            </FormBoxItem>
            <FormBoxItem>
              <FormBoxItemTitle>수정 일시</FormBoxItemTitle>
              <FormBoxItemValue>
                <TextField
                  disabled
                  placeholder="수정 일시"
                  height="42px"
                  width="250px"
                  name="title"
                  value={getDayFormatHypenV2(dataForm.updatedDate)}
                />
              </FormBoxItemValue>
            </FormBoxItem>
            <FormBoxItem>
              <FormBoxItemTitle>등록자</FormBoxItemTitle>
              <FormBoxItemValue>
                <TextField
                  disabled
                  placeholder="등록자"
                  height="42px"
                  width="250px"
                  name="title"
                  value={`${dataForm?.user?.username}(${dataForm?.user?.name})`}
                />
              </FormBoxItemValue>
            </FormBoxItem>
          </>
        )}
        <FormBoxItem>
          <FormBoxItemTitle>자료명</FormBoxItemTitle>
          <FormBoxItemValue>
            <TextField
              placeholder="자료명"
              height="42px"
              width="250px"
              name="title"
              value={dataForm.title}
              onChange={(e) => {
                onChangeData(e, setDataForm);
              }}></TextField>
          </FormBoxItemValue>
        </FormBoxItem>
        {/* <FormBoxItem>
          <FormBoxItemTitle>자료타입</FormBoxItemTitle>
          <FormBoxItemValue>
            <Select
              width="250px"
              value={dataForm.type}
              onChange={({ target: { value } }) => {
                setDataForm((dateForm) => ({
                  ...dateForm,
                  type: value,
                }));
              }}
              options={INIT_TYPE_OPTIONS}
            />
          </FormBoxItemValue>
        </FormBoxItem> */}
        <FormBoxItem>
          <FormBoxItemTitle>파일</FormBoxItemTitle>
          <FormBoxItemValue style={{ alignItems: 'center' }}>
            <Uploader
              id="file"
              onChange={(e) => {
                onChangeFile(e);
              }}>
              <UploadBtn>파일첨부</UploadBtn>
            </Uploader>
            {dataForm.file && <div style={{ marginLeft: '12px' }}>{fileName}</div>}
          </FormBoxItemValue>
        </FormBoxItem>
      </FormBox>
      <BottomBtnWrapper>
        {id && (
          <>
            <BUButton color="gray" size="small-1" onClick={() => navigate('/ad/reference')}>
              목록
            </BUButton>
            <BUButton color="red" size="small-1" onClick={handleOnDeleteData}>
              삭제
            </BUButton>
          </>
        )}
        <BUButton
          size="small-1"
          onClick={() => {
            validationCheck();
          }}>
          {id ? '수정' : '등록'}
        </BUButton>
      </BottomBtnWrapper>
    </ContentContainer>
  );
};

export default ReferenceForm;
