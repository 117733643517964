import http from 'api/http';
import { getDayFormatHypenV2 } from '../../utility/admin/timeFormat';

// 자료실 관련 api
//asd
export const fileBoardDataGetAPI = (params) => {
  return http('/file-board', {
    params: {
      ...params,
      startDate: getDayFormatHypenV2(params.startDate),
      endDate: getDayFormatHypenV2(params.endDate),
    },
  });
};

export const fileBoardDataGetDetailAPI = (id) => {
  return http.get(`/file-board/${id}`);
};

export const fileBoardDataCreateAPI = (params) => {
  return http.post('/file-board', params);
};
export const fileBoardDataUpdateAPI = (params, id) => {
  // return http.put(`/file-board`, params);
  return http.post(`/file-board/put`, params);
};
export const fileBoardDataDeleteAPI = (id) => {
  // return http.delete(`/file-board/${id}`);
  return http.get(`/file-board/${id}/delete`);
};
