import React from 'react';

const ServiceSectionTypography = ({ title, className }) => {
  return (
    <div
      className={`text-heading2 text-dark1A md:text-heading2 sm:text-heading3 xs:text-heading4 fold:text-heading4   ${className}`}>
      {title}
    </div>
  );
};

export default ServiceSectionTypography;
