import { ContentContainer, FormBox, FormBoxItem, FormBoxItemTitle, FormBoxItemValue, PageTitle } from 'styles/sc/admin';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '../../../components/admin/TextField';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { deleteUser, getUserDetail, signUpUser, upDateUserDetail, userIdCheck } from '../../../api/admin/user';
import { Toast } from '../../../components/toast';
import { BUButton } from '../../../components/admin/BUButton';
import { emailRegex } from '../../../utility/web/regex';
import { ModalContext } from '../../../components/modal/modalContext';
import { getDayFormatHypenV2 } from '../../../utility/admin/timeFormat';

const UserForm = () => {
  const { id } = useParams();
  const { openModal, closeModal } = useContext(ModalContext);
  const nav = useNavigate();
  const [idCheck, setIdCheck] = useState(false);
  const [formCheck, setFormCheck] = useState(true);
  const [userName, setUserName] = useState('');
  const [dataForm, setDataForm] = useState({
    name: '',
    username: '',
    password: '',
    email: '',
    phone: '',
    affiliation: '',
    createdDate: '',
    updatedDate: '',
  });

  const handleOnDetailData = () => {
    getUserDetail(id)
      .then((res) => {
        setDataForm(res.data);
      })
      .catch((err) => {});
  };
  const handleOnClickIdCheck = useCallback(() => {
    const name = dataForm.username;
    const newName = name.replace(/(\s*)/g, '');
    if (!newName) return Toast.info('아이디를 입력해주세요.');
    userIdCheck(newName)
      .then((res) => {
        if (!res.data) {
          Toast.success('사용 가능한 아이디입니다.');
          setUserName(dataForm.username);
          setIdCheck(!res.data);
        } else {
          Toast.info('이미 사용중인 아이디입니다.');
        }
      })
      .catch((err) => {});
  }, [dataForm.username]);
  const handleOnClickUpDateUser = () => {
    openModal('수정 하시겠습니까?', {
      confirm: async () => {
        upDateUserDetail(id, {
          name: dataForm.name,
          phone: dataForm.phone,
          email: dataForm.email,
          affiliation: dataForm.affiliation,
        })
          .then(({ status }) => {
            if (status === 200) {
              Toast.success('관리자가 정상적으로 수정되었습니다.');
              nav('/ad/user');
            } else {
              Toast.error('작업 처리 도중 오류가 발생하였습니다.');
            }

            closeModal();
          })
          .catch((err) => {});
      },
    });
  };
  const handleOnCLickDeleteUser = () => {
    openModal('삭제 하시겠습니까?', {
      confirm: async () => {
        deleteUser(id)
          .then(({ status }) => {
            if (status === 200) {
              Toast.success('정상적으로 삭제되었습니다.');
              nav('/ad/user');
              closeModal();
            } else {
              Toast.error('작업 처리 도중 오류가 발생하였습니다.');
            }
          })
          .catch((err) => {});
      },
    });
  };
  const handleOnClickCreatedUser = useCallback(() => {
    if (!dataForm.username || dataForm.username?.length === 0) {
      return Toast.info('아이디를 입력해주세요.');
    }

    if (!dataForm.password || dataForm.password?.length === 0) {
      return Toast.info('비밀번호를 입력해주세요.');
    }
    if (!dataForm.name) return Toast.info('이름을 입력해주세요.');
    if (!dataForm.phone) return Toast.info('휴대폰번호를 입력해주세요.');
    if (dataForm.phone.length !== 11) return Toast.info('휴대폰번호를 전부 입력해주세요.');
    if (!dataForm.affiliation) return Toast.info('소속을 입력해주세요.');
    if (!id && !idCheck) return Toast.info('아이디 중복체크를 해주세요.');
    if (!emailRegex(dataForm.email)) return Toast.info('이메일 형식이 올바르지 않습니다.');
    openModal('등록 하시겠습니까?', {
      confirm: async () => {
        await signUpUser(dataForm)
          .then(({ data, status }) => {
            if (status === 200) {
              Toast.success('관리자가 정상적으로 등록되었습니다.');
              nav('/ad/user');
            } else if (status === 400) {
              const { message } = data;
              Toast.info(message);
            } else {
              Toast.error('등록하는데 실패하였습니다.');
            }
          })
          .catch((err) => {});
        closeModal();
      },
    });
  }, [dataForm, idCheck]);

  const onChangeData = ({ target: { name, value } }) => {
    if ((name === 'phone' || name === 'password') && isNaN(+value)) return;
    setDataForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  useEffect(() => {
    if (id) return handleOnDetailData();
  }, []);
  useEffect(() => {
    if (dataForm?.username !== userName) setIdCheck(false);
    if (
      dataForm?.username &&
      dataForm?.password &&
      dataForm?.name &&
      dataForm?.phone &&
      dataForm?.phone.length === 11 &&
      dataForm?.affiliation &&
      idCheck
    )
      setFormCheck(false);
    else setFormCheck(true);
  }, [dataForm, idCheck]);

  return (
    <ContentContainer>
      <PageTitle>관리자 {id ? '수정' : '등록'}</PageTitle>
      <FormBox>
        <FormBoxItem>
          <FormBoxItemTitle>계정 구분</FormBoxItemTitle>
          <FormBoxItemValue>
            <TextField placeholder="계정 구분" height="42px" width="250px" name="name" value={'관리자'} disabled />
          </FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>아이디</FormBoxItemTitle>
          <FormBoxItemValue>
            <TextField
              placeholder="아이디"
              height="42px"
              width="250px"
              name="username"
              maxLength={20}
              disabled={id}
              value={dataForm.username}
              onChange={onChangeData}
            />
            {!id && (
              <button
                onClick={handleOnClickIdCheck}
                disabled={idCheck}
                className={`ml-[18px] h-[40px] py-[8px] px-[20px] text-[14px] font-[400] rounded-[6px] ${
                  idCheck ? 'bg-[#fafafc] text-dark3A' : 'bg-navy text-white'
                }`}>
                <span>중복체크</span>
              </button>
            )}
          </FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>비밀번호</FormBoxItemTitle>
          <FormBoxItemValue>
            {id ? (
              <button
                onClick={() => nav(`/ad/user_edit/pw/${id}`, { state: dataForm })}
                className={`h-[40px] py-[8px] px-[20px]  text-[14px] font-[400] rounded-[6px] bg-main text-white`}>
                <span>수정하기</span>
              </button>
            ) : (
              <TextField
                placeholder="비밀번호"
                type="password"
                height="42px"
                width="250px"
                name="password"
                value={dataForm.password}
                onChange={onChangeData}
              />
            )}
          </FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>이름</FormBoxItemTitle>
          <FormBoxItemValue>
            <TextField
              placeholder="이름"
              height="42px"
              width="250px"
              name="name"
              value={dataForm.name}
              onChange={onChangeData}
            />
          </FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>휴대폰번호</FormBoxItemTitle>
          <FormBoxItemValue>
            <TextField
              placeholder="휴대폰번호"
              height="42px"
              width="250px"
              maxLength={11}
              name="phone"
              value={dataForm.phone}
              onChange={onChangeData}
            />
          </FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>이메일</FormBoxItemTitle>
          <FormBoxItemValue>
            <TextField
              placeholder="이메일"
              height="42px"
              width="250px"
              name="email"
              value={dataForm.email}
              onChange={onChangeData}
            />
          </FormBoxItemValue>
        </FormBoxItem>
        <FormBoxItem>
          <FormBoxItemTitle>소속</FormBoxItemTitle>
          <FormBoxItemValue>
            <TextField
              placeholder="소속"
              height="42px"
              width="250px"
              name="affiliation"
              maxLength={20}
              value={dataForm.affiliation}
              onChange={onChangeData}
            />
          </FormBoxItemValue>
        </FormBoxItem>
        {id && (
          <>
            <FormBoxItem>
              <FormBoxItemTitle>등록 일시</FormBoxItemTitle>
              <FormBoxItemValue>
                <TextField
                  placeholder="등록일시"
                  height="42px"
                  width="250px"
                  name="createdDate"
                  disabled
                  value={getDayFormatHypenV2(dataForm.createdDate)}
                />
              </FormBoxItemValue>
            </FormBoxItem>
            <FormBoxItem>
              <FormBoxItemTitle>수정 일시</FormBoxItemTitle>
              <FormBoxItemValue>
                <TextField
                  placeholder="수정일시"
                  height="42px"
                  width="250px"
                  name="updatedDate"
                  disabled
                  value={getDayFormatHypenV2(dataForm.updatedDate)}
                />
              </FormBoxItemValue>
            </FormBoxItem>
          </>
        )}
      </FormBox>
      <div className={'flex items-center justify-center mt-[48px]'}>
        {id ? (
          <>
            <BUButton color={'gray'} size="small-1" onClick={() => nav('/ad/user')}>
              목록
            </BUButton>
            <BUButton color={'red'} size="small-1" onClick={handleOnCLickDeleteUser}>
              삭제
            </BUButton>
            <BUButton size="small-1" onClick={handleOnClickUpDateUser}>
              수정
            </BUButton>
          </>
        ) : (
          <BUButton size="small-1" onClick={handleOnClickCreatedUser} disabledState={formCheck}>
            등록
          </BUButton>
        )}
      </div>
    </ContentContainer>
  );
};
export default UserForm;
