import React from 'react';
import styled from 'styled-components';
const TextAreaWrap = styled.div`
  display: flex;
  align-items: center;
`;
const TextAreaStyled = styled.textarea`
  width: 100%;
  resize: none;
  font-size: 14px;
  color: var(--color-dark-1);
  border-radius: 6px;
  border: 1px solid var(--color-admin-border);
  height: 200px;
  padding: 8px 12px;

  &:focus-visible {
    outline: var(--color-admin-main) auto 1px;
  }
`;

export const TextArea = ({ ...rest }) => {
  return (
    <TextAreaWrap>
      <TextAreaStyled {...rest} />
    </TextAreaWrap>
  );
};
