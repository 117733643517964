import React from 'react';
import Section from '../../../layout/web/Section';
import notFound from '../../../assets/images/web/notFound.png';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const nav = useNavigate();
  return (
    <Section>
      <Section
        maxSize
        className={
          'flex items-center transition-all justify-center pt-[216px] pb-[216px] md:pt-[280px]  md:pb-[240px] sm:pt-[270px]  sm:pb-[260px] xs:pt-[270px]  xs:pb-[200px] fold:pt-[270px] fold:pb-[200px]'
        }>
        <div className={'text-center flex flex-col items-center justify-center'}>
          <img
            src={notFound}
            alt=""
            className={
              'transition-all max-w-[296px] max-h-[272px] mb-[40px] xs:w-[212px] xs:h-[194px] fold:w-[212px] fold:h-[194px]'
            }
          />
          <div
            className={
              'transition-all text-bodyMedium font-[600] leading-[33px] xs:text-bodyNormal xs:font-[600] fold:text-bodyNormal fold:font-[600] text-dark49'
            }>
            요청하신 페이지를 찾을 수 없습니다.
          </div>
          <div
            className={'text-bodySmall leading-[30px] mb-[30px] xs:text-bodyXSmall fold:text-bodyXSmall text-dark49'}>
            올바른 주소인지 확인 후 다시 시도해 주세요!
          </div>
          <button
            className={
              'transition-all px-[16px] py-[5px] bg-darkDB rounded-[10px] text-bodySmall text-dark49 leading-[30px] xs:text-bodyXSmall fold:text-bodyXSmall'
            }
            onClick={() => nav(-1)}>
            이전화면
          </button>
        </div>
      </Section>
    </Section>
  );
};

export default NotFound;
