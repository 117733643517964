import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Section from '../../../layout/web/Section';
import ServiceSectionTypography from '../../../components/web/texts/ServiceSectionTypography';
import PaymentCircleList from '../../../components/web/lists/PaymentCircleList';
import ServerSection from '../../../layout/web/services/ServerSection';
import ServiceCardList from '../../../components/web/lists/ServiceCardList';
import mPaymentLogic from '../../../assets/images/services/payment/mPaymentLogic.png';
import paymentLogic from '../../../assets/images/services/payment/paymentLogic.png';
import { useNavigate } from 'react-router-dom';
import {
	paymentCardItems,
	paymentCircleItems,
} from '../../../utility/web/items/serviceExplanation';
import { useMediaQuery } from '@mui/material';

const Platform = () => {
	const [currentImage, setCurrentImage] = useState({
		current: 0,
		next: 1,
	});
	const sm = useMediaQuery('(max-width:800px)');
	const firstSectionRef = useRef(null);
	const lastSectionRef = useRef(null);
	const nav = useNavigate();
	useEffect(() => {
		const timer = setInterval(() => {
			setCurrentImage((prev) => ({
				current: prev.next,
				next: (prev.next + 1) % 4,
			}));
		}, 4000);

		return () => clearInterval(timer);
	}, [currentImage]);

	const callback = (entries, io) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				if (!entry.target.classList) return;
				entry.target.classList.add('active');
				io.unobserve(entry.target);
			}
		});
	};
	const sections = new IntersectionObserver(callback, {
		threshold: [sm ? 0.1 : 0.4],
	});
	useEffect(() => {
		if (firstSectionRef.current) sections.observe(firstSectionRef.current);
		if (lastSectionRef.current) sections.observe(lastSectionRef.current);
		return () => {
			if (firstSectionRef.current) sections.unobserve(firstSectionRef.current);
			if (lastSectionRef.current) sections.unobserve(lastSectionRef.current);
		};
	}, []);
	return (
		<Page>
			<Section className={'bg-[#F9F9F9]'}>
				<Section
					maxSize
					className={
						'pt-[180px] md:pt-[160px] sm:pt-[133px] xs:pt-[103px] fold:pt-[103px] pb-[120px] md:pb-[100px] sm:pb-[70px] xs:pb-[50px] fold:pb-[50px]'
					}>
					<div
						ref={firstSectionRef}
						className={'flex flex-col items-center justify-center'}>
						<div className={'max-w-[600px]'}>
							<ServiceSectionTypography
								title={'전자결제 서비스'}
								className={
									'text-dark1A mb-[30px] md:mb-[25px] sm:mb-[20px] xs:mb-[15px] fold:mb-[15px] fade-in-up'
								}
							/>
							<div
								className={
									'text-bodyLarge text-dark49 font-[500] leading-[40px] xs:text-bodySmall xs:leading-[30px] fold:text-bodySmall fold:leading-[30px] mb-[60px] md:mb-[50px] sm:mb-[40px] xs:mb-[30px] fold:mb-[30px] fade-in-up-sub'
								}>
								소비자가 신용카드 및 다양한 결제수단을{' '}
								<br className={'hidden xs:block fold:block'} /> 이용하여
								안전하게 <br className={'xs:hidden fold:hidden'} /> 결제할 수
								있도록
								<br className={'hidden xs:block fold:block'} /> 지원하는
								서비스입니다.
							</div>
						</div>

						<PaymentCircleList items={paymentCircleItems} />
					</div>
				</Section>
			</Section>
			<ServiceCardList
				items={paymentCardItems}
				title={'전자결제 서비스 항목'}
			/>
			<ServerSection
				btnTitle={'서비스 문의하기'}
				onClick={() => nav('/customerService/question/create')}>
				<div>온/오프라인 비즈니스에</div>
				<div>필요한 모든 플랫폼 서비스</div>
			</ServerSection>

			<Section>
				<Section
					maxSize
					className={
						'relative pt-[100px] pb-[120px] md:pt-[80px] md:pb-[100px] sm:pt-[70px] sm:pb-[80px] xs:pt-[50px] xs:pb-[60px] fold:pt-[50px] fold:pb-[60px]'
					}>
					<ServiceSectionTypography
						title={'전자결제 서비스 흐름도'}
						className={
							' mb-[80px] md:mb-[70px] sm:mb-[60px] xs:mb-[40px] fold:mb-[40px] text-dark1A'
						}
					/>
					<div
						className={'w-full flex items-center justify-center '}
						ref={lastSectionRef}>
						<img
							className={'hidden lg:block md:block fade-in-up'}
							src={paymentLogic}
							alt=''
						/>
						<div
							className={
								'bg-darkF9  rounded-[25px] lg:hidden md:hidden fade-in-up'
							}>
							<img
								className={'min-w-[252px] min-h-[600px]'}
								src={mPaymentLogic}
								alt=''
							/>
						</div>
					</div>
				</Section>
			</Section>
		</Page>
	);
};

export default Platform;

const Page = styled(Section)`
	text-wrap: normal;
	white-space: normal;
	word-break: keep-all;
	text-align: center;

	.fade-in-up {
		opacity: 0;
		transform: translateY(100px);
		transition:
			opacity 1s,
			transform 0.7s;
	}
	.fade-in-up-circle {
		opacity: 0;
		transform: translateY(100px);
		transition:
			opacity 1s,
			transform 0.7s;
		transition-delay: 0.3s;
	}
	.fade-in-up-sub {
		opacity: 0;
		transform: translateY(100px);
		transition:
			opacity 1s,
			transform 0.7s;
		transition-delay: 0.1s;
	}
	& .active .fade-in-up,
	& .active .fade-in-up-sub,
	& .active .fade-in-up-circle,
	&.active .fade-in-up {
		opacity: 1;
		transform: translateY(0);
	}
`;
