import React from 'react';
import styled from 'styled-components';

const MainSectionRowList = ({ items }) => {
  return (
    <div
      className={
        'min-h-[600px] mt-[120px] md:mt-[90px] sm:mt-[60px] xs:mt-[29px] fold:mt-[29px] grid grid-cols-4 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-2 fold:grid-cols-1   border-solid border-[1px] border-darkDB border-r-0 border-b-0'
      }>
      {items.map((item, index) => {
        return (
          <RowListItems
            key={index}
            className={` max-h-[647px] pt-[200px] min-h-[304px] md:pt-[77px] md:pb-[40px] sm:py-[77px] xs:py-[65px] fold:py-[65px] min-w-[180px] `}>
            <div className={'w-full text-center flex flex-row items-center justify-center'}>
              <div
                className={
                  'w-[70px] h-[70px]  md:h-[60px] md:w-[60px] sm:h-[50px] sm:w-[50px] xs:h-[40px] xs:w-[40px] fold:h-[40px] fold:w-[40px] bg-main flex items-center justify-center rounded-full mb-[20px]'
                }>
                {item.icon}
              </div>
            </div>
            <div
              className={
                'text-dark1A text-bodyLarge md:text-bodyMedium sm:text-bodyNormal xs:text-bodySmall xs:font-[600] xs:leading-[30px] fold:text-bodySmall fold:font-[600] fold:leading-[30px] font-[600] mb-[30px]  md:mb-[25px]  sm:mb-[20px]  xs:mb-[12px] fold:mb-[12px]'
              }>
              {item.title}
            </div>
            <div
              className={
                'text-bodyNormal text-dark49 font-[500] leading-[30px] whitespace-pre-line  sm:text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall '
              }>
              {item.content}
            </div>
          </RowListItems>
        );
      })}
    </div>
  );
};

export default MainSectionRowList;

const RowListItems = styled.div`
  border-right: 1px solid rgba(219, 219, 219, 1);
  border-bottom: 1px solid rgba(219, 219, 219, 1);
  text-align: center;
  vertical-align: middle;
  & tr {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    background: rgba(248, 251, 255, 1);
  }
`;
