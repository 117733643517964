import styled from 'styled-components';

function Uploader({ children, ...rest }) {
  return (
    <>
      <UploaderLabel htmlFor={rest.id}>{children}</UploaderLabel>

      <UploaderInput
        type="file"
        id={rest.id}
        onChange={(e) => {
          rest.onChange(e);
        }}
      />
    </>
  );
}

export { Uploader };

const UploaderLabel = styled.label`
  cursor: pointer;
`;
const UploaderInput = styled.input`
  display: none;
`;
