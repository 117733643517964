import React, { useContext } from 'react';
import { styled } from 'styled-components';
import { ModalContext } from './modalContext';

const WaitModal = () => {
    const { modalContent, modalSubContent, openModal, modal, confirm } = useContext(ModalContext);
    console.log('modalSubContent,',modalSubContent)
    if (modal) {
        return (
            <ModalWrapper>
                <ModalBox className={'p-[45px] pr-[65px] pl-[65px]'}>
                    <div className="title pb-[22px] text-dark1A text-bodyNormal xs:text-bodySmall fold:text-bodySmall whitespace-pre-line break-keep">
                        {modalContent}
                    </div>
                    <ModalSubContentContainer>
                        {modalSubContent}
                    </ModalSubContentContainer>
                    <div className={'btnBox'}>
                        <button
                            className={`success px-[40px] py-[10px] xs:px-[30px] fold:px-[30px]  mt-[42px] ml-[20px] text-bodyXSmall`}
                            onClick={() => confirm()}>
                            확인
                        </button>
                    </div>
                </ModalBox>
            </ModalWrapper>
        );
    } else return null;
};

export default WaitModal;
const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  padding: 0 10px;
  height: 100vh;
  width: 100%;
  z-index: 21;
  background-color: rgba(58, 58, 60, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
`;

const ModalBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow:
    0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 20px;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
  @media all and (max-width: 600px) {
    font-size: 18px;
    flex-direction: column;
    text-align: center;
    margin: 0 40px;
    & .title {
      padding-bottom: 0;
      line-height: 30px;
    }
    & .btnBox {
      & button {
        margin: 0 auto;
        margin-top: 20px;
      }
    }
  }
  & * {
    transition: all 0.3s;
  }
  & .btnBox {
    display: flex;
    flex-direction: row;
    justify-content: end;
    & button {
      border-radius: 10px;
      cursor: pointer;
      background: var(--color-admin-main);
      color: #fff;
    }
  }
  @media all and (max-width: 510px) {
  }
`;

const ModalSubContentContainer =styled.div`
  color: #3a3a3c;
`;
