import http from '../http';
import { getDayFormatHypenV2 } from '../../utility/admin/timeFormat';

export const noticeList = (params) => {
  return http.get('/notice', {
    params: {
      ...params,
      startDate: getDayFormatHypenV2(params.startDate),
      endDate: getDayFormatHypenV2(params.endDate),
    },
  });
};

export const noticeDetail = (id) => {
  return http.get(`/notice/${id}`);
};

export const noticeEdit = (id, data) => {
  // return http.put(`/notice/${id}`, data);
  return http.post(`/notice/${id}/put`, data);
};

export const noticeCreate = (data) => {
  return http.post(`/notice`, data);
};

export const noticeDelete = (id) => {
  // return http.delete(`/notice/${id}`);
  return http.get(`/notice/${id}/delete`);
};
