import React from 'react';

const NotData = ({ text }) => {
  return (
    <div
      className={
        'w-full text-dark49 leading-[30px] flex items-center justify-center text-bodyNormal xs:text-bodySmall fold:text-bodySmall py-[185px] xs:py-[158px] fold:py-[158px]'
      }>
      <div className={'absolute'}>{text}</div>
    </div>
  );
};

export default NotData;
