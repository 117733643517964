import http from '../http';
import { getDayFormatHypenV2 } from '../../utility/admin/timeFormat';

export const applyServiceList = (params) => {
  return http.get('/service-application', {
    params: {
      ...params,
      startDate: getDayFormatHypenV2(params.startDate),
      endDate: getDayFormatHypenV2(params.endDate),
      searchOption: params.searchOption ? +params.searchOption : '',
      status: params.status ? +params.status : '',
    },
  });
};

export const applyDetail = (id) => {
  return http.get(`/service-application/${id}`);
};

export const applyCheck = (id) => {
  // return http.put(`/service-application/${id}/checker`);
  return http.post(`/service-application/${id}/checker/put`);
};
