import { useState } from 'react';

let todo;
const useModal = () => {
  let [modal, setModal] = useState(false);
  let [modalContent, setModalContent] = useState("I'm the Modal Content");
  let [modalSubContent, setModalSubContent] = useState("I'm the Modal Content");
  const [modalType, setModalType] = useState('confirm');
  let openModal = (content = false, args, modalType,subContent) => {
    todo = args;
    setModalType(modalType?.type || 'confirm');
    setModal(!modal);
    if (content) {
      setModalContent(content);
    }
    if(subContent) {
      setModalSubContent(subContent)
    }
    // if (confirmCallback) setConfirm(confirmCallback);
  };

  let closeModal = () => {
    setModal(false);
  };

  return {
    modal,
    openModal,
    modalContent,
    modalSubContent,
    todo,
    closeModal,
    modalType,
  };
};

export default useModal;
