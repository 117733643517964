import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const ApplyProcedureCard = ({ item, observe, index }) => {
  const cardRef = useRef(null);
  useEffect(() => {
    let pageOut = false;
    if (cardRef.current && observe) {
      setTimeout(() => {
        !pageOut && cardRef.current.classList.add('fade-in-up');
      }, 300 * index);
    }
    return () => {
      pageOut = true;
    };
  }, [observe]);
  return (
    <ApplyCardBox
      ref={cardRef}
      className={`justify-start lg:min-w-[440px] min-h-[350px] xs:min-h-[180px] fold:min-h-[180px] pt-[80px] xs:pt-0 fold:pt-0 xs:justify-center fold:justify-center translate-y-1/2 opacity-0`}>
      <div className={'mb-[20px] w-[42px] h-[40px] xs:hidden fold:hidden'}>{item.icon}</div>
      <div
        className={
          'text-dark1A text-bodyMedium xs:text-bodySmall fold:text-bodySmall font-[600] xs:font-[600] fold:font-[600] mb-[13px]'
        }>
        {item.title}
      </div>
      <div
        className={
          'text-dark49 text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall leading-[30px] mb-[10px]  xs:mb-0 fold:mb-0'
        }>
        {item.content}
      </div>
      <div
        className={
          'text-[rgba(245,0,0,1)] text-bodyXSmall  xs:text-bodyXXSmall fold:text-bodyXXSmall leading-[24px] mt-[5px]'
        }>
        {item.subContent}
      </div>
    </ApplyCardBox>
  );
};

export default ApplyProcedureCard;

const ApplyCardBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  white-space: pre-line;
  background: #fff;
  border-radius: 25px;
  border: solid 1px rgba(219, 219, 219, 1);
  transition: all 0.4s;

  &:hover {
    border-color: #0081ff;
  }
`;
