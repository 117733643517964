import React, { useCallback, useContext, useEffect, useState } from 'react';
import ServiceSectionTypography from '../../../../components/web/texts/ServiceSectionTypography';
import TextInputForm from '../../../../components/web/inputForm/TextInputForm';
import { emailRegex } from '../../../../utility/web/regex';
import Section from '../../../../layout/web/Section';
import MainColorBtn from '../../../../components/web/button/MainColorBtn';
import styled from '@emotion/styled';
import CheckBox from '../../../../components/inputs/CheckBox';
import {
	emailSelectedOptions,
	questionTypeOptions,
} from '../../../../utility/web/selectedOptions';

import { useSetRecoilState } from 'recoil';
import { loadingState } from '../../../../recoil/auth';
import {
	postCreatedQuestion,
	putEditedQuestion,
} from '../../../../api/web/customerService/question';
import { ModalContext } from '../../../../components/modal/modalContext';
import { useNavigate, useLocation } from 'react-router-dom';

const CreatedQuestion = () => {
	const [checked, setChecked] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const nav = useNavigate();
	const location = useLocation();
	const state = location.state;
	const [emailWarning, setEmailWarning] = useState(false);
	const setLoading = useSetRecoilState(loadingState);
	const [question, setQuestion] = useState({
		inquiryType: '',
		name: '',
		phone: '',
		email: '',
		password: '',
		title: '',
		content: '',
	});

	useEffect(() => {
		if (state?.type === 'edit') {
			setQuestion({
				...question,
				...state,
			});
			window.history.replaceState({}, '');
			nav('.', { state: { type: 'edit' } });
		}
	}, []);

	const { inquiryType, name, phone, email, password, title, content } = question;
	const { openModal, closeModal } = useContext(ModalContext);

	const handleQuestuinOnclick = useCallback(async () => {
		if (!emailRegex(email)) return setEmailWarning(true);
		openModal(
			`${
				state?.type === 'edit' && question.id ? '수정' : '문의'
			} 하시겠습니까?`,
			{
				confirm: async () => {
					closeModal();
					setLoading(true);
					let errorMessage = null;
					await (state?.type === 'edit' && question.id
						? putEditedQuestion
						: postCreatedQuestion)(question)
						.then((res) => {
							if (res?.data.status !== 200 && res?.data?.message) {
								errorMessage = res?.data?.message;
							}
							setLoading(false);
						})
						.catch((err) => console.log(err));
					openModal(
						errorMessage ?? `문의하신 내용이 정상적으로 ${
							state?.type === 'edit' && question.id ? '수정' : '접수'
						}되었습니다.`,
						{
							confirm: async () => {
								closeModal();
								nav('/customerService/question');
							},
						},
						{ type: 'success' },
					);
				},
			},
		);
	}, [question]);

	useEffect(() => {
		if (
			inquiryType &&
			name &&
			phone &&
			email &&
			password &&
			title &&
			content &&
			((state?.type === 'edit' && question.id) || checked)
		) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [question, checked]);

	return (
		<Section>
			<Section
				maxSize
				className={
					'pt-[180px] md:pt-[140px] xs:pt-[103px] fold:pt-[103px] sm:pt-[103px] transition-all'
				}>
				<ServiceSectionTypography
					title={'문의하기'}
					className={
						'text-dark1A text-center mb-[100px]   md:mb-[70px] sm:mb-[60px] xs:mb-[50px] fold:mb-[50px]'
					}
				/>
				<div
					className={
						'lg:px-[50px] md:px-[40px] sm:px-[30px] xs:px-[10px] fold:px-[10px]  pt-[20px] py-[30px] xs:pb-[10px] fold:pb-[10px] border-t-[2px] border-b-[1px] border-b-darkDB border-dark1A border-solid mb-[20px] lg:mb-[60px] md:mb-[45px] sm:mb-[30px]'
					}>
					<TextInputForm
						type={'select'}
						title={'문의 유형'}
						id={'inquiryType'}
						options={questionTypeOptions}
						placeholder={'문의 유형을 선택해주세요.'}
						setValue={setQuestion}
						value={inquiryType}
						essential
					/>
					<TextInputForm
						type={'text'}
						title={'이름'}
						id={'name'}
						placeholder={'이름을 입력해주세요.'}
						setValue={setQuestion}
						value={name}
						essential
					/>
					<TextInputForm
						type={'number'}
						title={'연락처'}
						id={'phone'}
						placeholder={'연락처를 입력해주세요.'}
						setValue={setQuestion}
						value={phone}
						essential
					/>
					<TextInputForm
						type={'email'}
						title={'이메일'}
						id={'email'}
						options={emailSelectedOptions}
						placeholder={'이메일을 입력해주세요.'}
						setValue={setQuestion}
						value={email}
						warning={emailWarning}
						essential
					/>
					<TextInputForm
						disabled={state?.type === 'edit' && question.id}
						type={'password'}
						title={'비밀번호'}
						id={'password'}
						placeholder={'비밀번호 입력해주세요.'}
						setValue={setQuestion}
						value={password}
						essential
					/>
					<TextInputForm
						type={'text'}
						title={'제목'}
						id={'title'}
						placeholder={'제목을 입력해주세요.'}
						setValue={setQuestion}
						value={title}
						essential
					/>
					<TextInputForm
						type={'textArea'}
						title={'내용'}
						id={'content'}
						placeholder={'내용을 입력해주세요.'}
						setValue={setQuestion}
						value={content}
						essential
					/>
				</div>
				{!(state?.type === 'edit' && question.id) && (
					<div
						className={
							'px-0 my-[20px] xs:mb-0 fold:mb-0 lg:px-[50px] md:px-[40px] sm:px-[30px] '
						}>
						<div
							className={
								'flex flex-row items-center justify-start my-[20px] gap-[10px]'
							}>
							<CheckBox value={checked} setValue={setChecked} />
							<div
								className={
									'text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall font-[600] leading-[30px]'
								}>
								개인정보수집 및 이용 약관 동의
							</div>
						</div>
						<div
							className={
								'bg-darkF9 pl-[36px] py-[20px] xs:px-[20px] fold:px-[20px] overflow-y-scroll overflow-x-hidden text-bodyXSmall xs:text-bodyXXSmall fold:text-bodyXXSmall leading-[24px] xs:leading-[23px] fold:leading-[23px] max-h-[184px] whitespace-pre-wrap border-[1px] border-darkDB border-solid'
							}>
							<DescriptionBox>
								<DescriptionBox>
									<DescriptionTitle>
										개인정보의 수집 및 이용안내
									</DescriptionTitle>
								</DescriptionBox>
								<DescriptionContent>
									바움피앤에스 주식회사(이하 "을")는 고객(이하 “갑”)의
									개인정보를 중요시하며 개인정보 보호법, 정보통신망 이용촉진 및
									정보보호 등에 관한 법률 등 관계법령을 준수하고 있습니다.
								</DescriptionContent>
								<DescriptionContent>
									“을”은 개인정보취급방침을 통하여 “갑”의 개인정보가 어떠한
									용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한
									조치가 취해지고 있는지 알려드립니다.
								</DescriptionContent>
							</DescriptionBox>
							<br />
							<DescriptionBox>
								<DescriptionTitle>1. 개인정보의 수집·이용목적</DescriptionTitle>
							</DescriptionBox>
							<DescriptionBox>
								<DescriptionContent>
									“을”은 다음과 같은 목적으로 ”갑”의 개인정보 항목을 수집 및
									이용하고 있습니다.
								</DescriptionContent>
								<DescriptionContent>- 문의하기</DescriptionContent>
							</DescriptionBox>
							<DescriptionBox>
								<br />
								<DescriptionTitle>2. 수집하는 개인정보의 항목</DescriptionTitle>
							</DescriptionBox>
							<DescriptionBox>
								<DescriptionContent>
									서비스 신청서 작성을 위해 "을"의 아래와 같은 개인정보를
									수집합니다.
								</DescriptionContent>
								<DescriptionContent>
									- 이름(성명), 연락처, 이메일
								</DescriptionContent>
							</DescriptionBox>
							<br />
							<DescriptionBox>
								<DescriptionTitle>
									3. 개인정보의 보유 및 이용기간
								</DescriptionTitle>
							</DescriptionBox>
							<DescriptionContent>
								개인정보는 원칙적으로 개인정보의 수집·이용목적이 달성되면 지체
								없이 파기 합니다.
							</DescriptionContent>
							<DescriptionContent>
								단, 전자금융거래법, 전자상거래 등에서의 소비자 보호에 관한 법률
								등 관계법령에 의하여 보존할 필요가 있는 경우 당해 법령에서 정한
								기간 동안 개인정보를 보존합니다.
							</DescriptionContent>
						</div>
					</div>
				)}

				<div
					className={
						'w-full flex items-center justify-center pb-[120px] pt-[80px] xs:py-[50px] fold:py-[50px] '
					}>
					<MainColorBtn
						onClick={handleQuestuinOnclick}
						disabled={disabled}
						className={
							'rounded-[15px] xs:rounded-[10px] fold:rounded-[10px] text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall leading-[30px] xs:leading-[30px] fold:leading-[30px] px-[100px] py-[16px]  xs:px-[60px] xs:py-[10px] fold:px-[60px] fold:py-[10px] transition-all'
						}
						text={state?.type === 'edit' && question.id ? '수정' : '등록'}
					/>
				</div>
			</Section>
		</Section>
	);
};

export default CreatedQuestion;

const DescriptionBox = styled.div`
	//margin-bottom: 20px;
`;

const DescriptionTitle = styled.div`
	color: rgb(49, 49, 49);
`;

const DescriptionContent = styled.div`
	color: rgba(155, 155, 155);
`;
