import React from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { styled as comStyled } from 'styled-components';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: 'rgba(0, 129, 255, 1)',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
});

function BpRadio(props) {
  return <Radio disableRipple color="default" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} {...props} />;
}

const Radios = ({ items, onChange, defaultValue, ...rest }) => {
  return (
    <CustomRadioGroup
      defaultValue={defaultValue?.toString() || '0'}
      onChange={onChange}
      aria-labelledby="demo-customized-radios"
      {...rest}>
      {items?.map((item) => (
        <FormControlLabel
          key={item.name || item.label}
          value={item.value}
          control={<BpRadio />}
          label={item.name || item.label || ''}
        />
      ))}
    </CustomRadioGroup>
  );
};

export default Radios;

const CustomRadioGroup = comStyled(RadioGroup)`
  &.MuiFormGroup-root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-left: 18px;
  >label {
    >.MuiFormControlLabel-label {
      font-family: "Pretendard";
      font-weight: 400;
      color: var(--color-dark-1);
      font-size: 14px;
    }
  }
  }
`;
