import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../assets/logos/miainLogo.svg';
import { ReactComponent as DarkLogo } from '../../assets/logos/mainLogoDark.svg';
import MainColorBtn from '../../components/web/button/MainColorBtn';
import Section from './Section';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavBarBtn from '../../components/web/button/NavBarBtn';
import NavAccordion from '../../components/web/accordions/NavAccordion';
import { useMediaQuery } from '@mui/material';
import { dropdownItems } from '../../utility/web/items/header';

const Header = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const navSize = useMediaQuery('(max-width: 870px)');
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const [changeColor, setChangeColor] = useState(false);
	const [showNav, setShowNav] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 80) {
				setChangeColor(true);
			} else {
				setChangeColor(false);
			}
		};
		if (showNav) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
			document.body.style.overflow = 'unset';
		};
	}, [showNav]);
	useEffect(() => {
		if (!navSize) setShowNav(false);
		if (navSize) setDropdownOpen(false);
	}, [isDropdownOpen, navSize]);
	return (
		<Section>
			<div
				onMouseEnter={(e) => !navSize && setDropdownOpen(true)}
				onMouseLeave={() => !navSize && setDropdownOpen(false)}>
				<HeaderContainer
					className={
						'fixed z-10 flex flex-col xs:left-0 fold:left-0 max-w-[1920px] '
					}
					$isOpen={!navSize && isDropdownOpen}
					$changeColor={!navSize && changeColor}
					$isHome={location.pathname === '/'}>
					<Section
						maxSize
						className={`section flex items-center z-10 relative ${
							changeColor && navSize ? 'bg-white' : ''
						} ${showNav ? 'gradient-active' : ''}`}
						style={{
							background: `linear-gradient(90deg, ${
								showNav && navSize ? '#0081FF' : 'transparent'
							} 50%, transparent 50%)`,
						}}>
						<div
							className={
								' w-full h-[80px] sm:h-[70px] xs:h-[65px] fold:h-[65px] flex flex-row justify-between items-start header:relative '
							}>
							<LogoBox
								className={'flex cursor-pointer'}
								onClick={() => navigate('/')}>
								{(!navSize && isDropdownOpen) ||
								(!navSize && changeColor) ||
								(!(location.pathname === '/') && !showNav) ? (
									<DarkLogo className={'xs:h-[27px] fold:h-[27px]'} />
								) : !showNav && changeColor ? (
									<DarkLogo className={'xs:h-[27px] fold:h-[27px]'} />
								) : (
									<Logo
										className={'xs:h-[27px] fold:h-[27px]'}
										onClick={() => setShowNav(false)}
									/>
								)}
							</LogoBox>
							<MainMenus className={'flex header:hidden'}>
								{dropdownItems.map((item, index) => (
									<div
										key={index}
										className={
											'h-full flex flex-row justify-center min-w-[120px]  relative flex-1 text-bodySmall md:text-[16px]'
										}>
										<div
											className={'flex flex-col items-center justify-center'}>
											<Link
												to={item.link}
												className={
													'p-[10px] cursor-pointer flex items-center justify-center hover:text-main'
												}>
												{item.title}
											</Link>
										</div>
									</div>
								))}
								<div
									className={
										'flex items-center justify-center w-[123px] text-white'
									}>
									<MainColorBtn
										text={'가맹점 관리'}
										className={
											'text-bodySmall py-[10px] px-[18px] rounded-[25px]'
										}
										onClick={() => {
											window.open('https://pullayo.com', '_blank');
										}}
									/>
								</div>
							</MainMenus>
							<div
								className={
									'hidden header:flex h-full items-center justify-center'
								}>
								<NavBarBtn
									state={showNav}
									setState={setShowNav}
									path={location.pathname === '/'}
									changeColor={changeColor}
								/>
							</div>
						</div>
						<div
							className={`lg:hidden absolute top-full flex flex-col transition-all duration-400 w-1/2 xs:w-full fold:w-full z-[3] ${
								showNav ? 'left-full -translate-x-full ' : 'left-full'
							}`}>
							<NavAccordion
								items={dropdownItems}
								status={showNav}
								setState={setShowNav}
							/>
						</div>
					</Section>
					<div
						onClick={() => setShowNav(false)}
						className={`hidden  header:block w-full h-full  fixed bg-[rgba(0,0,0,.7)] z-[2] transition-opacity duration-400 ${
							showNav ? 'opacity-100 visible' : 'opacity-0 invisible'
						}`}
					/>
					{isDropdownOpen && (
						<span
							className={
								'border-b-[1px] border-solid border-b-darkDB box-border header:hidden'
							}
						/>
					)}
					<SubMenuContainer $isOpen={isDropdownOpen}>
						<LogoBox>
							<DarkLogo className={'opacity-0'} />
						</LogoBox>
						<SubMenus>
							{dropdownItems.map((item, index) => (
								<div
									key={index}
									className={
										'h-full max-w-[170px] min-w-[120px]  flex flex-row justify-start items-start  relative flex-1 text-bodySmall md:text-[16px]'
									}>
									{
										<div className={'w-full'}>
											{item.items.map((subItem, index) => (
												<div
													key={index}
													className={
														' text-center h-[50px] w-full flex items-center justify-center hover:text-main'
													}>
													<Link
														to={subItem.link}
														className={'p-[10px]'}
														target={
															subItem.link.includes('https') ? '_blank' : ''
														}
														onClick={() => {
															setShowNav(false);
															setDropdownOpen(false);
														}}>
														{subItem.subTitle}
													</Link>
												</div>
											))}
										</div>
									}
								</div>
							))}
							<div
								className={
									'flex items-center justify-center w-[123px] text-white opacity-0'
								}>
								<MainColorBtn
									text={'가맹점 관리'}
									className={
										'text-bodySmall py-[10px] px-[18px] rounded-[25px]'
									}
									disabled={true}
									onClick={() => navigate(`/ad/login`)}
								/>
							</div>
						</SubMenus>
					</SubMenuContainer>
				</HeaderContainer>
			</div>
		</Section>
	);
};

export default Header;

const HeaderContainer = styled(Section)`
	color: ${({ $isOpen, $changeColor, $isHome }) =>
		$isOpen || $changeColor || !$isHome ? '#494949' : '#fff'};
	background-color: ${({ $isOpen, $changeColor }) =>
		$isOpen || $changeColor ? '#fff' : 'transparent'};

	transition: ${({ $isOpen }) =>
		`height 0.4s ,background-color ${
			$isOpen ? '0' : '0.7s'
		} ease-in-out 0s,color 0.4s ease-in-out 0s`};

	.section {
		position: relative;
	}

	.section::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 0%;
		background: #0081ff;
		transition: width 0.15s;
		z-index: -1;
	}

	.gradient-active::before {
		width: 100%;
	}
`;

const MainMenus = styled.div`
	height: 100%;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	max-width: 973px;
	width: 100%;
`;

const SubMenuContainer = styled.div`
	display: flex;
	margin: 0 auto;
	max-width: 1420px;
	height: auto;

	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: relative;
	overflow: hidden;
	transition: 0.4s;
	max-height: ${({ $isOpen }) => ($isOpen ? '500px' : '0px')};
	padding: ${({ $isOpen }) => ($isOpen ? '30px 20px' : '0 20px')};
	@media (max-width: 1420px) {
		padding-right: 20px;
	}
`;

const SubMenus = styled.div`
	display: flex;
	height: 100%;
	flex-direction: row;
	justify-content: end;
	align-items: start;
	width: 100%;
`;

const LogoBox = styled.div`
	width: auto;
	height: 80px;
	align-items: center;
	justify-content: center;
	& * {
		transition: 0.4s;
	}
	@media (max-width: 800px) {
		height: 70px;
	}

	@media (max-width: 500px) {
		height: 65px;
		transform: translateY(3px);
		& svg {
			width: 78px;
		}
	}
`;
