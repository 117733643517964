import React from 'react';
import { ReactComponent as Calculate } from '../../../assets/icons/services/platform/calculateSolutions.svg';
import { ReactComponent as Maintenace } from '../../../assets/icons/services/platform/maintenanceSolutions.svg';
import { ReactComponent as PaysolTen } from '../../../assets/icons/services/platform/paysolTen.svg';
import { ReactComponent as SosoStore } from '../../../assets/icons/services/platform/sosoStore.svg';
import { ReactComponent as MobilePay } from '../../../assets/icons/services/payment/mobilePay.svg';
import { ReactComponent as Nfc } from '../../../assets/icons/services/payment/nfc.svg';
import { ReactComponent as Passbook } from '../../../assets/icons/services/payment/passbook.svg';
import { ReactComponent as Card } from '../../../assets/icons/services/payment/card.svg';
import { ReactComponent as WitchettPay } from '../../../assets/icons/services/additional/witchettPay.svg';
import { ReactComponent as SmallPayment } from '../../../assets/icons/services/additional/smallPayment.svg';
import { ReactComponent as OverseasRemittance } from '../../../assets/icons/services/additional/overseasRemittance.svg';

// 서비스 안내/플랫폼 서비스
export const platformCircleItems = [
  {
    title: '온라인\n 결제 연동',
    color: '#1E8BF6',
  },
  {
    title: '안전한\n 결제 시스템',
    color: '#0C73D8',
  },
  {
    title: '오프라인 결제\n 모듈 제공',
    color: '#185EA3',
  },
  {
    title: '다양한\n 결제 수단 제공',
    color: '#134575',
  },
];
// 서비스 안내/플랫폼 서비스 서비스 항목
export const platformCardItems = [
  {
    title: '소소상점',
    content: '소상공인에게 필요한 서비스를 제공합니다.\n' + '소상공인 가게 매출을 한번에 관리 할 수 있습니다.',
    icon: <SosoStore />,
  },
  {
    title: 'Paysol ten',
    content: '오직 P&S에서만 가능한 서비스입니다.\n' + '매출 발생 10분 후 바로 입금을 받을 수 있습니다.',
    icon: <PaysolTen />,
  },
  {
    title: '(재)정산솔루션',
    content: '안전하고 정확한 정산 서비스를 통해\n' + '빠른 시간에 정산해 드리는 서비스입니다.',
    icon: <Calculate />,
  },
  {
    title: '대사관리솔루션',
    content: '매출을 손쉽게 확인할 수 있는 서비스입니다.\n' + '정산 금액이 얼마인지, 영업 수수료 관리가 가능합니다.',
    icon: <Maintenace />,
  },
];

// 서비스 안내/전자결제 서비스
export const paymentCircleItems = [
  {
    title: '연중무휴 \n 결제 가능',
    color: '#1E8BF6',
  },
  {
    title: '정산관리 \n 시스템 제공',
    color: '#0C73D8',
  },
  {
    title: '관리자 페이지 \n 대시보드 제공',
    color: '#185EA3',
  },
  {
    title: '가맹점 통합 매출관리 시스템 제공',
    color: '#134575',
  },
];
// 서비스 안내/전자결제 서비스 서비스 항목
export const paymentCardItems = [
  {
    title: '신용·체크카드',
    content: '국내 전 카드사의 신용 · 체크카드(On/Off)로\n' + '일시불, 할부 결제를 제공합니다.',
    icon: <Card />,
  },
  {
    title: '간편결제',
    content: '다양한 간편결제 서비스를 제공합니다.\n' + '원하는 서비스를 선택하여 간편결제가 가능합니다.',
    icon: <Nfc />,
  },
  {
    title: '휴대폰 결제',
    content:
      '휴대폰 번호와 생년월일, 성별 등 인증을 통해\n' +
      '즉시 결제하고 결제금액은 이동통신 요금으로\n' +
      '후불 청구되는 결제 서비스입니다.',
    icon: <MobilePay />,
  },
  {
    title: '가상계좌(무통장 입금)',
    content:
      '국내 전 은행 계좌로 가상계좌 발급이 가능하여\n' +
      '고객이 편리하게 입금 할 수 있으며,\n' +
      '입금 현황을 실시간으로 확인 할 수 있습니다.',
    icon: <Passbook />,
  },
];

// 서비스 안내/부가 서비스
export const additionalCircleItems = [
  {
    title: '쉽고 빠른\n 서비스 오픈',
    color: '#1E8BF6',
  },
  {
    title: '전산 내\n 세금계산서 발행',
    color: '#0C73D8',
  },
  {
    title: '인증 서비스 제공\n 2차 피해 방지',
    color: '#185EA3',
  },
  {
    title: '자체\n 법무팀 운영',
    color: '#134575',
  },
];
// 서비스 안내/부가 서비스 서비스 항목
export const additionalCardItems = [
  {
    title: '해외 송금업',
    content: '수수료가 80~90% 이상 저렴하며\n 빠른 송금 서비스가 가능합니다.',
    icon: <OverseasRemittance />,
  },

  {
    title: '위챗페이',
    content: '카드를 위챗과 연동하면 QR로\n 결제가 가능한 서비스입니다.',
    icon: <WitchettPay />,
  },
  {
    title: '핸드폰 소액결제',
    content: 'On/Off에서 결제하고 통신요금과\n 함께 납부하는 결제 서비스입니다.',
    icon: <SmallPayment />,
  },
];
