import React from 'react';
import styled from '@emotion/styled';

const DirectionsCardItem = ({ icon, title, content }) => {
  return (
    <DirectionsCardBox className={'flex flex-col directions:mb-[20px]'}>
      <div
        className={
          'flex flex-row items-center text-dark1A justify-start text-bodyNormal mb-[5px] xs:text-bodyXSmall fold:text-bodyXSmall'
        }>
        {icon || ''} {title || ''}
      </div>
      <div
        className={
          'text-bodySmall leading-[30px] text-dark49  xs:text-bodyXSmall fold:text-bodyXSmall whitespace-pre-wrap break-keep'
        }>
        {content || ''}
      </div>
    </DirectionsCardBox>
  );
};

export default DirectionsCardItem;

const DirectionsCardBox = styled.div`
  & * {
    transition: all 0.3s;
  }
  & svg {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
  @media screen and (max-width: 499.9px) {
    & svg {
      width: 15px;
      height: 15px;
      margin-right: 8px;
    }
  }
`;
