import React from 'react';

const TextAreaInput = ({ value, id, onChange, placeholder, setFocus, className }) => {
  return (
    <div className={'flex-1 w-full max-w-[950px] transition-all '}>
      <textarea
        type="text"
        className={`w-full max-h-[740px] min-h-[434px] h-full border-darkDB resize-none border-[1px] rounded-[10px] outline-none focus:border-main py-[10px] px-[20px] text-dark49 text-bodyXSmall leading-[24px] placeholder:text-darkDB ${
          className || ''
        }`}
        id={id}
        autoComplete="off"
        value={value || ''}
        onChange={onChange}
        placeholder={placeholder}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    </div>
  );
};

export default TextAreaInput;
