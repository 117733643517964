import http from '../../http';

export const getQuestionList = (params) => {
  return http.get('/inquiry', { params: params });
};

export const getQuestionDetail = (params) => {
  return http.get(`/inquiry/${params}`);
};

export const postQuestionInfoPassword = (params) => {
  return http.post('/inquiry/password', params);
};

export const postCreatedQuestion = (params) => {
  return http.post('/inquiry', params);
};

export const putEditedQuestion = (params) => {
  // return http.put(`/inquiry/${params.id}`, {
  return http.post(`/inquiry/${params.id}/put`, {
    inquiryType: params.inquiryType,
    name: params.name,
    email: params.email,
    password: params.password,
    title: params.title,
    content: params.content,
  });
};
