import React from 'react';

const SectionTypography = ({ title, className }) => {
  return (
    <div
      className={`text-center text-dark1A text-heading1 mb-[30px] md:text-heading2 md:mb-[25px] sm:mb-[15px] xs:mb-[15px] fold:mb-[15px] sm:text-heading3 xs:text-heading3 fold:text-heading3 ${
        className || ''
      }`}>
      {title}
    </div>
  );
};

export default SectionTypography;
