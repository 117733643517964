import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';

const backgroundColorStyles = css`
  ${({ theme, color, $disabledtate }) => {
    const selected = theme.palette[color].background;
    const amount = 0.3;
    const disabledColor = lighten(amount, selected);
    const backgroundColor = $disabledtate ? disabledColor : selected;
    const hoverColor = $disabledtate ? disabledColor : lighten(0.1, selected);
    const activeColor = $disabledtate ? disabledColor : darken(0.1, selected);

    return css`
      background: ${backgroundColor};
      color: ${theme.palette[color].color};
      &:hover {
        background: ${hoverColor};
      }
      &:active {
        background: ${activeColor};
      }
    `;
  }}
`;

const sizeStyles = css`
  ${(props) =>
    props.size === 'large' &&
    css`
      width: 232px;
      height: 62px;
      font-size: 18px;
      @media (max-width: 480px) {
        padding: 12px 22px 14px;
        font-size: 17px;
      }
    `}

  ${(props) =>
    props.size === 'medium' &&
    css`
      width: 232px;
      height: 62px;
      font-size: 18px;
      @media (max-width: 480px) {
        padding: 12px 22px 14px;
        font-size: 16px;
      }
    `}

  ${(props) =>
    props.size === 'small-1' &&
    css`
      border-radius: 7px;
      width: 120px;
      height: 42px;
      font-size: 14px;
      @media (max-width: 480px) {
        padding: 12px 22px 14px;
        font-size: 14px;
      }
    `}
  
  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: 14px;
      @media (max-width: 480px) {
        padding: 12px 22px 14px;
        font-size: 14px;
      }
    `}
  ${(props) =>
    props.size === 'full' &&
    css`
      width: 100%;
      padding: 14px 24px 16px;
    `}
`;

const BUButtonStyled = styled.button`
  //padding: 14px 24px 16px;
  color: #ffffff;
  display: inline-block;
  border-radius: ${(props) => (props.radius ? `${props.radius}px` : '15px')};
  font-weight: 600;
  line-height: 1;
  border: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition:
    -webkit-transform 200ms,
    background 200ms;
  transition:
    transform 200ms,
    background 200ms;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  position: relative;
  ${backgroundColorStyles};
  ${sizeStyles};
  ${(props) =>
    props.disabledState &&
    css`
      cursor: default !important;
    `};
  & + & {
    margin-left: 10px;
  }
`;

export const BUButton = ({ children, color, size, disabledState, radius, ...rest }) => {
  return (
    <BUButtonStyled
      color={color}
      size={size}
      radius={radius}
      disabled={disabledState}
      $disabledtate={disabledState}
      {...rest}>
      {children}
    </BUButtonStyled>
  );
};

BUButton.defaultProps = {
  size: 'medium',
  color: 'primary',
};
