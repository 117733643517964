import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as DownLoad } from '../../../assets/icons/web/referenceRoom/downLoad.svg';
import NotData from '../lists/NotData';

const ReferenceRoomTable = ({ items, pageMeta }) => {
  const { page, pageSize, total } = pageMeta;
  const [formatItems, setFormatItems] = useState([]);
  const handleFileDownLoadOnClick = async (fileId) => {
    // 개발 환경에서는 API URL이 실서버와 다르기 때문에, 개발 환경에서는 다른 URL 로 요청을 보내야 합니다.
    // await window.open(`http://doallat.iptime.org:2602/api/v1/file/${fileId}`, '_blank'); // 개발 환경
    await window.open(`/api/v1/file/${fileId}`, '_blank');
  };
  useEffect(() => {
    if (items) {
      setFormatItems(
        items.map((item, index) => {
          return {
            ...item,
            id: total + (1 - page) * pageSize - index,
          };
        })
      );
    }
  }, [items]);

  return (
    <ReferenceRoomTableContainer
      className={'w-full mb-[60px] border-b-[1px] border-solid border-b-darkDB border-t-2 border-[#1A1A1A]'}>
      <table className={'w-full min-w-[240px]'}>
        <thead
          className={
            'border-b-[1px] border-solid border-b-darkDB text-dark1A text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall min-w-[240px]'
          }>
          <tr className={'h-[60px] xs:h-[54px] fold:h-[54px]'}>
            <th className={'w-1/4 fold:min-w-[50px] fold:max-w-[50px] max-w-1/4 align-middle font-[500]'}>번호</th>
            <th className={'w-2/4 fold:w-auto max-w-[20px] min-w-[20px] align-middle font-[500]'}>제목</th>
            <th className={'w-1/4 fold:min-w-[80px] fold:max-w-[80px] max-w-1/4 align-middle font-[500]'}>파일첨부</th>
          </tr>
        </thead>
        <tbody>
          {formatItems.map((item) => (
            <tr
              key={item.id}
              className={
                'h-[70px] tableRow text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall leading-[24px] rounded-[200px] min-w-[240px]'
              }>
              <td
                className={
                  'text-center text-dark1A rounded-bl-[10px] rounded-tl-[10px] fold:min-w-[50px] fold:max-w-[50px]'
                }>
                {item.id}
              </td>
              <td className={'text-start text-dark1A overflow-ellipsis overflow-hidden whitespace-nowrap max-w-[20px]'}>
                {item.title}
              </td>
              <td className={'text-center rounded-br-[10px] rounded-tr-[10px] fold:min-w-[80px] fold:max-w-[80px]'}>
                <div
                  onClick={() => handleFileDownLoadOnClick(item?.fileId)}
                  className={
                    'inline-flex text-bodyXSmall xs:text-bodyXXSmall fold:text-bodyXXSmall items-center justify-center  border-[1px] border-solid border-[#BCBCBC] text-[#BCBCBC] rounded-[5px] py-[6px] pr-[18px] pl-[14px] xs:px-[7px] fold:px-[7px] cursor-pointer font-[400]'
                  }>
                  <DownLoad className={'w-[10px] h-[13px] mr-[10px] xs:hidden fold:hidden'} />
                  다운로드
                </div>
              </td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td>{(!items || items?.length === 0) && <NotData text={'등록된 자료가 없습니다.'} />}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </ReferenceRoomTableContainer>
  );
};

export default ReferenceRoomTable;

const ReferenceRoomTableContainer = styled.div`
  & * {
    transition: all 0.1s;
  }
  & thead {
  }
  & tbody .tableRow {
    & svg path {
      fill: rgba(188, 188, 188, 1);
      stroke: rgba(188, 188, 188, 1);
    }

    & > td {
      vertical-align: middle;
    }

    &:hover td {
      background-color: #f9f9f9;
      color: #0081ff;

      & > div {
        border: 1px solid #0081ff;
        color: #0081ff;
        & svg path {
          fill: #0081ff;
          stroke: #0081ff;
        }
      }
      &:first-child {
        color: rgb(26, 26, 26);
      }
    }
  }
`;
