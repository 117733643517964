import http from '../http';
import { getDayFormatHypenV2 } from '../../utility/admin/timeFormat';

export const faqList = (params) => {
  return http.get('/faq', {
    params: {
      ...params,
      startDate: getDayFormatHypenV2(params.startDate),
      endDate: getDayFormatHypenV2(params.endDate),
    },
  });
};

export const faqCreate = (data) => {
  return http.post('/faq', data);
};

export const faqDetail = (id) => {
  return http.get(`/faq/${id}`);
};

export const faqEdit = (id, data) => {
  // return http.put(`/faq/${id}`, data);
  return http.post(`/faq/${id}/put`, data);
};

export const faqDelete = (id) => {
  // return http.delete(`/faq/${id}`);
  return http.get(`/faq/${id}/delete`);
};
