import { ReactComponent as Accept } from '../../../assets/icons/web/applys/accept.svg';
import { ReactComponent as Apply } from '../../../assets/icons/web/applys/apply.svg';
import { ReactComponent as InterLock } from '../../../assets/icons/web/applys/interlock.svg';
import { ReactComponent as Review } from '../../../assets/icons/web/applys/review.svg';
import { ReactComponent as Open } from '../../../assets/icons/web/applys/open.svg';
import { ReactComponent as Proceed } from '../../../assets/icons/web/applys/proceed.svg';

// 서비스 신청 절차
export const applyProcedureItems = [
	{
		title: '01. 서비스 신청',
		icon: <Apply />,
		content:
			'· 온라인 신청 : URL (추후 입력)\n · 이메일 : sales@baumpns.com\n · 전화 : 1833-6199',
		subContent: '',
	},
	{
		title: '02. 업무 협의 및 제휴사 심사',
		icon: <Review />,
		content: '영업 담당자를 통한 가능 서비스 및\n 수수료 등 계약사항 협의',
		subContent: '',
	},
	{
		title: '03. 계약서 작성 및 접수',
		icon: <Accept />,
		content: '계약서 작성 후 구비서류와 함께 등기발송',
		subContent:
			'등기발송 주소 : 인천 연수구 인천타워대로323,\n A동 2907-09호(송도동, 송도 센트로드)\n 바움피앤에스(주) 영업본부 ',
	},
	{
		title: '04. 시스템 연동',
		icon: <InterLock />,
		content: '결제 모듈 제공 및 연동 후 이용방법 안내',
		subContent: '',
	},
	{
		title: '05. 보증보험 진행',
		icon: <Proceed />,
		content: '각종 거래에서 발생하는 신용위험\n 감소를 위해 보증보험 진행',
		subContent: '',
	},
	{
		title: '06. 서비스 오픈',
		icon: <Open />,
		content: 'TEST 결제 진행 후 서비스 오픈',
		subContent: '',
	},
];

// 서비스 신청 절차
export const mApplyProcedureItems = [
	{
		title: '01. 서비스 신청',
		icon: <Apply />,
		content:
			'· 온라인 신청 : URL (추후 입력)\n · 이메일 : sales@baumpns.com\n · 전화 : 1833-6199',
		subContent: '',
	},
	{
		title: '02. 업무 협의 및 제휴사 심사',
		icon: <Review />,
		content: '영업 담당자를 통한 가능 서비스 및\n 수수료 등 계약사항 협의',
		subContent: '',
	},
	{
		title: '03. 계약서 작성 및 접수',
		icon: <Accept />,
		content: '계약서 작성 후 구비서류와 함께 등기발송',
		subContent:
			'주소 : 인천 연수구 인천타워대로323,\n A동 2907-9호 (송도 센트로드)\n 바움피앤에스(주) 영업본부 등기발송',
	},
	{
		title: '04. 시스템 연동',
		icon: <InterLock />,
		content: '결제 모듈 제공 및 연동 후 이용방법 안내',
		subContent: '',
	},
	{
		title: '05. 보증보험 진행',
		icon: <Proceed />,
		content: '각종 거래에서 발생하는 신용위험\n 감소를 위해 보증보험 진행',
		subContent: '',
	},
	{
		title: '06. 서비스 오픈',
		icon: <Open />,
		content: 'TEST 결제 진행 후 서비스 오픈',
		subContent: '',
	},
];
