export const bizNoFormatter = (num) => {
  if (!num) return '';
  return num.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
};

export const formatPhoneNumber = (input) => {
  if (!input) return '';
  const cleanInput = input.replaceAll(/[^0-9]/g, '');

  let result = '';
  const length = cleanInput.length;
  if (length === 8) {
    result = cleanInput.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else if (cleanInput.startsWith('02') && (length === 9 || length === 10)) {
    result = cleanInput.replace(/(\d{2})(\d{3,4})(\d{4})/, '$1-$2-$3');
  } else if (!cleanInput.startsWith('02') && (length === 10 || length === 11)) {
    result = cleanInput.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3');
  } else {
    result = cleanInput;
  }
  return result;
};
