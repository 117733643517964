import { ContentContainer, PageTitle, TableWrapStyled } from 'styles/sc/admin';
import Radios from '../../../components/admin/Radios';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { TextFieldInput } from '../../../components/admin/TextField';
import { useNavigate, useParams } from 'react-router-dom';
import { TextArea } from '../../../components/admin/TextArea';
import { BUButton } from '../../../components/admin/BUButton';
import { faqCreate, faqDetail, faqEdit } from '../../../api/admin/faq';
import { Toast } from '../../../components/toast';
import { noticeCreate, noticeDelete, noticeDetail, noticeEdit } from '../../../api/admin/notice';
import { getFullDayFormatHypen } from '../../../utility/admin/timeFormat';
import Loading from '../../../components/loading/Loading';
import { LoadingPage } from '../../../components/loading/LoadingPage';
import { ModalContext } from 'components/modal/modalContext';

const radioItems = [
  {
    label: '일반',
    value: '0',
  },
  {
    label: '고정',
    value: '1',
  },
];

const RadioWrap = styled.div`
  width: 130px;
  > .MuiFormGroup-root {
    padding-left: 2px;
  }
`;
const NoticeForm = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const mode = useMemo(() => {
    return id ? '수정' : '등록';
  }, [id]);
  const { openModal, closeModal } = useContext(ModalContext);
  const [inputs, setInputs] = useState({
    fix: '0',
    title: '',
    content: '',
  });

  const valid = () => {
    let status = false;
    if (!inputs.title.length) {
      Toast.error('제목을 작성해주세요.');
    } else if (inputs.content.length < 10) {
      Toast.error('내용을 10자 이상 작성해주세요');
    } else {
      status = true;
    }
    return status;
  };

  const handleChange = (e) => {
    setInputs((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleClick = async () => {
    try {
      if (!valid()) return;
      let responseStatus = null;
      if (id) {
        const { data, status } = await noticeEdit(id, inputs);
        responseStatus = status;
      } else {
        const { data, status } = await noticeCreate(inputs);
        responseStatus = status;
      }
      if (responseStatus === 200) {
        goList();
        return Toast.success(`공지사항 ${mode}이 완료되었습니다.`);
      }
    } catch (e) {
      return Toast.error(`공지사항 ${mode} 중 오류가 발생하였습니다.`);
    }
  };

  const handleOnDeleteData = useCallback(async () => {
    openModal('삭제하시겠습니까?', {
      confirm: async () => {
        const { status } = await noticeDelete(id);
        if (status !== 200) {
          return Toast.error(`공지사항 삭제 중 오류가 발생하였습니다.`);
        }
        Toast.success(`공지사항 삭제가 완료되었습니다.`);
        closeModal();
        navigate(`/ad/notice`);
      },
    });
  }, [id]);

  const goList = () => {
    navigate(`/ad/notice`);
  };

  useEffect(() => {
    if (!id) {
      setIsLoading(false);
      return;
    }
    const getData = async () => {
      try {
        const { data, status } = await noticeDetail(id);
        setInputs(data);
      } catch (e) {
        return Toast.error('공지사항을 불러오는 중 오류가 발생하였습니다.');
      } finally {
        setIsLoading(false);
      }
    };
    getData();

    return () => {
      setInputs({
        fix: '0',
        title: '',
        content: '',
      });
    };
  }, []);

  if (isLoading) return <LoadingPage />;

  return (
    <ContentContainer>
      <PageTitle>공지사항 {mode}</PageTitle>
      <TableWrapStyled>
        <FormTable>
          <tbody>
            {id && (
              <>
                <tr>
                  <th>등록일시</th>
                  <td>{getFullDayFormatHypen(inputs?.createdDate)}</td>
                </tr>
                <tr>
                  <th>수정일시</th>
                  <td>{getFullDayFormatHypen(inputs?.updatedDate)}</td>
                </tr>
                <tr>
                  <th>등록자</th>
                  <td>{`${inputs?.user?.username || ''} (${inputs?.user?.name || ''})`}</td>
                </tr>
              </>
            )}
            <tr>
              <th>상단고정 여부</th>
              <td>
                <RadioWrap>
                  <Radios items={radioItems} onChange={handleChange} defaultValue={inputs.fix} name="fix" />
                </RadioWrap>
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td>
                <TextFieldInput
                  width={'250px'}
                  height={'42px'}
                  onChange={handleChange}
                  name="title"
                  value={inputs.title}
                />
              </td>
            </tr>
            <tr>
              <th>내용</th>
              <td>
                <TextArea onChange={handleChange} name="content" value={inputs.content} />
              </td>
            </tr>
          </tbody>
        </FormTable>
      </TableWrapStyled>

      <ButtonWrap>
        {id && (
          <>
            <BUButton size="small-1" color="gray" onClick={goList}>
              목록
            </BUButton>
            <BUButton size="small-1" color="red" onClick={handleOnDeleteData}>
              삭제
            </BUButton>
          </>
        )}

        <BUButton size="small-1" onClick={handleClick}>
          {mode}
        </BUButton>
      </ButtonWrap>
    </ContentContainer>
  );
};

const FormTable = styled.table`
  > tbody {
    > tr {
      > td {
        padding-left: 20px !important;
        padding-right: 20px !important;
      }
    }
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;
export default NoticeForm;
