const PrivacyPolicy = () => {
	return (
		<pre style={{ whiteSpace: 'pre-wrap' }}>
			개인정보 수집 및 활용 동의서
			<br />
			<br />
			① 개인정보의 수집 및 이용
			<br />
			1. 개인정보의 수집 및 이용목적
			<br />
			회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
			<br />
			- 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산
			<br />
			- 불량 회원의 부정 이용 방지와 비인가 사용 방지, 불만 처리 등 민원 처리,
			고지 사항 전달
			<br />
			- 회사가 직접 또는 타사와의 업무제휴 등을 통하여 제공하게 되는 신상품의
			소개, 이벤트 안내, 고객 유치, 관리 등을 포함한 각종 마케팅 활용에 이용
			<br />
			2. 수집하는 개인정보 항목
			<br />
			회사는 계약, 상담, 서비스 신청 등을 위해 아래와 같은 개인정보를 수집하고
			있습니다.
			<br />
			- 수집항목 : 이름, 로그인 ID, 비밀번호, 휴대 전화번호, 이메일, 생년월일,
			은행명, 계좌번호, 예금주명, 서비스 이용기록, 접속로그, 접속 IP 정보
			<br />
			- 상기 명시된 개인정보 수집항목 이외의 서비스 이용과정이나 서비스 처리
			과정에서 추가 정보(접속 IP/MAC Address, 쿠키, e-mail, 서비스 접속 일시,
			서비스 이용기록, 불량 혹은 비정상 이용기록, 결제기록)들이 자동 혹은
			수동으로 생성되어 수집될 수 있습니다.
			<br />
			- 개인정보 수집 방법 : 홈페이지(회원가입), 계약서류
			<br />
			3. 개인정보의 보유 및 이용 기간
			<br />
			원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체
			없이 파기합니다. 단, 관계 법령의 규정에 따라 보존할 필요가 있는 경우
			회사는 아래와 같이 관계 법령에서 정한 일정한 기간 회원 정보를 보관합니다.
			<br />
			- 보존 항목 : 이름, 로그인 ID, 비밀번호, 휴대 전화번호, 이메일, 생년월일,
			은행명, 계좌번호, 예금주명, 서비스 이용기록, 접속로 그, 접속 IP 정보
			<br />
			- 보존 근거 : 전자금융거래법 전자상거래 등에서의 소비자 보호에 관한 법률
			<br />
			- 보존 기간 : 5 년<br />
			- 대금 결제 및 재화 등의 공급에 관한 기록 : 5 년 (전자상거래 등에서의
			소비자 보호에 관한 법률)
			<br />
			- 계약 또는 청약 철회에 관한 기록 : 5 년 (전자상거래 등에서의 소비자
			보호에 관한 법률)
			<br />
			- 소비자 불만 또는 분쟁 처리에 관한 기록 : 3 년 (전자상거래 등에서의
			소비자 보호에 관한 법률)
			<br />
			<br />
			② 개인정보 제 3 자 제공 및 위탁
			<br />
			1. 개인정보 제 3 자 제공
			<br />
			회사는 이용자의 동의 없이 이용자님의 개인정보를 제 3 자에게 제공하지
			않습니다. 회사는 서비스 이행을 위하여 개인정보를 다음과 같이 제 3 자에게
			제공하고 있습니다.
			<br />
			<br />
			제공목적 제공받는 자 제공정보 보유 및 이용 기간
			<br />
			서비스 이용을 위한 결제기관 정보제공 1. 신용카드 : 비씨, KB, 삼성, 신한,
			롯데, NH, 현대, 외환, 하나 SK
			<br />
			2. 은행 : 신한, SC 제일, 씨티, 하나, 농협, 기업, 국민, 저축, 수협, 신협,
			우체국, 새마을금고, 대구, 부산, 경남, 광주, 전북, 조흥, 제주
			<br />
			3. 계좌이체 : 뱅크페이
			<br />
			4. 가상계좌 : 세틀뱅크
			<br />
			5. 휴대폰결제 : KG 모빌리언스
			<br />
			6. 전자결제대행업체 : KG 모빌리언스, 스마트로, 페이레터, 세틀뱅크, 기타
			유관 사업자번호, 상호명, 대표자명, 대표자 생년월일, 홈페이지 주소,
			사업자등록번호, 전화번호, 팩스 번호, 이메일주소, 사업장 주소, 담당자명,
			담당자(휴대전화 번호 등) 서비스 종료 시, 또는 상담 후 폐기
			<br />
			<br />
		</pre>
	);
};

export default PrivacyPolicy;
