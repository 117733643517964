import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Section from '../../../layout/web/Section';
import ServiceSectionTypography from '../../../components/web/texts/ServiceSectionTypography';
import PaymentCircleList from '../../../components/web/lists/PaymentCircleList';

import ServiceCardList from '../../../components/web/lists/ServiceCardList';
import {
	additionalCardItems,
	additionalCircleItems,
} from '../../../utility/web/items/serviceExplanation';
import ServerSection from '../../../layout/web/services/ServerSection';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const Additional = () => {
	const firstSectionRef = useRef(null);
	const sm = useMediaQuery('(max-width:800px)');
	const nav = useNavigate();
	const callback = (entries, io) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				entry.target.classList.add('active');
				io.unobserve(entry.target);
			}
		});
	};
	const sections = new IntersectionObserver(callback, {
		threshold: [sm ? 0.1 : 0.4],
	});
	useEffect(() => {
		if (firstSectionRef.current) sections.observe(firstSectionRef.current);
		return () => {
			if (firstSectionRef.current) sections.unobserve(firstSectionRef.current);
		};
	}, []);
	return (
		<Page>
			<Section className={'bg-[#F9F9F9]'}>
				<Section
					maxSize
					className={
						'pt-[180px] md:pt-[160px] sm:pt-[133px] xs:pt-[103px] fold:pt-[103px] pb-[120px] md:pb-[100px] sm:pb-[70px] xs:pb-[50px] fold:pb-[50px]'
					}>
					<div
						ref={firstSectionRef}
						className={'flex flex-col items-center justify-center '}>
						<div className={'max-w-[450px]'}>
							<ServiceSectionTypography
								title={'부가 서비스'}
								className={
									'text-dark1A mb-[30px] md:mb-[25px] sm:mb-[20px] xs:mb-[15px] fold:mb-[15px] fade-in-up'
								}
							/>
							<div
								className={
									'text-bodyLarge text-dark49 font-[500] leading-[40px] xs:text-bodySmall xs:leading-[30px] fold:text-bodySmall fold:leading-[30px] mb-[60px] md:mb-[50px] sm:mb-[40px] xs:mb-[30px] fold:mb-[30px] fade-in-up-sub'
								}>
								안전하고 빠른 결제 서비스를{' '}
								<br className={'hidden xs:block fold:block'} />
								진행하기 위해 다양한 부가 서비스를{' '}
								<br className={'hidden xs:block fold:block'} />
								제공하고 있습니다.
							</div>
						</div>
						<PaymentCircleList items={additionalCircleItems} />
					</div>
				</Section>
			</Section>
			<ServiceCardList items={additionalCardItems} title={'부가 서비스 항목'} />
			<ServerSection
				btnTitle={'서비스 문의하기'}
				onClick={() => nav('/customerService/question/create')}>
				<div>온/오프라인 비즈니스에</div>
				<div>필요한 모든 부가 서비스</div>
			</ServerSection>
		</Page>
	);
};

export default Additional;

const Page = styled(Section)`
	text-wrap: normal;
	white-space: normal;
	word-break: keep-all;
	text-align: center;
	.fade-in-up {
		opacity: 0;
		transform: translateY(100px);
		transition:
			opacity 1s,
			transform 0.7s;
	}
	.fade-in-up-circle {
		opacity: 0;
		transform: translateY(100px);
		transition:
			opacity 1s,
			transform 0.7s;
		transition-delay: 0.3s;
	}
	.fade-in-up-sub {
		opacity: 0;
		transform: translateY(100px);
		transition:
			opacity 1s,
			transform 0.7s;
		transition-delay: 0.1s;
	}
	& .active .fade-in-up,
	& .active .fade-in-up-sub,
	& .active .fade-in-up-circle {
		opacity: 1;
		transform: translateY(0);
	}
`;
