import React, { useEffect, useRef } from 'react';
import Section from '../../../layout/web/Section';
import ServiceSectionTypography from '../../../components/web/texts/ServiceSectionTypography';
import CEOImage from '../../../assets/images/web/ceo.png';
import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';

const CEOGreeting = () => {
	const ceoImageRef = useRef(null);
	const imgCol = useMediaQuery('(max-width: 1050px)');
	const callback = (entries, io) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				entry.target.classList.add('active');
				io.unobserve(entry.target);
			}
		});
	};
	const sections = new IntersectionObserver(callback, {
		threshold: [imgCol ? 0.1 : 0.6],
	});
	useEffect(() => {
		if (ceoImageRef.current) sections.observe(ceoImageRef.current);

		return () => {
			if (ceoImageRef.current) sections.unobserve(ceoImageRef.current);
		};
	}, []);
	return (
		<Section>
			<Section
				className={
					'pt-[180px] md:pt-[160px] sm:pt-[133px] xs:pt-[103px] fold:pt-[103px] pb-[120px] greeting:pb-[80px] xs:pb-[60px] fold:pb-[60px] transition-all'
				}
				maxSize>
				<ServiceSectionTypography
					title={'CEO인사말'}
					className={
						'text-dark1A mb-[100px] md:mb-[80px] sm:mb-[60px] xs:mb-[50px] fold:mb-[50px] text-center'
					}
				/>
				<FadeInXContainer
					ref={ceoImageRef}
					className={
						'w-full h-auto bg-darkF9 rounded-[30px] px-[120px] greeting:px-[40px] md::px-[40px] sm:px-[30px] xs:px-[20px] fold:px-[20px] sm:py-[30px] flex justify-between greeting:flex-col-reverse xs:flex-col-reverse fold:flex-col-reverse'
					}>
					<div
						className={
							'transition-all w-full py-[70px] md:py-[60px] sm:py-[60px] xs:py-[50px] fold:py-[50px] h-full flex flex-col items-center gap-[40px] xs:gap-[30px] fold:gap-[30px] justify-center whitespace-pre-wrap break-keep text-center'
						}>
						<div
							className={
								'transition-all text-heading1 md:text-heading2 sm:text-heading3 xs:text-mGreetingTitle fold:text-mGreetingTitle text-dark1A  fade-in-up'
							}>
							당연함을 <span className={'text-main'}>지켜 나가는</span> 기업,{' '}
							<span className={'text-main'}>바움P&S</span>입니다.
						</div>
						<div
							className={
								'max-w-[708px] xs:max-w-[320px] fold:max-w-[320px] w-full text-bodyNormal greeting:text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall text-dark49 fade-in-up-sub'
							}>
							온라인에서 오프라인까지 결제와 정산 시스템을 제공하는 PG(Payment
							Gateway) 기업인 바움P&S는 신뢰와 성실을 바탕으로 고객사로의 만족을
							위한 빠르고 정확한 업무처리, <br />
							<br /> 쉽고 편리한 혁신 플랫폼을 지속적으로 개발, 제공함으로써
							다변화된 시장의 유연한
							<br className={'hidden xs:block fold:hidden'} /> 대처를 통해
							성공적인 안착을 통해 시장을 대변하는 기업으로 성장하고자 합니다.{' '}
							<br />
							<br /> 이제, 저희 바움P&S의 다양한 도전과{' '}
							<br className={'xs:hidden fold:hidden'} /> 성공적인 도약을 지켜봐
							주시기 바랍니다. 감사합니다.
							<br />
							<br />
							<div
								className={
									'text-bodyNormal  greeting:text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall fade-in-up-last'
								}>
								대표이사
								<span
									className={
										'ml-[10px] text-bodyLarge  greeting:text-bodyMedium xs:text-bodySmall fold:text-bodySmall font-[600] greeting:font-[600] xs:font-[600] fold:font-[600] xs:leading-[30px] fold:leading-[30px] text-dark1A'
									}>
									김문수
								</span>
							</div>
						</div>
					</div>
				</FadeInXContainer>
			</Section>
		</Section>
	);
};

export default CEOGreeting;

const FadeInXContainer = styled.div`
	& .fade-in-up {
		opacity: 0;
		transform: translateY(100px);
		transition:
			opacity 1s,
			transform 0.7s;
	}
	& .fade-in-up-sub {
		opacity: 0;
		transform: translateY(100px);
		transition:
			opacity 1s,
			transform 0.7s;
		transition-delay: 0.2s;
	}
	& .fade-in-up-last {
		opacity: 0;
		transform: translateY(100px);
		transition:
			opacity 1s,
			transform 0.7s;
		transition-delay: 0.4s;
	}

	&.active .fade-in-up,
	&.active .fade-in-up-sub,
	&.active .fade-in-up-last {
		opacity: 1;
		transform: translateX(0);
	}
`;
