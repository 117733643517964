import noticePopImg from 'assets/images/NoticeFinal.jpg';
import {useState} from "react";
import {useNavigate} from "react-router-dom";
const NoticePopUp = () => {
    const [isPop, setIsPop] = useState(true);
    const navigate = useNavigate();
    const onClickGoToNotice = () => {
        window.location.href = 'https://baumpns.com/customerService/notice/21';
        setIsPop(prev=>!prev)
    }

    const onClickGoToGoolgeForm = () => {
        window.open('https://forms.gle/zcgFynNJGZKjY8cSA', '_blank');
    }

    const onClickImageClose = () => {
        setIsPop(prev => !prev)
    }

    return (
        isPop && (
            <div
                style={{zIndex:10}}
                className={`absolute
                            right-[800px] h-[720px] w-[470px] 
                            xs:w-full xs:h-full xs:right-0 xs:top-[100px] 
                            ss:w-full xs:h-full ss:right-0 ss:top-[100px] 
                            object-cover`}>

                <img src={noticePopImg}
                     alt="공지사항 팝업 이미지"
                     className="w-full h-auto"
                />
                <div
                    className={`w-[30px] h-[30px] cursor-pointer 
                    relative top-[-655px] right-[-433px] 
                    xs:top-[-549px] xs:right-[-358px]
                    ss:top-[-510px] ss:right-[-330px]`}
                    onClick={onClickImageClose}
                >

                </div>

            </div>
        )
    )
}

export default NoticePopUp;
