import styled from 'styled-components';
import { useMediaQuery } from '@mui/material';

const PaymentCircleList = ({ items }) => {
  const smSize = useMediaQuery('(max-width: 799px)');

  return (
    <CircleGridContainer
      className={
        'grid grid-cols-4 max-w-[840px] font-[500] leading-[33px] text-white sm:grid-cols-2  xs:grid-cols-2 fold:grid-cols-2 place-items-center items-center text-bodyMedium sm:text-bodyXSmall xs:text-bodyXSmall fold:text-bodyXSmall fade-in-up-circle'
      }>
      {items.map((item, index) => (
        <PaymentServerSectionCircle
          key={index}
          $translate={index}
          $smSize={smSize}
          style={{ backgroundColor: item.color }}
          className={`h-[240px] w-[240px] md:h-[220px] md:w-[220px] sm:h-[200px] sm:w-[200px] xs:h-[150px] xs:w-[150px] fold:h-[150px] fold:w-[150px]`}>
          {item.title}
        </PaymentServerSectionCircle>
      ))}
    </CircleGridContainer>
  );
};

export default PaymentCircleList;

const CircleGridContainer = styled.div`
  display: grid;
  place-items: center;
  min-width: 300px;
`;

const PaymentServerSectionCircle = styled.div`
  max-width: 240px;
  max-height: 240px;
  min-width: 150px;
  min-height: 150px;

  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  white-space: pre-line;
  z-index: ${({ $translate }) => $translate};
  transform: ${({ $translate, $smSize }) =>
    `translate(-${$translate !== 2 && $translate !== 0 && $smSize ? 10 : 0}px, ${
      $smSize && $translate > 1 && $smSize ? '-10' : '0'
    }px)`};
`;
