import http from 'api/http';

/**
 * 로그인 API
 * @returns
 */
export const loginAction = ({ id, password }) => {
  return http.post('/login', { username: id, password });
};

/**
 *  유저 정보 API
 * @returns
 */
export const getUserInfo = () => {
  return http.get('/user-info');
};

/**
 *  유저 비밀번호 수정 API
 * @returns
 */
export const updatePassword = (password) => {
  // return http.put('/password', password);
  return http.post('/password/put', password);
};

/**
 *  유저 정보 수정
 * @returns
 */
export const upDateUserDetail = (data) => {
  // return http.put('/user', data);
  return http.post('/user/put', data);
};
