import { styled } from 'styled-components';
import { useMemo, useState, memo } from 'react';
import { ReactComponent as DropDown } from 'assets/icons/admin/drop_down.svg';

function Select({ style, width, value, onChange, options }) {
  const [isOpen, setIsopen] = useState(false);
  const selectionName = useMemo(() => {
    const index = options?.findIndex((item) => item.value === value?.toString());
    return index === -1 ? options[0].name : options[index].name;
  }, [options, value]);

  return (
    <SelectWrapper width={width} style={style}>
      <Selection
        className={'w-full'}
        onClick={() => {
          setIsopen(!isOpen);
        }}>
        <span>{selectionName}</span>
        <DropDown></DropDown>
      </Selection>
      <Options open={isOpen}>
        {options.map((option, index) => (
          <Option
            key={`select_option_${option.name}_${index}`}
            value={option.value}
            onClick={(e) => {
              onChange(e);
              setIsopen(false);
            }}>
            {option.name}
          </Option>
        ))}
      </Options>
    </SelectWrapper>
  );
}

export default Select;

const SelectWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  //height: 100%;
  min-width: 135px;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => (width ? width : 'auto')};
  position: relative;
  cursor: pointer;
`;

const Selection = styled.label`
  border: 1px solid var(--color-admin-border);
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-dark-1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: var(--border-radius);
  cursor: pointer;
  height: 42px;
  > svg {
    fill: var(--color-dark-1);
  }
  > span {
    margin-right: 16px;
  }
`;
const Options = styled.ul`
  z-index: 1;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 41px;
  background-color: #fff;
  display: ${({ open }) => (open ? 'block' : 'none')};
  border-radius: var(--border-radius);
`;
const Option = styled.li`
  border: 1px solid var(--color-admin-border);
  font-size: 14px;
  font-weight: 400;
  padding: 12px 16px;
  border-bottom: none;
  width: auto;
  color: var(--color-dark-1);
  &:first-child {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
  &:last-child {
    border-bottom: 1px solid var(--color-admin-border);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
`;
