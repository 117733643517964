import React from 'react';

const NumberInput = ({ value, id, onChange, placeholder, setFocus, className }) => {
  return (
    <div className={'flex-1 w-full max-w-[950px]'}>
      <input
        type="tel"
        className={`w-full border-darkDB border-[1px] rounded-[10px] outline-none focus:border-main py-[13px] px-[20px] text-dark49 text-bodyXSmall leading-[24px] h-[50px] sm:h-[48px] xs:h-[44px] fold:h-[44px] placeholder:text-darkDB ${
          className || ''
        }`}
        id={id}
        autoComplete="off"
        value={value || ''}
        onChange={onChange}
        placeholder={placeholder}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    </div>
  );
};

export default NumberInput;
