import { atom, selector } from 'recoil';

// 유저 정보
const userState = atom({
  key: 'userState',
  default: null,
});

const useNavMenuState = atom({
  key: 'navMenuState',
  default: [],
});
// 로딩
const loadingState = atom({
  key: 'loading',
  default: false,
});

export { userState, useNavMenuState, loadingState };
