import styled from 'styled-components';

const NavBarBtn = ({ state, setState, changeColor, path }) => {
  return (
    <NavBtn $changeColor={changeColor} $state={state} $path={path}>
      <button className={`hamburger ${state ? 'open' : ''}`} onClick={() => setState(!state)}>
        <div className={`line`} />
        <div className={`line`} />
        <div className={`line`} />
      </button>
    </NavBtn>
  );
};

export default NavBarBtn;

const NavBtn = styled.div`
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  .hamburger {
    background: none;
    border: none;
    cursor: pointer;
    position: relative;
    width: 24px;
    height: 16px;
    justify-content: space-between;
    padding: 0;
    outline: none;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
  }
  .hamburger .line {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: ${({ $changeColor, $state, $path }) =>
      !$path && $state ? '#fff' : !$path || $changeColor ? 'black' : '#fff'};
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s;
  }

  .hamburger .line:nth-child(1) {
    top: 0;
  }

  .hamburger .line:nth-child(2) {
    top: 7px;
  }

  .hamburger .line:nth-child(3) {
    bottom: 0;
  }

  .hamburger.open .line:nth-child(1) {
    top: 8px;
    transform: rotate(45deg);
  }

  .hamburger.open .line:nth-child(2) {
    opacity: 0;
  }

  .hamburger.open .line:nth-child(3) {
    top: 8px;
    transform: rotate(-45deg);
  }
`;
