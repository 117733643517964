import React, { useEffect } from 'react';
import Section from '../../../../layout/web/Section';
import MainColorBtn from '../../../../components/web/button/MainColorBtn';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getNoticeDetail } from '../../../../api/web/customerService/notice';
import { getDayFormatHypenV2 } from '../../../../utility/admin/timeFormat';
import { useSetRecoilState } from 'recoil';
import { loadingState } from '../../../../recoil/auth';

const NoticeDetail = () => {
  const nav = useNavigate();
  const params = useParams();
  const setLoading = useSetRecoilState(loadingState);
  const { data, isFetching } = useQuery({
    queryKey: ['userNoticeDetail'],
    queryFn: () => getNoticeDetail(params?.id),
  });
  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);

  return (
    <Section>
      <Section
        maxSize
        className={'h-full pt-[180px] md:pt-[140px] sm:pt-[103px] xs:pt-[103px] fold:pt-[103px] transition-all'}>
        <div
          className={
            'border-solid border-t-dark1A border-t-2 border-b-darkDB border-b-[1px] mb-[100px] md:mb-[80px] sm:mb-[60px] xs:mb-[50px] fold:mb-[50px]'
          }>
          <div
            className={
              'flex flex-row items-center justify-between py-[15px] border-solid border-b-[1px] border-b-darkDB px-[30px]'
            }>
            <div
              className={
                'text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall text-dark1A leading-[30px] xs:leading-[30px] fold:leading-[30px]'
              }
              style={{ userSelect: 'text' }}
            >
              {data?.data?.title}
            </div>
            <div className={'text-bodyXSmall text-dark49'}>{getDayFormatHypenV2(data?.data?.createdDate)}</div>
          </div>
          <div
            className={
              'px-[30px] py-[25px] whitespace-pre-line text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall leading-[30px] text-dark49 mb-[70px]'
            }
            style={{ userSelect: 'text' }}
          >
            {data?.data?.content}
          </div>
        </div>
        <div
          className={
            'flex items-center justify-center mb-[120px] md:mb-[90px] sm:mb-[70px] xs:mb-[50px] fold:mb-[50px]'
          }>
          <MainColorBtn
            onClick={() => nav(-1)}
            text={'목록으로 돌아가기'}
            className={
              'rounded-[15px] xs:rounded-[10px] fold:rounded-[10px] text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall leading-[30px] xs:leading-[30px] fold:leading-[30px] px-[51.5px] py-[16px] md:px-[40px] md:py-[14px] sm:px-[35px] sm:py-[14px] xs:px-[30px] xs:py-[10px] fold:px-[30px] fold:py-[10px] transition-all'
            }
          />
        </div>
      </Section>
    </Section>
  );
};

export default NoticeDetail;
