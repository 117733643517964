import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState, useNavMenuState } from 'recoil/auth';
import { getUserInfo } from 'api/admin/auth';
import { getNavMenu } from 'const/admin';
import { LoadingPage } from 'components/loading/LoadingPage';
import { useCallback } from 'react';

// 로그인한(인증이 된) 유저만 출입이 가능한 페이지
const AdminAuthMiddleWhere = () => {
  const location = useLocation();

  const [user, setUser] = useRecoilState(userState);
  const [menu, setMenu] = useRecoilState(useNavMenuState);

  const accessToken = localStorage.getItem('accessToken');

  const isAccessMenu = useCallback(() => {
    if (user.userRole === 'ROLE_SUPER') return true;
    const path = location.pathname.split('/')[2];
    const onlySuperAccess = ['user', 'user_reg', 'user_edit'];
    return !onlySuperAccess.includes(path);
  }, [user, location.pathname]);

  useEffect(() => {
    const loadon = async () => {
      const { data } = await getUserInfo();
      setUser(data);
      const menus = getNavMenu(data.userRole);
      setMenu(menus);
    };
    if (accessToken && !user) {
      loadon();
    }
  }, []);

  if (location.pathname === '/ad/login') {
    if (accessToken) {
      return <Navigate to={`/ad/apply`} />;
    } else {
      return <Outlet />;
    }
  } else {
    if (accessToken) {
      if (!user) return <LoadingPage />;
      if (!isAccessMenu()) {
        return <Navigate to={`/ad/404`} />;
      }
      return <Outlet />;
    } else {
      return <Navigate to={`/ad/login`} />;
    }
  }
};

export default AdminAuthMiddleWhere;
