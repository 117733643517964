import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Section from '../../../layout/web/Section';

const ServiceCard = ({ item, col = false, index }) => {
  const cardRef = useRef(null);
  const callback = (entries, io) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setTimeout(() => {
          entry.target.classList.add('active');
        }, index * 200);

        io.unobserve(entry.target);
      }
    });
  };
  const sections = new IntersectionObserver(callback, {
    threshold: [0.1],
  });
  useEffect(() => {
    if (cardRef.current) sections.observe(cardRef.current);
    return () => {
      if (cardRef.current) sections.unobserve(cardRef.current);
    };
  }, []);
  return (
    <CardContainer
      ref={cardRef}
      className={`${
        col
          ? 'pl-[37px] py-[65px] xs:py-[24px] fold:py-[24px] md:pl-[37px] md:py-[75px] sm:px-0 xs:px-0 fold:px-0 '
          : 'px-[10px] lg:px-[80px]  md:px-[80px] py-[76px] sm:py-[24px] xs:py-[24px] fold:py-[24px]'
      } flex flex-row sm:flex-col xs:flex-col fold:flex-col items-center justify-center  border-[1px] border-solid border-darkDB rounded-[15px] lg:rounded-[25px] md:rounded-[25px] fade-in-up-card`}>
      <div className={`flex items-center justify-center xs:w-full fold:w-full`}>
        <div
          className={
            'w-[80px] h-[80px] md:w-[70px] md:h-[70px]  sm:h-[60px] sm:w-[60px]  xs:w-[43px] xs:h-[43px] fold:w-[43px] fold:h-[43px] lg:mr-[40px] md:mr-[25px] sm:mb-[10px] xs:mb-[10px] fold:mb-[10px]'
          }>
          {item.icon}
        </div>
      </div>
      <div className={'flex flex-col items-center text-start whitespace-pre-line'}>
        <div
          className={
            'w-full lg:leading-[33px] lg:mb-[5px] text-bodyMedium font-[600]  sm:text-bodySmall xs:text-bodyXSmall fold:text-bodyXSmall text-dark1A sm:text-dark49 xs:text-dark49 fold:text-dark49 sm:text-center xs:text-center fold:text-center'
          }>
          {item.title}
        </div>
        <div className={'w-full text-bodySmall md:hidden sm:hidden xs:hidden fold:hidden leading-[30px] text-dark49'}>
          {item.content}
        </div>
      </div>
    </CardContainer>
  );
};

export default ServiceCard;

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  transition: all 0.2s;

  &:hover {
    border-color: #0081ff;
  }
  & svg {
    width: 100%;
    height: 100%;
  }
  &.fade-in-up-card {
    opacity: 0;
    transform: translateY(100px);
    transition:
      opacity 1s,
      transform 0.7s;
  }
  &.active.fade-in-up-card {
    opacity: 1;
    transform: translateY(0);
  }
`;
